import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import HighCharts from "highcharts/highstock";
import HC_more from 'highcharts/highcharts-more';
import { injectIntl } from 'react-intl';
import Moment from 'moment';
import mTZ from 'moment-timezone';
import { getTempUnit, fetchBrowserTimeZone } from '../../../services/utilityService';
import HighChartsData from "highcharts/highcharts";

HC_more(HighCharts)
window.moment = Moment;
mTZ();

class StockChart extends React.PureComponent {
    constructor(props) {
        super(props)
        HighCharts.setOptions({
            colors: ['#FF7474', '#5CD65C', '#7495BE'], // controls which colors are used.
            global: {
                useUTC: true,
                timezone: props.timezone
            }
        })
        this.state = {
            chartSeries : []
        }
        this.series = this.buildSeries(this.props.options)
        this.afterChartCreated = this.afterChartCreated.bind(this)
    }

    componentDidMount() {
        this.processData(this.props.siteData, this.props.options.dataColumns);
    }

    buildSeries = (options) => {
        let cols = options.dataColumns;
        let series = {};
        cols.forEach((columnName, i) => {
          if (i > 0) {
            series[columnName] = {};
            series[columnName].data = [];
            series[columnName].visible = true;
            series[columnName].id = columnName;
          }
        });
        return series;
    }
    
    processData = (data, cols) => {
        let ts = cols[0], s = {};
        cols.forEach((columnName, c) => {
            if (c !== 0) {
                s[columnName] = this.series[columnName];
                s[columnName].data = [];
                let colNameUnit = columnName.slice(1);
                data['details'].forEach((detailObj, d) => {
                    if(["UM1", "VC10"].includes(detailObj.type) && ["SITE", "LATERAL_SITE"].includes(this.props.treeNode.type)){
                        if (detailObj.pointData) {
                            for(let j=0;j<detailObj.pointData.length;j++){
                                let points1 = detailObj.pointData;
                                let point1 = points1[j];
                                if (point1.hasOwnProperty(columnName)) {
                                    var value = point1[columnName];
                                    var t = point1["t"];
                                    // Set marker radius as 2 for point which has previous/next point has null values
                                    if((points1.length > 1 ) && ( ((j!==0 && j!==(points1.length-1)) && (points1[j-1][columnName] === null && points1[j+1][columnName] === null)) || (j===(points1.length-1) && points1[j-1][columnName] === null) || ((j===0 && j!==(points1.length-1)) && (points1[j+1][columnName]=== null)))){
                                        s[columnName]['data'].push({x:t,y:value, marker:{radius:2}});
                                    }else{
                                        s[columnName]['data'].push([t, value].flat());
                                    }
                                }
                            }
                        }
                    }
                    else{
                        if (detailObj.phase.toLowerCase() === columnName[0]) {
                            if (detailObj.pointData) {
                                for(let i=0;i<detailObj.pointData.length;i++){
                                    let points = detailObj.pointData;
                                    let point = points[i];
                                    let t = point[ts];
                                    if (point.hasOwnProperty(colNameUnit)) {
                                        var value = point[colNameUnit];
                                        // Set marker radius as 2 for point which has previous/next point has null values
                                        if((points.length > 1 ) && ( ((i!==0 && i!==(points.length-1)) && (points[i-1][colNameUnit] === null && points[i+1][colNameUnit] === null)) || (i===(points.length-1) && points[i-1][colNameUnit] === null) || ((i===0 && i!==(points.length-1)) && (points[i+1][colNameUnit]=== null)))){
                                            s[columnName]['data'].push({x:point["t"],y:value, marker:{radius:2}});
                                        }else{
                                            s[columnName]['data'].push([point["t"], value]);
                                        }
                                    }
                                }
                            }
                        }
                    }
                });
            }
            this.setState({
                chartSeries : s
            })
        });
    }

    setExtremes = (min,max) => {	
		if( this.internalChart ) this.internalChart.xAxis[0].setExtremes(min, max, true, false);
	}

    isViewSelected = (view, id) => {
        let v = (view && view.length === 1 && view[0].value === 'i') ? 'load' : (view && view.length === 1 && view[0].value === 't') ? 'temperature' : 'a';
		return v === id.slice(1) || v === 'a';
	}

    isSecViewSelected = (view, id, secondaryPhase, secondaryView, siteData) => {
        let flag = false;
        siteData.chartType === "secondary" && secondaryView.filter(e => e.text === "Voltage" || e.text === "Current").length > 0 && secondaryPhase.length > 0 ? secondaryPhase.forEach(e => {
            if (secondaryView.filter(item => item.text === "Voltage").length > 0 && (id.split(e.toLowerCase()).length > 1 || id.split(e).length > 1) && (id === "v120X1" || id === "v120X3")) flag = true;
            if (secondaryView.filter(item => item.text === "Current").length > 0 && (id.split(e.toLowerCase()).length > 1 || id.split(e).length > 1) && (id === "x1Current" || id === "x3Current")) flag = true;
        }) : siteData.chartType === "secondary" && (secondaryView.filter(e => e.text === "Voltage" || e.text === "Current").length >= 0 && secondaryPhase.length > 0)? secondaryPhase.forEach(e => {
            if ((id.split(e.toLowerCase()).length > 1 || id.split(e).length > 1) && (id === "v120X1" || id === "v120X3")) flag = true;
            else if ((id.split(e.toLowerCase()).length > 1 || id.split(e).length > 1) && (id === "x1Current" || id === "x3Current")) flag = true;
        }):
            siteData.chartType === "secondary" && secondaryView.filter(e => e.text === "Voltage" || e.text === "Current").length > 0 && secondaryPhase.length === 0 ? ["X1", "X3"].forEach(e => {
                if (secondaryView.filter(item => item.text === "Voltage").length > 0 && (id.split(e.toLowerCase()).length > 1 || id.split(e).length > 1) && (id === "v120X1" || id === "v120X3")) flag = true;
                if (secondaryView.filter(item => item.text === "Current").length > 0 && (id.split(e.toLowerCase()).length > 1 || id.split(e).length > 1) && (id === "x1Current" || id === "x3Current")) flag = true;
            }):
            siteData.chartType === "secondary" && secondaryView.filter(e => e.text === "Voltage" || e.text === "Current").length === 0 && secondaryPhase.length === 0 ? ["X1", "X3"].forEach(e => {
                if ( (id.split(e.toLowerCase()).length > 1 || id.split(e).length > 1) && (id === "v120X1" || id === "v120X3")) flag = true;
                if ( (id.split(e.toLowerCase()).length > 1 || id.split(e).length > 1) && (id === "x1Current" || id === "x3Current")) flag = true;
            })
                : siteData.chartType === "load" && secondaryView.filter(e => e.text === "Transformer Loading").length > 0 ? flag = true : id === "kvaThreshold" ? flag = true : siteData.chartType === "primary" ? flag = true
                    : secondaryView.length === 0 ? flag = true
                    : siteData.chartType === "kwkvar" && secondaryView.filter(e => e.text === "kVAR" || e.text ==="kW").length === 0 ? flag = true
                        : siteData.chartType === "kwkvar" && secondaryView.filter(e => e.text === "kVAR" || e.text === "kW").length > 0 ? siteData.chartType === "kwkvar" && secondaryView.filter(e => e.text === "kVAR" || e.text ==="kW").forEach(t => {
                            if (id.split("Range")[0] === t.value) flag = true
                        }) : flag = false;
        return flag
    }

    isVC10SecViewSelected = (view, id, secondaryPhase, secondaryView, siteData) => {
        let flag = false, ltempData = [], idBackup = id, tempObj = { "primaryLoad": 'l', "kvaThreshold": "l", "kWRange": "kW", "x1PowerFactor": "powerFactor", "x3PowerFactor": "powerFactor", "x1PhaseAngle": "phaseAngle", "x3PhaseAngle": "phaseAngle", "x1Current": "i", "x3Current": "i","voltage":"v","voltageSetPoint":"v","voltageSetPointRange":"v" };
        id = (tempObj[id] ? tempObj[id] : id);
        ltempData = secondaryView.filter(e => (e.value === id));
        switch (siteData.chartType) {
            case "vc10VkVARInj":
                flag = flag = ltempData.length > 0 ? true : secondaryView.length === 0 ? true : false;
                break;
            case "vc10SecCurr":
               flag = secondaryPhase.length > 0 ? secondaryPhase.map(e => e.toLowerCase() + "Current").includes(idBackup) : true;
                break;
            case "vc10Load":
                flag = ltempData.length > 0 ? true : secondaryView.length === 0 ? true : false;
                break;
            case "vc10KWkVAR":
                flag = ltempData.length > 0 ? true : secondaryView.length === 0 ? true : false;
                break;
            case "vc10PowerPhase":
                flag = (ltempData.length > 0 || secondaryView.length === 0) ? secondaryPhase.length > 0 ? secondaryPhase.map(e => e.toLowerCase() + id[0].toUpperCase() + id.slice(1)).includes(idBackup) : true : false;
                break;
        }
        return flag;
    }

    getMarker = (series) => {
        let enableMarker = {...this.props.options.highcharts.plotOptions.series.marker, enabled:false};
        Object.entries(series).forEach(([id, s], i) => {
            if (s && s.data.length === 1) {
                enableMarker = { ...this.props.options.highcharts.plotOptions.series.marker, enabled:true, };
            }
        });
        return enableMarker;
    }

    // getGapSize = (pointInterval, zm1presence) => {
	// 	if (pointInterval && parseInt(pointInterval, 10)) {
    //         return ((parseInt(pointInterval, 10) === 5) && zm1presence) ? 36000000 : parseInt(pointInterval, 10)*60*1000;
	// 	} else {
	// 		return (zm1presence) ? 36000000 : 300000; // 5mins
	// 	}
    // }

    afterChartCreated = (chart) => {
        this.internalChart = chart;
        this.props.chartList.push(chart);
        this.forceUpdate();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.siteData && this.props.siteData && (prevProps.siteData !== this.props.siteData)) {
            this.processData(this.props.siteData, this.props.options.dataColumns);
        }
        this.setExtremes(this.props.startDate,this.props.endDate);
        this.internalChart.setSize(null, this.props.elementHeight - 5);
        this.internalChart.reflow();
        setTimeout( () => {
            window.dispatchEvent(new Event('resize'));
        }, 200);
    }

    render() {
        const { siteData, startDate, endDate, view, phase, timezone, chartList, treeNode, secondaryPhase, secondaryView } = this.props;
        const { formatMessage } = this.props.intl;
        const graphColor = { "v120X1": "#FC1DF2", "v120X3": "#0947FA", "x1Current": "#FF7474", "x3Current": "#5CD65C", "primaryLoad": "#079ded", "kvaThreshold": "#8B4513", "kW": "#0099d8", "kWRange": "#0099d8", "kVAR": "#F99417"
        ,"kVARInjected":"#33CDD7","voltageSetPoint":"#BF3E58","voltageSetPointRange":"#BF3E58","voltage":"#4B9C7A","x1PowerFactor":"#A78DC6","x3PowerFactor":"#CEA612","x1PhaseAngle":"#F16F0E","x3PhaseAngle":"#5AAF9E" }
        return (
            <React.Fragment>
                <HighchartsReact
                    highcharts={HighCharts}
                    constructorType={"stockChart"}
                    options={{ //with format message
                        ...this.props.options.highcharts,
                        series: Object.entries(this.state.chartSeries).map(([id, s], i) => {
                            s.name = (id && id.slice(1) === 'temperature') ? formatMessage({ id: "monitor.tootltip." + id }) + ' (' + getTempUnit('value') + ')' : formatMessage({ id: "monitor.tootltip." + id });
                            s.phaseName = (id && id.slice(1) === 'temperature') ? formatMessage({ id: "monitor.tootltip." + id }) + ' (' + getTempUnit('value') + ')' : formatMessage({ id: "monitor.tootltip." + id });
                            s.id = id;
                            //s.yAxis = (id && (id.slice(1) === 'temperature') || id === "v120X1" || id ==="v120X3" || id==="kW" || id==="kWRange" || id ==="x1PhaseAngle" || id ==="x3PhaseAngle")? 1 : 0;
                            s.yAxis = (id && (id.slice(1) === 'temperature') || (["v120X1","v120X3","kW","kWRange","x1PhaseAngle","x3PhaseAngle","voltage","voltageSetPoint","voltageSetPointRange"].includes(id)))? 1 : 0;
                            s.dashStyle = (id && (id.slice(1) === 'temperature' || id === "v120X1" || id ==="v120X3")) ? this.props.options.dashStyles[1] : this.props.options.dashStyles[0];
                            s.turboThreshold = 0;
                            s.color = graphColor[id];
                            //s.color = (id === "v120X1") ? "#FC1DF2" : (id === "v120X3") ? "#0947FA" : (id === "x1Current") ? "#FF7474" : (id === "x3Current") ? "#5CD65C" : (id === "primaryLoad") ? "#079ded" : (id === "kvaThreshold") ? "#8B4513": (id === "kW" || id === "kWRange") ? "#0099d8" : (id === "kVAR") ? "#F99417" : null ;
                            //s.visible =(treeNode.siteDeviceType !== "UM1" && (treeNode.type !== "SITE" || treeNode.type !== "LATERALSITE")) ? (phase && phase.length !== 0) ? (this.isViewSelected(view, id) && (phase.indexOf(id[0].toUpperCase()) !== -1)) : this.isViewSelected(view, id) : this.isSecViewSelected(view, id, secondaryPhase, secondaryView,siteData);
                            s.visible = (treeNode.siteDeviceType === "VC10") ? this.isVC10SecViewSelected(view, id, secondaryPhase, secondaryView,siteData) : (treeNode.siteDeviceType !== "UM1" && (treeNode.type !== "SITE" || treeNode.type !== "LATERALSITE")) ? (phase && phase.length !== 0) ? (this.isViewSelected(view, id) && (phase.indexOf(id[0].toUpperCase()) !== -1)) : this.isViewSelected(view, id) : this.isSecViewSelected(view, id, secondaryPhase, secondaryView,siteData);
                            s.type = (id.split("Range").length > 1 ?  "arearange" : "spline");
                            s.lineWidth = (id.split("Range").length > 1 ? 0 : 1.3);
                            s.fillOpacity = (id.split("Range").length > 1 ? 0.3 : 0);
                            s.states = id.split("Range").length > 1 ? { hover: { enabled: false } } : { hover: { enabled: true }, inactive: { opacity: 1 } };
                            return s;
                        }),
                        ...this.props.options.highcharts.plotOptions.series.marker = this.getMarker(this.state.chartSeries),// series has only one points
                        // ...this.props.options.highcharts.plotOptions.series['gapSize'] = this.getGapSize(siteData.pointInterval, siteData.zm1presence),
                        ...this.props.options.highcharts.xAxis.title.text = formatMessage({ id: "common.label.time" }),
                        ...this.props.options.highcharts.xAxis.max = endDate,
                        ...this.props.options.highcharts.xAxis.min = startDate,
                        // ...this.props.options.highcharts.yAxis[0].title.text = !this.props.options.yAxisTitle ? formatMessage({ id: "monitor.label.axis.current" }) : formatMessage({ id: `monitor.label.axis.${this.props.options.yAxisTitle.yAxis0}` }) ,
                        ...this.props.options.highcharts.yAxis[0].title.text = this.props.options.highcharts.yAxis[0].title.text === "Current" ? formatMessage({ id: "monitor.label.axis.current" }) : this.props.options.highcharts.yAxis[0].title.text ,
                        ...(this.props.options.highcharts.yAxis[1] ? this.props.options.highcharts.yAxis[1].title.text = this.props.options.highcharts.yAxis[1].title.text.includes("Temperature") ? formatMessage({ id: "db.common.temperature" }) +  ' (' + getTempUnit('value') + ')' : this.props.options.highcharts.yAxis[1].title.text:null),
                        ...(this.props.options.highcharts.yAxis[1] && (this.props.options.highcharts.title.text === "kW and kVAR" || this.props.options.highcharts.title.text === "Voltage and kVAR Injected") ? 
                            (this.props.options.highcharts.yAxis[0].height = '50%',
                            this.props.options.highcharts.yAxis[0].lineWidth = 2,
                            this.props.options.highcharts.yAxis[0].top = '50%',
                            this.props.options.highcharts.yAxis[1].opposite = false,
                            this.props.options.highcharts.yAxis[1].height = '46%',
                            this.props.options.highcharts.yAxis[1].offset = 0,
                            this.props.options.highcharts.yAxis[1].lineWidth = 2,
                            this.props.options.highcharts.yAxis[1].labels.align = "right",
                            this.props.options.highcharts.yAxis[1].labels.x = -3,
                            this.props.options.highcharts.yAxis[1].gridLineWidth = 1
                            )
                            : null) , 
                            
                   ...this.props.options.highcharts.xAxis.events.setExtremes = (e) => {
                            if(typeof e.min === 'undefined' && typeof e.max === 'undefined'){
                                this.props.zoomOut(chartList, e)
                            };
                            if(e.trigger && e.trigger === "zoom"){
                                this.props.updateXScale(chartList, e);
                            }
                        },
                        ...this.props.options.highcharts.xAxis.events.afterSetExtremes = (e) => {
                            if (!e.hasOwnProperty('DOMEvent') && this.props) {
                                this.props.updateXScale(chartList, e);
                            }
                        },
                        ...{
                            tooltip: {
                                crosshairs: true,
                                zoomType: 'x',
                                borderWidth: 1,
                                borderColor: '#aeceea',
                                backgroundColor: '#fafafa',
                                alignTicks : false, // required for multiple series
                                useHTML : true,
                                split: false,
                                shared: true,
                                valueDecimals: 1,
                                headerFormat: '{point.key} ' + fetchBrowserTimeZone('zoneAbbr', timezone) + '<br/>',
                                pointFormat: '{series.options.phaseName} : {point.y}<br/>',
                                 formatter: function() {
                                    let s =  new Date(this.x).toLocaleString('en-US', {timeZone:timezone, month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' ,weekday:"long", hourCycle: 'h23'}) + fetchBrowserTimeZone('zoneAbbr', timezone) + '<br/>';
                                    this.points.forEach(item => {
                                        s += (["UM1","VC10"].includes(treeNode.siteDeviceType) && (treeNode.type === "SITE" || treeNode.type === "LATERAL_SITE")) ? `<span style="color:${item.color}">\u25CF</span> ` : "" ;
                                        if(item.point.options.hasOwnProperty("y"))
                                        s += `${item.series.name} : ${Number(item.point.options.y.toFixed(3))}<br/>`
                                        else
                                        s += `${item.series.name} : ${Number(item.point.options.low.toFixed(3))} to ${Number(item.point.options.high.toFixed(3))}<br/>`
                                    });
                                    return s;
                                 }
                            },
                        }
                    }}
                    callback={this.afterChartCreated}
                />
            </React.Fragment>
        )
    }
}

export default injectIntl(StockChart);
