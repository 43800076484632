import React, { Component } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import NavigationItem from './navigationItem';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { getCustomReports } from '../services/headerService';

class CustomReportNavigations extends Component {

    state = {
        customReportData: []
    }

    componentDidMount() {
        //api call to fetch the custom report info
        getCustomReports().then((data) => {
            if (data.data.status === "OK" && data.data.message === "success") {
                this.setState({
                    customReportData: data.data.data
                });
            }
        })
    }

    render() {
        const items = this.state.customReportData.map(e => {
            if (e.reportAvailability) {
                return <NavigationItem key={`${e.id}-${encodeURIComponent(e.reportName)}`} extraClass="customlink" isDisabled={e.reportVisibility ? false : true} link={`/reports/custom?id=${e.id}&name=${encodeURIComponent(e.reportName)}`}>{e.reportName}</NavigationItem>
            }
        })
        return (
            <NavDropdown title={<span><FormattedMessage id={`nav.main.customreport`} /> <FontAwesomeIcon icon={faChevronDown} size="sm" /> </span>} disabled={this.props.roleAndPermission.roleId > 2} id="collasible-nav-dropdown1" className="nav-dropdown-menu update-profile-menu">
                {items}
                <hr className="hr-color m-0 p-0" />
                <NavigationItem extraClass="customlink" link="/customReportManageSchedules"><FormattedMessage id="nav.main.manageSchedules" /></NavigationItem>
                <NavigationItem extraClass="d-none" link="/customReportManageReports"><FormattedMessage id="nav.main.manageReports" /></NavigationItem>
            </NavDropdown>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default withRouter(connect(mapStateToProps, {})(CustomReportNavigations));