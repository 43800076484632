import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const SingleSelect = (props) => {

    //if label and value are different and based on the value need to display the label
    const updateValueToLabel = (data, val) => {
        const obj = data.find(item => item.value === val)
        return obj !== undefined ? obj.label : ""
    }

    return (
        <Dropdown>
            <Dropdown.Toggle className="single-select" disabled={props.disabled !== undefined && props.disabled ? true : false}>
                {props.isDisplayLabel ? <React.Fragment> {updateValueToLabel(props.data, props.value)} &nbsp;</React.Fragment> : <React.Fragment>{props.value}&nbsp;</React.Fragment>}
                {/* {props.value} */}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {props.data.map((item, key) => {
                    return <Dropdown.Item
                        disabled={item.isDisabled}
                        key={key}
                        onClick={() => !item.hasOwnProperty("isLegacy") ? props.setType(item.value) : props.setType(item)}
                    >
                        {props.icon !== undefined ? (props.id ? props.id === item.value : props.value === item.value || item.label === props.value) ? <span> <FontAwesomeIcon icon={faCheck} size="sm" />&nbsp;&nbsp;</span> : <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> : ""}
                        {item.label}
                        {props.isLabelRightIcon ? <span className='float-right'>{item.labelRightIcon}</span> : ""}
                    </Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

SingleSelect.propTypes = {
    disabled: PropTypes.bool,
    isDisplayLabel: PropTypes.bool, //if true display the label onle else value will diaplay
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    data: PropTypes.array,
    setType: PropTypes.func,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isLabelRightIcon: PropTypes.bool //if true then 'props.data' structure need to pass like this: [{ 'label': Item1, 'value': item1, 'labelRightIcon': your icon here}
}
export default SingleSelect;