import React, { Component } from 'react';
import SiteTable from '../components/siteTable';
import { SiteContext } from '../provider/siteProvider';
import Loader from '../../../../hoc/loader';
import ModalWindow from '../../../../hoc/modelWindow';
import SiteActionContainer from './siteActionContainer';
import { FormattedMessage } from 'react-intl';
import TextField from '../../../../hoc/form/textField';
import { Row, Col } from 'react-bootstrap';
import Alert from '../../../../hoc/alert';

class SiteContainer extends Component {
    static contextType = SiteContext;
    render() {
        var modalContent = <React.Fragment>
            {this.context.state.alertMessage.msg !== undefined && this.context.state.alertMessage.msg !== "" ? <><Alert type={this.context.state.alertMessage.type} message={<div> {this.context.state.alertMessage.msg}</div>} /> <br/></> : ""}
            <Row className="p-0 m-0">
                <Col className="col-7 p-0 m-0"><FormattedMessage id="manage.sites.groundFaultThreshold" /> (1 - 10000 A<sub>rms</sub>) * </Col>
                <Col className="col-5">
                <FormattedMessage id="manage.sites.groundFaultThreshold" >
                    {
                    msg =>(
                        <TextField
                        placeholder={msg}
                        type="text"
                        isDisabled={false}
                        value={this.context.state.selectedSiteData.groundFaultThreshold}
                        field=""
                        handleOnchange={(e) => this.context.handleChangeGroundFaultThreshold(e)}
                        className={this.context.state.formError["groundFaultThreshold"] !== undefined && this.context.state.formError["groundFaultThreshold"] === true ? "form-err-border-red ml-2p" : ""}
                    />
                    )
                    }
                   </FormattedMessage>
                </Col>
            </Row>
        </React.Fragment>

        return (
            <React.Fragment>
                {this.context.state.loading ? <div className="loader-wrap"><Loader elementClass="device-management-ajax-loader" /></div> : null}
                <SiteActionContainer/>
                <SiteTable sites={this.context.state.sites} loading={this.context.state.loading} className="sites-table"/>
                {this.context.state.modal.showModal ?
                    <ModalWindow
                        show={this.context.state.modal.showModal}
                        onHide={this.context.modalClose}
                        size={"md"}
                        dialogClassName={"siteContainerModal"}
                        onBtnClickHandler={this.context.modelClickHandler}
                        title= {<span><FormattedMessage id={`manage.sites.editsite`} /> {this.context.state.modal.title ? " : " : ""} {this.context.state.modal.title}</span>}
                        modeldata={{content:modalContent}}
                        footer={[
                            { className: "", name: <FormattedMessage id={`manage.sites.applicablemsg`} />, leftContent: true },
                            { className: "modalSaveBtn", name: <FormattedMessage id='manage.sites.save' />, value: "saveSingleSite", disabled: this.context.state.disableSaveBtn }
                          ]}
                    /> : null}
            </React.Fragment>
        )
    }
}

export default SiteContainer;