import * as actionTypes from '../login/actions/loginActionTypes';

const intialState = {
    loggedIn: false,
    sessionData: null,
    formLogin: false,
    loginData: null,
    loading: true,
    searchQuery: null,
    notify: "",
    currentScrollHeight:0,
    roleAndPermission: {},
    showErrorModal: false,
    showAlertModal:false,
    errorTile:'',
    errorMessage: '',
    msgType: '',
    hideForgotPassword: false
}

const loginReducer = function (currentState = intialState, action) {
    switch (action.type) {
        case actionTypes.ON_LOGIN:
            return {
                ...currentState,
                loggedIn: action.loggedIn,
                loginData: action.loginData
            }
        case actionTypes.SET_SESSION:
            return {
                ...currentState,
                loggedIn: action.sessionData.loggedIn,
                sessionData: action.sessionData,
                loading: false
            }
        case actionTypes.UPDATE_FORM_LOGIN:
            return {
                ...currentState,
                formLogin: action.formLogin,
                errorMessage: action.errorMessage,
                hideForgotPassword: action.hideForgotPassword
            }
        case actionTypes.UPDATE_GLOBAL_TREE:
            return {
                ...currentState,
                treeData: [action.globalTreeData]
            }
        case actionTypes.UPDATE_SEARCH_QUERY:
            return {
                ...currentState,
                searchQuery: action.searchQuery
            }
        case actionTypes.UPDATE_NOTIFY:
            return {
                ...currentState,
                notify: action.notify
            }
        case actionTypes.GET_ALL_PERMISSION_DATA:
            return {
                ...currentState,
                roleAndPermission: action.permissionData
            }
        case actionTypes.OPEN_ERROR_WINDOW:
            return {
                ...currentState,
                showErrorModal: true,
                errorMessage: action.errorMessage,
                msgType:action.msgType,
                errorTile:action.errorTile
            }
        case actionTypes.CLOSE_ERROR_WINDOW:
            return {
                ...currentState,
                showErrorModal: false,
                errorMessage: '',
                errorTile:''
            }
        case actionTypes.OPEN_ALERT_WINDOW:
            return {
                ...currentState,
                showAlertModal: true,
                errorMessage: action.errorMessage,
                msgType: action.msgType
            }
        case actionTypes.CLOSE_ALERT_WINDOW:
            return {
                ...currentState,
                showAlertModal: false,
                errorMessage: ''
            }
        case actionTypes.SET_LOADER:
            return {
                ...currentState,
                loading: action.loaderData
            }
        case actionTypes.ON_LOGOUT:
            return {
                ...currentState,
                loggedIn: action.loggedIn,
                loginData: action.loginData
            }
        default:
            return currentState
    }
}

export default loginReducer;