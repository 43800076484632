import React from 'react';
import { Form } from 'react-bootstrap';
import {injectIntl} from 'react-intl'; 

const TextField = ({type, field, value, placeholder, handleOnchange, size, maxLength, readOnly, isDisabled, className, handleOnKeyDown, handleOnBlur, autoFocus }) => {
    return (
        <Form.Control
            type={type}
            value={value !== "" && value !== null && value !== undefined ? value : ""}  // values are allowed to be '0'
            placeholder={placeholder}
            onChange={(e) => handleOnchange(e.target.value, field)}
            size={size}
            maxLength={maxLength}
            readOnly={readOnly ? true : false}
            disabled = {isDisabled !== undefined && isDisabled ? true : false }
            className={className !== undefined ? className : "" }
            autoComplete={type === "password" ? "new-password" : type === "email" ? "email" : ""}
            onKeyDown={e => handleOnKeyDown ? handleOnKeyDown(e.target.value, field, e) : e.stopPropagation()}
            onBlur={e => handleOnBlur ? handleOnBlur(e.target.value, field, e) : e.stopPropagation()}
            autoFocus={autoFocus}
        />
    )
}

export default injectIntl(TextField);
//type
// email, text, password, etc
//size || sm, lg