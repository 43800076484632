import React, { useState, useContext, useEffect } from 'react';
import Table from '../../../../hoc/table';
import { FormattedMessage } from 'react-intl';
import Tooltip from '../../../../hoc/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faDownload, faTrash, faEye, faUpload } from '@fortawesome/free-solid-svg-icons';
import { tableData } from '../../../../utility/virtualDevicerManagementUtility';
import {VirtualDeviceContext} from '../provider/virtualDeviceProvider';
import BlankPage from '../../../../hoc/blankPage';
import Loader from '../../../../hoc/loader';
import ViewVirtualDeviceModal from './viewVirtualDeviceModal';
import ViewVirtualDeviceDeleteModal from './virtualDeviceDeleteModal';
import VirtualDeviceLiveViewModal from './liveView/virtualDeviceLiveViewModal';
import { Row, Col } from 'react-bootstrap';

const VirtualDeviceMainTable=()=>{
    const contextData = useContext(VirtualDeviceContext);
    const [columnsData, setColumnData] = useState(tableData.columnsData)

    useEffect(()=>{
        const roleName = contextData.roleAndPermission.roleName;
        setColumnData(
            [
                ...tableData.columnsData,
                {
                    accessor: 'action', Header: <FormattedMessage id='dm.column.actions' />, sortable: false, Cell: ({ original }) => {
                        return <span>
                            <Tooltip baseContent={<FontAwesomeIcon  icon={faEye} size="sm" onClick={ ()=> handleOnclick(original, 'View')} className={'cursor-pointer'} />} message={ <FormattedMessage id={"vdm.tooltip.view"} />} placement={'top'} />
                            &nbsp;&nbsp;
                            <Tooltip baseContent={ <FontAwesomeIcon icon={faPen} size="sm" onClick={((!['ROLE_ADMIN', 'ROLE_ORG_ADMIN'].includes(roleName)) || original.uploaded ) ? ()=> {} : ()=> handleOnclick(original, 'Edit')} className={((!['ROLE_ADMIN', 'ROLE_ORG_ADMIN'].includes(roleName)) || original.uploaded ) ? 'disabled-icon' : 'cursor-pointer'} />} message={<FormattedMessage id={"groupmanagement.context.edit"} /> } placement={'top'}
                            /> &nbsp;&nbsp;
                            <Tooltip baseContent={ <FontAwesomeIcon icon={faDownload} size="sm" onClick={( !['ROLE_ADMIN', 'ROLE_ORG_ADMIN'].includes(roleName)) ? ()=> {} : ()=> handleOnclick(original, 'download')} className={(!['ROLE_ADMIN', 'ROLE_ORG_ADMIN'].includes(roleName)) ? 'disabled-icon' : 'cursor-pointer'} />} message={ <FormattedMessage id={"db.sysAdmin.download"} /> } placement={'top'} />
                            &nbsp;&nbsp;&nbsp;                            
                            <span className={ !['ROLE_ADMIN', 'ROLE_ORG_ADMIN'].includes(roleName) ? "disabled-file-inputs" : "custom-file-inputs"}>
                                <Tooltip placement="top" message={ <FormattedMessage id={"vdm.tooltip.upload"} />} baseContent={
                                    <span className='tooltip-btn'>
                                        <FontAwesomeIcon icon={faUpload} size="sm" className={ !['ROLE_ADMIN', 'ROLE_ORG_ADMIN'].includes(roleName) ? "disabled-input-icon-color" : "input-icon-color"}/>
                                            <input type="file"
                                                onChange={(e) => !['ROLE_ADMIN', 'ROLE_ORG_ADMIN'].includes(roleName) ? ()=> {} : handleOnclick(original, 'uploadFile', e)}
                                                onClick={(event) => event.target.value = null}
                                                accept={".csv"}
                                                disabled={!['ROLE_ADMIN', 'ROLE_ORG_ADMIN'].includes(roleName)}
                                            />
                                    </span>
                                } />
                            </span>
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip baseContent={<FontAwesomeIcon icon={faTrash} size="sm" onClick={(roleName !== 'ROLE_ADMIN' && roleName !== 'ROLE_ORG_ADMIN') ? ()=> {} : ()=> handleOnclick(original, 'delete')} className={(roleName !== 'ROLE_ADMIN' && roleName !== 'ROLE_ORG_ADMIN') ? 'disabled-icon' : 'cursor-pointer'}/>} message={ <FormattedMessage id={"manage.profiles.delete"} /> } placement={'top'} />
                        </span>;
                    }   
                }
            ]
        )
    },[contextData.roleAndPermission.roleName])

    const handleOnclick=(original, clickedType, e)=>{
        contextData.handleAddVirtualDevice(original, clickedType, e)
    }

    /* Highlight the row on click */
    const rowSelection = (state, rowInfo) => {
        let isOpenDeleteModal = contextData.state.isOpenDeleteModal;
        let color = isOpenDeleteModal || contextData.state.isOpenedDeviceViewModal || contextData.state.isDisplayDeviceLiveView ? '#F1D7AD' : '';
        if (rowInfo && rowInfo.original) {
            return {
                style: {
                    background: rowInfo.original.name === contextData.state.clickedRowData?.name ? color : ""
                }
            };
        } else { return {}; }
    }
    
    return (
        <Row className={contextData.state.allVirtualDeviceData.length ? 'm-0 p-0': 'device-main-table m-0 p-0' }>
            <Col className='m-0 p-0'>
                {(contextData.state.isLoadingVDdownloadLoader || contextData.state.isLoadingVirtualTable) && <Loader elementClass="tree-ajax-loader center-element" /> }
                { contextData.state.allVirtualDeviceData.length !== 0 ?
                        <Table
                            tableColumn={columnsData}
                            tableRowData={contextData.state.allVirtualDeviceData}
                            rowSelectionCallback={(state, rowInfo)=>rowSelection(state, rowInfo)}
                        />
                    :
                    !contextData.state.isLoadingVirtualTable && <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />
                }
                    
                {contextData.state.isOpenedDeviceViewModal && <ViewVirtualDeviceModal />}
                {contextData.state.isOpenDeleteModal && <ViewVirtualDeviceDeleteModal /> }
                {contextData.state.isDisplayDeviceLiveView && <VirtualDeviceLiveViewModal />}
            </Col>
        </Row>
    )
}

export default VirtualDeviceMainTable;