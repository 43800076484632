import React, { createContext } from 'react';
import { loadNotificationCategory, loadNotificationTemplates, deleteNotificationTemplates, storeNotificationTemplates } from '../services/notificationTemplateService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { notificationTemplatesData } from '../../../utility/notificationTemplateUtility';
import { FormattedMessage } from 'react-intl';

export const NotificationTemplateContext = createContext();

class NotificationTemplateProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: [],
            notificationTemplate: [],
            allTemplateList: [],
            isLoadingData: false,
            selectedTemplateCopy: {}, //will compare previous data with new user filled data, based on this displying validation modal
            saveAsName: '',
            formData: {
                category: "",
                notificationTemplate: "",
                name: "",
                systemDefined: false,
                active: false,
                emailBody: '',
                placeHolders: [],
                subject: ""
            },
            validationMsg: { msg: "", type: "" },
            currentBtnName: '',
            isOpenValidationModal: false,
            validateModalResMsg: '',
            isDisplayModalCloseBtn: false,
            selectionStart: 0,
            updateFrom: ''
        }
    }

    componentDidMount() { this.getNotificationTemplateData() }
    componentWillReceiveProps() { this.getNotificationTemplateData() }

    getNotificationTemplateData = () => {
        this.setState({ isLoadingData: true }, () => {
            loadNotificationCategory()
                .then((res) => {
                    if(res){
                        let data = res.status !== 'FAIL' ? res : [];
                        this.prepareData(data)
                    } else this.setState({ isLoadingData: false })
                }).catch((err) => this.setState({ isLoadingData: false }))
        })
    }

    prepareData = (allData) => {
        let tmpArr = [];
        allData.forEach(item => {
            var name = item + " Notification";
            tmpArr.push({ label: name.charAt(0).toUpperCase() + name.slice(1), value: item })
        })
        this.setState({ category: tmpArr, formData: { ...this.state.formData, category: tmpArr[0].value } }, () => {
            this.getNotificationTemplatesData(this.state.formData.category, null)
        })
    }

    getNotificationTemplatesData = (category, callFrom) => {
        // on selction of deviceunsalert getting response failed, this is an error from backend, after fix the error from backend then we will pass dynamic category.
        // loadNotificationTemplates(category, { validateSuccess: false, errorTile: 'Notification Templates', errorMessage: 'Data loading failed' })
        loadNotificationTemplates('device', { validateSuccess: false, errorTile: <FormattedMessage id={'settings.notificationTemplates.text.title'} />, errorMessage: <FormattedMessage id={'common.error.data'} /> })
            .then((res) => {
                let data = res.data.status !== 'FAIL' ? res.data.data : [];
                if (data.length !== 0) this.prepareTemplateData(data, callFrom)
                this.setState({ isLoadingData: false })
            }).catch((err) => {
                this.setState({ isLoadingData: false })
            })
    }

    prepareTemplateData = (allData, callFrom) => {
        let tmpArr = [];
        allData.forEach(item => {
            tmpArr.push({ label: item.name, value: item.name, labelRightIcon: item.active ? <FontAwesomeIcon icon={faCheckCircle} color='green' /> : "" })
        })

        // let defaultSelected = allData[0];
        let currentSelectedData = {};
        if (callFrom === 'saveAs' || callFrom === 'create') currentSelectedData = allData[allData.length - 1]   //if created new template then display last data
        else if (callFrom === 'save') currentSelectedData = allData.find(item => item.name === this.state.formData.name) //if template updated then display the same data
        else currentSelectedData = allData[0]; //if delete or other always diaply first data
        this.setState({
            notificationTemplate: tmpArr,
            allTemplateList: allData,
            selectedTemplateCopy: { ...currentSelectedData, notificationTemplate: currentSelectedData.name },
            formData: { ...this.state.formData, notificationTemplate: currentSelectedData.name, ...currentSelectedData }
        })
    }

    updateFormData = (value, field) => {
        if (field === 'notificationTemplate') this.onChangeNotificationTemplateField(value, field)
        else if (field === 'category') {
            let isOldAndNewDataAreSame = this.compareOldDataWithNewData();
            this.setState({ notifiCatValBackUp: value, updateFrom: 'categoryField' }, () => { //this value we are using for 'compareWarning' modal 'ok button', if user clicked ok then call this getNotificationTemplatesData api
                if (isOldAndNewDataAreSame) {
                    this.setState({ formData: { ...this.state.formData, [field]: value }, selectedTemplateCopy: { ...this.state.selectedTemplateCopy, [field]: value } }, () => {
                        this.getNotificationTemplatesData(value, null);
                        this.removeAllFormError();
                        this.setState({ isLoadingData: true, validationMsg: { msg: "", tyepe: "" } });
                    })
                } else {
                    this.setState({
                        isOpenValidationModal: true,
                        validateModalResMsg: <FormattedMessage id={"settings.notificationTemplates.text.changetemplate.confirmationtext"} />,
                        // modalName: 'compareWarning',===>
                        modalName: 'categoryChangeWarning',
                        isDisplayModalCloseBtn: false,
                    })
                }
            })
        } else {
            this.setState({ formData: { ...this.state.formData, [field]: value } })
        }
    }

    handleTagVariables = (field, value) => { //on select of tag ('@') item, 1st close it, then update the current onchange
        let concatVal = this.state.formData[field].slice(0, this.state.selectionStart) + " " + value + " " + this.state.formData[field].slice(this.state.selectionStart + 1, this.state.formData[field].length)
        this.setState({ ['isOpenTagSection' + field]: false }, () => {
            this.updateFormData(concatVal, field)
        })
    }

    handleOnKeyDown = (value, field, event) => {
        event.stopPropagation();
        if (event.key === '@' && (field === 'emailBody' || field === 'subject')) this.setState({ ['isOpenTagSection' + field]: true, selectionStart: event.target.selectionStart })
        else this.setState({ ['isOpenTagSection' + field]: false })
    }

    //storing save as template field name from save as modal
    updateSaveAsFormData = (value) => this.setState({ saveAsName: value })

    onChangeNotificationTemplateField = (value, field) => { //based on the notification template selection value display all the form fields.
        this.setState({ notifiTempValBackUp: value, updateFrom: 'notifyField' }, () => { //this value we are using for 'compareWarning' modal 'ok button', if user clicked ok then update value based on selected template name
            let isOldAndNewDataAreSame = this.compareOldDataWithNewData();
            if (isOldAndNewDataAreSame) {
                this.setState({ currentBtnName: 'save', validationMsg: { msg: "", tyepe: "" } })
                this.updateFormdataBasedOnNotifTempVal(field, value)
                this.removeAllFormError();
            } else {
                this.setState({
                    isOpenValidationModal: true,
                    validateModalResMsg: <FormattedMessage id={"settings.notificationTemplates.text.changetemplate.confirmationtext"} />,
                    modalName: 'templateChangeWarning',
                    isDisplayModalCloseBtn: false,
                })
            }
        })
    }

    updateFormdataBasedOnNotifTempVal = (field, value) => {
        let alltemplate = this.state.allTemplateList;
        let findTemplate = alltemplate.find(item => item.name === value) //finding exact template from all template list
        this.setState({
            formData: { ...this.state.formData, ...findTemplate, [field]: value },
            selectedTemplateCopy: { ...findTemplate, [field]: value },
        })
    }

    compareOldDataWithNewData = () => {
        let val = false;
        let sortOldData = this.sortObject(this.state.selectedTemplateCopy);
        let sortNewData = this.sortObject(this.state.formData);
        if (JSON.stringify(sortOldData) === JSON.stringify(sortNewData)) val = true
        return val
    }

    sortObject = (obj) => {
        return Object.keys(obj).sort().reduce((a, v) => {
            a[v] = obj[v];
            return a;
        }, {});
    }

    resetDataOnCloseModal = () => { //reset all the data on close the notification template modal.
        this.setState({
            notificationTemplate: [],
            allTemplateList: [],
            formData: { category: "", notificationTemplate: "", name: "", systemDefined: false, active: false, emailBody: '', placeHolders: [], subject: "" },
            currentBtnName: "",
            validationMsg: { msg: "", type: "" },
            isOpenTagSectionemailBody: false,
            isOpenTagSectionsubject: false,
            selectedTemplateCopy: {},
            modalName: "",
            updateFrom: ''
        })
        this.removeAllFormError();
    }

    handleClickModalActionButton = (clickedBtnName) => { //handling all the action button(create, save, saveAs or delete)
        this.setState({ currentBtnName: clickedBtnName }, () => {
            if (this.state.currentBtnName === 'createTemplate') {
                this.setState({ updateFrom : '' })
                this.displayFormToCreateNewTemplate();
            }
            else if (this.state.currentBtnName === 'save' || this.state.currentBtnName === 'create') this.saveCreateNewOrSaveAsTemplate();
            else if (this.state.currentBtnName === 'saveAs') this.saveAsTemplate();
            else if (this.state.currentBtnName === 'delete') this.deleteTemplate();
        })
    }

    //if user clicked 'Create Template' button, then display blank fields,
    displayFormToCreateNewTemplate = () => {
        let isOldAndNewDataAreSame = this.compareOldDataWithNewData();
        if (isOldAndNewDataAreSame) this.displayNewForm();
        else this.setState({
            currentBtnName: this.state.updateFrom !== "" ? 'save' : 'createTemplate',
            isOpenValidationModal: true,
            validateModalResMsg: <FormattedMessage id={"settings.notificationTemplates.text.createtemplate.confirmationtext"} />,
            modalName: 'createTemplateWarning',
            isDisplayModalCloseBtn: false,
        })
    }

    displayNewForm = () => this.setState({ formData: { ...this.state.formData, name: "New Template", systemDefined: false, active: false, emailBody: '', subject: "" }, selectedTemplateCopy: { ...this.state.formData, name: "New Template", systemDefined: false, active: false, emailBody: '', subject: "" }, currentBtnName: 'createTemplate' })

    checkFormValidation = () => {
        //checking if required fields are empty then adding a new 'isErr' propertie, based on this updating the error message
        let errMsg = { msg: '', type: '' };
        notificationTemplatesData.formFields.btmForm.forEach(item => {
            if (item.isMandatory && this.state.formData[item.value] === "") item.isErr = true
            else item.isErr = false
        })

        let isAvailDuplicateName = false; //checking if template name are already existing or no, based on this updating the error message
        this.state.allTemplateList.forEach(item => {
            if (this.state.currentBtnName === 'create') {
                if (item.name === this.state.formData.name) isAvailDuplicateName = true
            } else if (this.state.currentBtnName === 'save') {
                if (item.id !== this.state.formData.id && item.name === this.state.formData.name) isAvailDuplicateName = true
            } else if (this.state.currentBtnName === 'saveAs') {
                if (item.name === this.state.saveAsName) isAvailDuplicateName = true
            }
            else isAvailDuplicateName = false
        })

        let isAvailEmptyErr = notificationTemplatesData.formFields.btmForm.some(item => item.isErr === true);
        if (isAvailEmptyErr) errMsg = { msg: <FormattedMessage id={'settings.notificationTemplates.form.message.mandatory'} />, type: 'danger' };  //for empty mandatory field
        if (isAvailDuplicateName) errMsg = { msg: <FormattedMessage id={'settings.notificationTemplates.form.message.duplicatename'} />, type: 'danger' }; //if duplicate name
        if (this.state.formData.emailBody.length > 10000) errMsg = { msg: <FormattedMessage id={'settings.notificationTemplates.form.message.emailbody_length'} />, type: 'danger' } //if Body field have more than 10000 charactors
        if (this.state.formData.subject.length > 255) errMsg = { msg: <FormattedMessage id={'settings.notificationTemplates.form.message.subjectLength'} />, type: 'danger' } //if subject field have more than 255 charactors
        if (this.state.selectedTemplateCopy.active && !this.state.formData.active) errMsg = { msg: <FormattedMessage id={'settings.notificationTemplates.warning.add'} />, type: 'danger' }
        return errMsg
    }

    updateValidateModalResMsg = () => { // updating modal body message if status success
        let msg = "";
        if (this.state.currentBtnName === 'save') msg = <FormattedMessage id={'settings.notificationTemplates.success.add'} />
        else if (this.state.currentBtnName === 'create') msg = <FormattedMessage id={'settings.notificationTemplates.success.create'} />
        else msg = <FormattedMessage id={'settings.notificationTemplates.success.clone'} />
        return msg
    }

    updateResFailedMsg = () => { //if response failed update failure message
        let errMsg = { errorTile: '', errorMessage: '' }
        if (this.state.currentBtnName === 'save') errMsg = { errorTile: <FormattedMessage id={"dm.column.save"} />, errorMessage: <FormattedMessage id={"settings.notificationTemplates.failure.add"} /> }
        else if (this.state.currentBtnName === 'saveAs') errMsg = { errorTile: <FormattedMessage id={"manage.profiles.saveas"} />, errorMessage: <FormattedMessage id={"settings.notificationTemplates.failure.clone"} /> }
        else if (this.state.currentBtnName === 'create') errMsg = { errorTile: <FormattedMessage id={"db.common.create"} />, errorMessage: <FormattedMessage id={"settings.notificationTemplates.failure.create"} /> }
        else if (this.state.currentBtnName === 'delete') errMsg = { errorTile: <FormattedMessage id={"db.common.delete"} />, errorMessage: <FormattedMessage id={"settings.notificationTemplates.failure.delete"} /> }
        return errMsg
    }

    saveCreateNewOrSaveAsTemplate = () => { //for update, create new temlate and save as template
        let findFormErr = this.checkFormValidation(); //updating field error'
        if (findFormErr.msg !== "") {
            this.setState({ validationMsg: findFormErr })
        } else {
            this.setState({ isLoadingData: true }, async () => {
                let reqBody = this.removeUnwantedProperties();
                let prepareEmailBody = { ...reqBody, systemDefined : this.state.currentBtnName === 'save' ? this.state.formData.systemDefined : false, active : this.state.currentBtnName === "saveAs" ? false : this.state.formData.active, emailBody: (this.state.formData.emailBody).replace(/\r?\n/g, ' <br/> '), name: this.state.currentBtnName === 'saveAs' ? this.state.saveAsName : this.state.formData.name, id: this.state.currentBtnName === 'save' ? this.state.formData.id : '' }
                let updateErrorMsg = this.updateResFailedMsg(this.state.currentBtnName)
                let resData = await storeNotificationTemplates(prepareEmailBody, { validateSuccess: false, errorTile: updateErrorMsg.errorTile, errorMessage: updateErrorMsg.errorMessage })
                if (resData) {
                    if (resData.data.status !== "FAIL") {
                        this.setState({
                            isOpenValidationModal: true, //open validation modal on status success response
                            validateModalResMsg: this.updateValidateModalResMsg(),
                            isDisplayModalCloseBtn: true, //if status success display only close button
                            isLoadingData: false,
                            validationMsg: { msg: "", type: "" },
                            saveAsName: "",
                        }, () => { //callback API based on status success. )
                            this.getNotificationTemplatesData(this.state.formData.category, this.state.currentBtnName)
                            this.setState({ currentBtnName: 'save' }) //for diaply save, save as, and delete button
                        })
                    } else this.setState({ isOpenValidationModal: false, isLoadingData: false })
                } else {
                    this.setState({ isOpenValidationModal: false, isLoadingData: false })
                }
            })
        }

    } //handle update template here 
    saveAsTemplate = () => { this.setState({ isOpenValidationModal: true, validateModalResMsg: '', isDisplayModalCloseBtn: false, validationMsg: { msg: '', type: '' } }) }
    deleteTemplate = () => { this.setState({ isOpenValidationModal: true, validateModalResMsg: <FormattedMessage id={'settings.notificationTemplates.text.deletetemplate.confirmationtext'} />, isDisplayModalCloseBtn: false, validationMsg: { msg: "", type: "" } }) }

    closeAlertMessage = () => {
        notificationTemplatesData.formFields.btmForm.map(item => item.isErr = false)
        this.setState({ validationMsg: { msg: "", type: "" } })
    }

    removeUnwantedProperties = () => {
        var obj = this.state.formData;
        delete obj.notificationTemplate;
        return obj
    }

    removeAllFormError = () => {
        notificationTemplatesData.formFields.btmForm.forEach(item => {
            if (item.isMandatory && item.isErr) item.isErr = false
        })
    }

    validationModalActionBtn = (value) => {
        if (this.state.currentBtnName === 'delete') { //if user clicked delete modal 'ok' button
            this.setState({ isOpenValidationModal: false }, async () => {
                if (value === 'ok') {
                    this.setState({ isLoadingData: true })
                    let reqBody = this.removeUnwantedProperties() //removed unwanted properties from 'this.state.formdata'
                    let updateErrorMsg = this.updateResFailedMsg(this.state.currentBtnName)
                    let dltResData = await deleteNotificationTemplates(reqBody, { validateSuccess: false, errorTile: updateErrorMsg.errorTile, errorMessage: updateErrorMsg.errorMessage })
                    if (dltResData) {
                        if (dltResData.data.status !== "FAIL") {
                            this.setState({
                                isOpenValidationModal: true, //open validation modal on status success response
                                validateModalResMsg: <FormattedMessage id={'settings.notificationTemplates.success.delete'} />, //display this message in validation modal body.
                                isDisplayModalCloseBtn: true, //if status success display only close button
                                isLoadingData: false,
                            }, () => this.getNotificationTemplatesData(this.state.formData.category, this.state.currentBtnName)) //callback API based on status success.
                        } else this.setState({ isOpenValidationModal: false, isLoadingData: false })
                    } else this.setState({ isOpenValidationModal: false, isLoadingData: false })
                }
            })
        } else if (this.state.currentBtnName === 'saveAs') {
            if (value === 'ok') {
                if (this.state.saveAsName === "") {
                    this.setState({ validationMsg: { msg: <FormattedMessage id={'settings.notificationTemplates.form.message.mandatory_name'} />, type: "danger", for: "validationModal" } })
                } else {
                    this.setState({ isOpenValidationModal: false })
                    this.saveCreateNewOrSaveAsTemplate();
                }
            } else {
                this.setState({ isOpenValidationModal: false, saveAsName: "", validationMsg: { msg: "", tyepe: "" } })
            }
        } else if (this.state.modalName === 'categoryChangeWarning' || this.state.modalName === 'templateChangeWarning' || this.state.modalName === 'createTemplateWarning') {
            if (value === 'ok') {
                this.removeAllFormError();
                this.setState({ isOpenValidationModal: false, validationMsg: { msg: "", tyepe: "" }, currentBtnName: this.state.modalName === 'createTemplateWarning' ? 'createTemplate' : 'save' }, () => {
                    if (this.state.modalName === 'categoryChangeWarning') {
                        this.getNotificationTemplatesData(this.state.notifiCatValBackUp, null)
                        this.setState({ formData: { ...this.state.formData, category: this.state.notifiCatValBackUp }, selectedTemplateCopy: { ...this.state.selectedTemplateCopy, category: this.state.notifiCatValBackUp }, updateFrom: '' })
                    } else if (this.state.modalName === 'templateChangeWarning') {
                        this.updateFormdataBasedOnNotifTempVal('notificationTemplate', this.state.notifiTempValBackUp)
                        this.setState({ updateFrom: '' })
                    } else if (this.state.modalName === 'createTemplateWarning') {
                        this.displayNewForm()
                    }
                })
            } else this.setState({ isOpenValidationModal: false })
        }
        else this.setState({ isOpenValidationModal: false })
    }

    render() {
        return (
            <NotificationTemplateContext.Provider value={{
                state: this.state,
                updateFormData: this.updateFormData,
                handleClickButton: this.handleClickModalActionButton,
                closeAlertMessage: this.closeAlertMessage,
                validationModalActionBtn: this.validationModalActionBtn,
                resetDataOnCloseModal: this.resetDataOnCloseModal,
                updateSaveAsFormData: this.updateSaveAsFormData,
                handleTagVariables: this.handleTagVariables,
                handleOnKeyDown: this.handleOnKeyDown
            }}>
                {this.props.children}
            </NotificationTemplateContext.Provider>
        )
    }
}

export default NotificationTemplateProvider