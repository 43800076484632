import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import SingleSelect from '../../../hoc/singleSelect';
import Button from '../../../hoc/button';
import { FormattedMessage } from 'react-intl';
import { disturbancesData } from '../../../utility/disturbanceAnalyticsUtility';
import { DisturbanceAnalyticsContext } from '../provider/disturbanceAnalyticsProvider';
import DisturbanceModal from './disturbanceModal';
import { fetchBrowserTimeZone, dateConvertion } from '../../../services/utilityService';

class DisturbanceAnalyticsContainer extends Component {
    static contextType = DisturbanceAnalyticsContext;
    render() {
        const { formData, isOpenValidateModal } = this.context.state;
        return (
            <Row className='disturbance-filter'>
                {disturbancesData.formFields.map((item, key) => {
                    // return <Col xs={4} key={key}>
                    return <Col key={key}>
                        <Row className='center-alighn-text'>
                            <Col md={item.type === 'singleSelect' ? 2 : 4} className='p-0'>{item.label} {item.value !== 'interval' ? fetchBrowserTimeZone('zoneAbbr', this.context.state.timeZone) : ""}</Col>
                            <Col md={item.type === 'singleSelect' ? 10 : 8} className={'pl-0'}>
                                {item.type === 'date' ?
                                    <div className="container-fluid p-0 m-0">
                                        <span className="date-input-field float-left">
                                            <input className='formatDate' value={moment(formData[item.value]).format("MM/DD/YYYY")} disabled={true} />
                                        </span>
                                        {(formData[item.value] !== null && formData[item.value] !== undefined && formData[item.value] !== "") ? 
                                        <DateRangePicker
                                            initialSettings={{
                                                singleDatePicker: true,
                                                showDropdowns: true,
                                                startDate: moment(dateConvertion(formData[item.value], 'endDate', this.context.state.timezone)),
                                                minYear: parseInt(moment().subtract(1, "year").format('YYYY'), 10),
                                                maxYear: parseInt(moment().add(20, "year").format('YYYY'), 10),
                                                maxDate:moment(dateConvertion(formData.endTimestamp, 'endDate', this.context.state.timezone)),
                                                showISOWeekNumbers: true,
                                                opens: 'left',
                                                autoApply: true,
                                            }}
                                            onCallback={(e, picker) => this.context.updateFilterFormData(item.value, e, picker)}
                                        >
                                            <span className="date-icon icon-width float-right"><FontAwesomeIcon icon={faCalendarAlt}/></span>
                                        </DateRangePicker>
                                        : "" }
                                    </div> :
                                    <span className='single-select-dropdown option-list'>
                                        <SingleSelect data={disturbancesData.intervalData} setType={(value) => this.context.updateFilterFormData('interval', value)} value={formData[item.value]} icon='fa-check' />
                                    </span>
                                }
                            </Col>
                        </Row>
                    </Col>
                })}
                <Col className="apply-btn-area pull-right p-0"> <Button btnText={<FormattedMessage id='dm.column.apply' />} className={`apply-btn pull-right ${new Date(formData["endTimestamp"]).getTime() >= new Date(formData["startTimestamp"]).getTime() ? "" : "disabled"}`} handleButtonClick={this.context.handleClickApplyBtn} /> </Col>
                {isOpenValidateModal ? <DisturbanceModal onBtnClickHandleBtn={this.context.handleValidateModalBtnClick} /> : ""}
            </Row>
        );
    }
}
export default DisturbanceAnalyticsContainer;