import React, { Component, Fragment } from 'react';
import RegisterContainer from './containers/registerContainer';
import { TreeConsumer } from '../../../treeview/provider/treeProvider';
import RegisterProvider from './provider/registerProvider';
import { connect } from 'react-redux';
import './index.css'

class FaultRegister extends Component {
    render() {
        return (
            <Fragment>
                <TreeConsumer>
                    {(context) => (
                        <RegisterProvider updatedTreeData={context.state.tree[0]} loadNextLevel={context.loadNextLevel} selectSearchedNode={context.selectSearchedNode} treeNode={context.state.highlightedNode.node} treeLoader={context.state.loading} breadCrumbName={context.state.selectedItem}>
                            <RegisterContainer />
                        </RegisterProvider>
                    )}
                </TreeConsumer>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableSubMenus: state.headerData.enableSubMenus
    };
}

export default connect(mapStateToProps, null)(FaultRegister);