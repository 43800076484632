import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { dashboardData } from '../../../utility/dashboardUtility';
import * as dashboardBuilderAction from '../actions/index';
import TimeFilter from '../../../hoc/timeFilter';
import { getWatchlistURL } from '../services/dashboardService';
import Loader from '../../../hoc/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import ManageWatchListModal from '../components/manageWatchListModal';
import PopModal from '../components/popupModal';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import ReactTablePropType from '../../../hoc/reactTablePropType';
import Button from '../../../hoc/button';
import Table from '../../../hoc/table';
import { updateFilterData } from '../../faultAnalysis/register/actions/index';
import { faultDetailsData } from '../../../utility/faultDetailsUtility';
import { disturbancesData } from '../../../utility/disturbanceAnalyticsUtility';
import { dateConvertion } from '../../../services/utilityService';
import AlertMessage from '../../../hoc/alert';
import TableMultiSortNumber from '../../../hoc/tableMultiSortNumber';
import Tooltip from '../../../hoc/tooltip';

class WatchListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRange: this.props.watchlistDateRange,
      watchlistArr: [],
      isOpenWatchListModal: false,
      isSort: false,
      popModalShow: false,
      payload: [],
      alertMessage: '',
      columnsData: dashboardData.columnsData.map(e => e.accessor === "disturbanceSum" ? {
        accessor: 'disturbanceSum', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.table.fullName.dist' />} />, className: this.props.roleAndPermission.licenseData.saifiEnabled ? 'clm-style' : '',
        Cell: ({ original }) => {
          return !this.props.roleAndPermission.licenseData.saifiEnabled ?
            <Tooltip baseContent={<span className="cursor-not-allowed">0</span>} message={<FormattedMessage id='groupmanagement.menus.tooltip' />} placement={'bottom'}
            />
            : <span>{original.disturbanceSum}</span>
        }
      } : e)
    }
  }
  componentDidMount() {
    this.getWatchListData();
  }

  getWatchListData = () => {
    this.setState({ isLoding: true })
    getWatchlistURL({ 'ORGID': this.props.orgId, 'RANGE': this.state.dateRange, ...this.props.requestparams })
      .then(data => {
        this.setState({ isLoding: false })
        var result = [];
        if (data && data.length > 0) {
          result = data[0].map(function (d1) {
            var marchedd2 = [];
            if (data && data.length > 1) {
              marchedd2 = data[1].filter(function (d2) {
                if ((d1['region'] === d2['region']) && (d1['substation'] === d2['substation']) && (d1['feeder'] === d2['feeder'])) {
                  return Object.assign({}, d2, d1)
                }
                return null
              });
              if (data.length === 2) {
                return Object.assign({}, marchedd2[0], d1);
              }
            }
            var marchedd3 = [];
            if (data && data.length === 3) {
              marchedd3 = data[2].filter(function (d3) {
                if ((d1['region'] === d3['region']) && (d1['substation'] === d3['substation']) && (d1['feeder'] === d3['feeder'])) {
                  return Object.assign({}, d3, d1)
                }
                return null
              });
              return Object.assign({}, marchedd3[0], marchedd2[0], d1);
            }
            return d1;
          });
        }
        this.setState({ watchlistArr: result })
      })
  }

  handleOnClickTimeFilter = (value) => {
    if (this.state.dateRange !== value) {
      this.props.updateWatchlistDateRangeData(value);
      this.setState({ dateRange: value }, () => { this.getWatchListData() })
    }
  }
  handleOpenManagageWatchList = () => {
    this.handleOpenModal();
  }
  handleCloseModal = () => {
    this.setState({ isOpenWatchListModal: false })
  }
  handleOpenModal = () => {
    this.setState({ isOpenWatchListModal: true })
  }
  getUpdatedWatchlistData = () => {
    this.getWatchListData()
  }

  columnClick = (state, rowInfo, column) => {
    if (column) {
      return {
        onClick: e => {
          let currentDate = new Date().getTime() - (1000 * 3600 * 24);
          let endDateTimestamp = dateConvertion(currentDate, 'endDate', this.props.roleAndPermission.timezone);
          let startDateTimestamp = endDateTimestamp + 1 - (this.state.dateRange * 1000 * 60 * 60 * 24)
          let endDateTimestampFault = dateConvertion(currentDate, 'endDate');
          let startDateTimestampFault = endDateTimestampFault + 1 - (this.state.dateRange * 1000 * 60 * 60 * 24)
          sessionStorage.setItem("faultTimeStamp",JSON.stringify({startTimestamp:dateConvertion(startDateTimestampFault, 'short'),endTimestamp:dateConvertion(endDateTimestampFault, 'short')}))
          let obj = { feederName: rowInfo.original.feeder, name: rowInfo.original.feeder, regionName: rowInfo.original.region, substationName: rowInfo.original.substation, type: "FEEDER" }
          if (this.props.roleAndPermission.licenseData.saidiEnabled&&(column.id === "sustainedCount" || column.id === "momentaryCount")) {
            let filterObj = column.id === "sustainedCount" ? {eventState: "", eventType: "Fault Sustained Interruption"} : {eventState: "", eventType: "Fault Momentary Interruption"};
            this.props.updateSelectedTreeFromDashboard(obj);
            this.props.updateFilterData({...filterObj, isReDirect: true});
            this.props.history.push("/events/details");
          }
          if (column.id === "disturbanceSum"&& this.props.roleAndPermission.licenseData.saifiEnabled) {
            disturbancesData.selectedDataByUser = {
              startTimestamp: dateConvertion(startDateTimestamp, 'short', this.props.roleAndPermission.timezone),
              endTimestamp: dateConvertion(endDateTimestamp, 'short', this.props.roleAndPermission.timezone),
              interval: this.state.dateRange === 31 ? 'Weekly' : 'Daily'
            }
            this.props.updateSelectedTreeFromDashboard(obj);
            this.props.history.push("/disturbances")
          }
          if (column.id === "maxLoad" || column.id === "maxUnbalance") {
            let { region, substation, feeder } = rowInfo.original;
            this.setState({ payload: [{ name: this.props.rootNodeName, type: "ROOTNODE" }, { name: region, type: "region" }, { name: substation, type: "substation" }, { name: feeder, type: "feeder" }] }, () => {
              this.handleOpenModalPLPU();
            })
          }
        }
      }
    } else {
      return "";
    }
  }

  handleCloseModalPLPU = () => {
    this.setState({ popModalShow: false })
  }
  handleOpenModalPLPU = () => {
    this.setState({ popModalShow: true })
  }

  handleSuccessAlert=(val)=>{
    if(val) this.setState({alertMessage: <FormattedMessage id='db.manageWatchlist.sucResponse' />})
    else this.setState({alertMessage:val})
  }

  closeAlertMessage = () => {
    this.setState({ alertMessage: ''})
  }

  render() {
    return (
      <Row className={'manageWatchlist'}>
        <Col>
          <Row className="breadcrumb-background watchlist-table-height" >
            <Col xs={3} className="center-title pad-left-top"> <FormattedMessage id='db.column.watchlist' /></Col>
            <Col xs={6}>{this.state.alertMessage && <AlertMessage position=" ml-2 mx-auto" type={'success'} message={this.state.alertMessage} timer={10000} setVisible={()=>this.closeAlertMessage()}/>}</Col>
            <Col xs={3} className="dashboard-chart-timeFilter center-title justify-content-right">
              {this.state.watchlistArr.length !== 0 ?
                <span className="customTooltip">
                  <FontAwesomeIcon className='cursor-pointer setting-icon' onClick={this.handleOpenManagageWatchList} icon={faCog} size="lg" />
                  <span className="tooltiptext tooltiptext-center">{<FormattedMessage id="db.column.manageWatchlist" />}</span>
                </span>
                : ""}
              <span className="dashboard-chart-timeFilter"><TimeFilter data={dashboardData.timeFilterData} handleOnClick={this.handleOnClickTimeFilter} selected={this.state.dateRange} /></span>
            </Col>
          </Row>
          <Row className= {this.state.watchlistArr.length>6 ? 'table-section': ''}>
            {this.state.isLoding === undefined || this.state.isLoding ? <Loader elementClass="tree-ajax-loader center-element" /> :
            this.state.watchlistArr.length !== 0 ?
              <ReactTablePropType
                reactTableContainer={
                  <Table 
                    tableColumn={this.state.columnsData}
                    tableRowData={this.state.watchlistArr}
                    showPagination={false}
                    pageSize={this.state.watchlistArr.length}
                    // multiSort={true}
                    getTdProps={this.columnClick}
                  />
                }
              />
              :
              <Button className="center-element add-feeders" handleButtonClick={this.handleOpenManagageWatchList} btnText={<FormattedMessage id='db.column.addFeedersToWatchlist' />} />
            }
          </Row>
          <div>
            {this.state.isOpenWatchListModal ? <ManageWatchListModal show={true} onHide={this.handleCloseModal} orgid={this.props.orgId} getupdatedwatchlistdata={this.getUpdatedWatchlistData} handleSuccessMessage={(val)=>this.handleSuccessAlert(val)}/> : ""}
            {this.state.popModalShow ? <PopModal show={true} onHide={this.handleCloseModalPLPU} breadcrumbData={this.state.payload} dateRange={this.state.dateRange} orgId={this.props.orgId} /> : ""}
          </div>
        </Col>
      </Row>
    )
  }
}
const mapStateToProps = state => {
  return {
    requestparams: state.dashboardData.requestParams,
    watchlistDateRange: state.dashboardData.watchlistDateRange,
    roleAndPermission: state.loginData.roleAndPermission
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedTreeFromDashboard: (data) => dispatch(dashboardBuilderAction.updateSelectedTreeFromDashboard(data)),
    updateWatchlistDateRangeData: (data) => dispatch(dashboardBuilderAction.updateWatchlistDateRangeData(data)),
    updateFilterData: (data) => dispatch(updateFilterData(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WatchListComponent));