import React, { Component } from 'react';
import { BrowserRouter, withRouter } from "react-router-dom";
import SubNavigations from '../../header/navigation/subNavigations';
import Menus from '../../config/menus.json';
import ManageRoutes from '../../routes/manageRoutes';
import { connect } from 'react-redux';
import './index.css';

class Manage extends Component {

    updateMenuItems = (data) => {
        data.emPermissions.forEach(permission => {
            Menus.manageSubMenu.forEach((menu, i) => {
                if (menu.key.includes("classAssignment") && (permission.permissionName === "UPDATE_CLASSASSIGNMENT_FILES" && permission.hasPermission === false)) {
                    Menus.manageSubMenu.splice(i, 1);
                }
                if(menu.key.includes("profiles") && (permission.permissionName === "READ_DEVICE_CONFIG_PROFILE" && permission.hasPermission === false)) {
                    menu.isDisabled = true;
                    menu.extraClass = "disabled"
                }
                if(menu.key.includes("site") && (data.roleName !== 'ROLE_ADMIN' && data.roleName !== 'ROLE_ORG_ADMIN')){
                    Menus.manageSubMenu.splice(i, 1);
                }
            });
        });
    }

    render() {
        if (Object.keys(this.props.roleAndPermission).length > 0) {
            this.updateMenuItems(this.props.roleAndPermission)
        }

        return (
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <div className="secondary-navbar col-md-12 col-12 p-0">
                    <SubNavigations menu={Menus['manageSubMenu']} />
                    <ManageRoutes />
                </div>
            </BrowserRouter >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default withRouter(connect(mapStateToProps, {})(Manage));