import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { logout } from '../../mainContent/userManagement/services/userService';
import { samlLogout } from '../../home/services/homeService';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { onSamlLogout, onSamlFailed } from '../actions/headerActionBuilder';
import {setLoader, logOut} from '../../login/actions/loginActionsBuilder';

class Logout extends Component {
    clearExpandColumns = () =>{
        ["deviceExpandColumn","otapExpandColumn","configStatusExpandColumn","userManagementExpandColumn","equipmentProfileExpandColumn", "cableProfileExpandColumn"].forEach(e => sessionStorage.clear(e));
    }

    handleLogout = () => {
        this.props.setLoader(true);
        if (this.props.roleAndPermission.samlEnabled) {
            samlLogout()
                .then(res => {
                    logout()
                        .then(data => {
                            this.props.onLogOut();
                            this.props.isSamlLogout(true);
                            this.props.history.push("/samlLogout");
                            this.props.setLoader(false);
                            this.clearExpandColumns();
                        }).catch(err => {
                            this.props.onLogOut();
                            this.props.isSamlLogout(true);
                            this.props.history.push("/samlLogout");
                            this.props.setLoader(false);
                            console.log("Other modules logout failed after SAML logout.", err);
                            this.clearExpandColumns();
                        })
                }).catch(error => {
                    this.props.onLogOut();
                    this.props.isSamlFailed(true);
                    this.props.history.push("/samlFailed");
                    this.props.setLoader(false);
                    console.log("SAML logout failed.", error);
                    this.clearExpandColumns();
                })
        } else {
            logout()
                .then(data => {
                    this.props.onLogOut();
                    this.props.setLoader(false);
                    this.clearExpandColumns();
                }).catch(err => {
                    this.props.onLogOut();
                    this.props.setLoader(false);
                    this.clearExpandColumns();
                })
        }
    }

    render() {
        return (
            <div onClick={this.handleLogout} className="noNavigationItemLink">
                <FormattedMessage id={`nav.main.logout`} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isSamlLogout: (data) => dispatch(onSamlLogout(data)),
        isSamlFailed: (data) => dispatch(onSamlFailed(data)),
        setLoader: (params) => dispatch(setLoader(params)),
        onLogOut: () => dispatch(logOut())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Logout));