import React, { Component } from 'react'
import { LicenceContext } from '../provider/licenceProvider'
import { licenceMngmntData } from '../../../utility/licenceManagementUtility'
import BlankPage from '../../../hoc/blankPage';
import { FormattedMessage } from 'react-intl';
import Table from '../../../hoc/table';

class LicenseManagementContainer extends Component {
    static contextType = LicenceContext;
    constructor(props) {
        super(props);
        this.state = {
            columnsData: licenceMngmntData.columnsData
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={this.context.state.licenceData.length === 0 ? 'licenceMgntTbl' : ""} >
                    {this.context.state.licenceData.length !== 0 ?
                        // <ReactTable
                        //     columns={this.state.columnsData}
                        //     data={this.context.state.tableData}
                        //     pageSize={this.context.state.tableData.length}
                        //     showPagination={false}
                        //     className="-striped -highlight"
                        // />
                        <Table
                            tableColumn={this.state.columnsData}
                            tableRowData={this.context.state.tableData}
                            showPagination={false}
                        />
                        :
                        !this.context.state.isLoading ? <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} /> : ""
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default LicenseManagementContainer;
