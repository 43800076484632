import React, {useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../../hoc/tooltip';
import {VirtualDeviceContext} from '../provider/virtualDeviceProvider';
import Alert from '../../../../hoc/alert';
import { FormattedMessage } from 'react-intl';

const VirtualDeviceActionComponent = () => {
    const contextData = useContext(VirtualDeviceContext);
    const roleName = contextData.roleAndPermission.roleName;
   
    return (
        <div className="btn-toolbar mr-1 mb-3" role="toolbar">
           { ((contextData.state.alertMessage.for ==='createOrUpdate' || contextData.state.alertMessage.for === 'deleteDevice') && contextData.state.alertMessage.type ==='success') || (contextData.state.alertMessage.for === 'uploadFile') ? 
             <Alert type={contextData.state.alertMessage?.type} message={contextData.state.alertMessage?.message} setVisible={()=>contextData.closeAlertMessage()} position={'position-center'} timer={10000}/> : ''}
            
            <div className="btn-group btn-group-icons float-right" role="group">
                <Tooltip baseContent={
                    <span onClick={() => contextData.handleAddVirtualDevice(true, 'Add')} className={ (roleName !== 'ROLE_ADMIN' && roleName !== 'ROLE_ORG_ADMIN') ? 'disabled btn btn-outline-secondary btn-light': 'btn btn-outline-secondary btn-light'}>
                        <FontAwesomeIcon icon={faPlus} size="sm" />
                    </span>} message={ <FormattedMessage id={"vdm.tooltip.addVirtualDevice"} /> } placement={'top'} />
            </div>
        </div>
    );
}

export default VirtualDeviceActionComponent;