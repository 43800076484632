import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import MarkerClusterer from '@google/markerclusterer';

import { getNodePath } from '../../../services/utilityService';
import { getMapCenter, getlatlong, getMapViewData } from '../services/mapService';
import { updateSelectedTreeFromDashboard } from '../../dashboard/actions/index';

export const MapContext = React.createContext();
let infoWindow, spiderCenterMarker, markerSettings, googleMapRef, googleRef, markers = [], markerPositions = {};

class MapProvider extends Component {

  constructor(props) {
    super(props)
    this.state = {
      treeNode: this.props.treeNode,
      loading: false,
      mapZoom: 5,
      center: {
        lat: 10.99835602,
        lng: 77.01502627
      },
      isOpenStreetViewModal: false,
      streetViewLabel: '',
      isLodingStreet: false,
      streetViewAvailable: false,
      roleAndPermission: this.props.roleAndPermission,
      breadCrumb: this.props.breadCrumb
    }
    this.OverlappingMarkerSpiderfier = null
    this.oms = null
    this.spiderfierOptions = {
      keepSpiderfied: true,
      nearbyDistance: 1,
      legWeight: 1,
      circleFootSeparation: 100
    }
    this.spiderfierAllowedZoom = 15
    this.clusterOptions = {
      styles: [
        {
          textColor: '#000',
          url: require(`../../../resources/images/markercluster.png`),
          height: 56,
          width: 56
        }
      ],
      maxZoom: this.spiderfierAllowedZoom,
      isNodeviceLocationFound:false
    }
    this.fetchMapDataStart = false;
  }

  componentWillUnmount() {
    this.stopInterval();
  }

  componentDidMount = async () => {
    if (this.state.treeNode && Object.keys(this.state.treeNode).length > 0 && (!this.props.treeLoader)) {
      this.getMapData();
    }
  }

  // Construct the marker icon for site
  siteMarkerIcon = (siteInfo) => {
    let defaultIconName = 'BBB'; // Consider all phases empty as default icon
    let markerIconName = '';
    let siteStatusList = [];
    if (siteInfo.phases) {
        for (let phase in siteInfo.phases) {
        let phaseInfo = siteInfo.phases[phase];
        if (phaseInfo.numberOfActiveFaults && phaseInfo.numberOfActiveFaults > 0) {
          markerIconName += 'R';
        } else if (phaseInfo.numberOfActiveInterruptions && phaseInfo.numberOfActiveInterruptions > 0) {
          markerIconName += 'O';
        } else if (!phaseInfo.serialNumber) {
          markerIconName += 'B';
        } else {
          markerIconName += 'G';
        }

        siteStatusList.push(phaseInfo.status);
      }
    }
    if (siteStatusList && siteStatusList.length) {
      markerIconName += (siteStatusList.indexOf('OFFLINE') !== -1) ? 'O' : ((siteStatusList.indexOf('INTERMITTENT') !== -1) ? 'I' : ((siteStatusList.indexOf('UNREGISTERED') !== -1) ? 'U' : ''));
    }

    // Check for UM3+ position count before returning the icon name
    return (markerIconName ? markerIconName : defaultIconName) + (((siteInfo.siteDeviceType === 'UM3+') && (siteInfo.um3TotalPositonCount > 1)) ? '-UM3' : '');
  };

  closeModal = () => {
    googleMapRef.getStreetView().setVisible(false);
    this.setState({ isOpenStreetViewModal: false })
  }
  
  getTooltipInfo = (phase, phaseData, siteData) => {

    this.toggleStreetView = (lat, long, streetViewLabel, streetViewAngle) => {
      this.setState({ 
        isOpenStreetViewModal: true,
        isLodingStreet: true,
        streetViewLabel: streetViewLabel
      });
      let streetViewService = new googleRef.StreetViewService();
      let STREETVIEW_MAX_DISTANCE = 100;
      let latLng = new googleRef.LatLng(lat, long);

      streetViewService.getPanoramaByLocation(latLng, STREETVIEW_MAX_DISTANCE, (streetViewPanoramaData, status) => {
        if (status === googleRef.StreetViewStatus.OK) {
          this.setState({ 
            isLodingStreet: false,
            streetViewAvailable: true
          });
          let panorama = new googleRef.StreetViewPanorama(document.getElementById('streetView'),{
            pov: {
              heading: 300,
              pitch: (streetViewAngle != null) ? streetViewAngle : 30
            },
            addressControl: false
          });
          panorama.setPano(streetViewPanoramaData.location.pano);
          panorama.setVisible(true);
        } else {
          this.setState({ 
            isLodingStreet: false,
            streetViewAvailable: false
          });
        }
      });
    }  
  
    this.navigatePage = (pageName, siteData) => {
      let path = {};
      if (pageName === 'faultevents') {
        path = { regionName: siteData.region, substationName: siteData.substation, feederName: siteData.feeder, type: "FEEDER" }
      } else {
        if ((siteData.lateral == null) || (siteData.lateral === '')) {
          path = { regionName: siteData.region, substationName: siteData.substation, feederName: siteData.feeder, siteName: siteData.site, type: "SITE" }
        } else {
          path = { regionName: siteData.region, substationName: siteData.substation, feederName: siteData.feeder, siteName: siteData.site, lateralName: siteData.lateral, lateralSiteName: siteData.lateralSite, type: "LATERAL_SITE" }
        }
      }
      this.props.updateSelectedTreeFromDashboard(path);
      
      switch(pageName) {
        case 'managedevices':
          this.props.history.push("/manage/details");
          break;
        case 'faultevents':
          this.props.history.push("/events/details");
          break;
        case 'logi':
          this.props.history.push("/monitor");
          break;
        default:
          break;
      }

    };

    window.mapScope = this;
    let siteName = siteData.lateral === null ? siteData.site :  siteData.lateral +" - "+ siteData.lateralSite;
    let info = "<div class='tooltip-title'><span class='fas'>&#xf0da;</span><span>   "+ siteData.region + " - "+ siteData.substation + " - "+ siteData.feeder + " - " + siteName +"</span></div><div>";
    info += "<fieldset class='tooltip-wrap' style='width: 100%;'> <table class='table tooltip-table'><thead><tr><th>Phase</th><th>Status</th><th>Serial Number</th></thead>";
    let vc10DeviceExist = false;

    for(let phase in siteData.phases){
//      classToBeAdded = !this.props.roleAndPermission.licenseData.saidiEnabled ? "pointer-events-visible cursor-not-allowed disabled" : '';
//      titleToBeDisplayed = !this.props.roleAndPermission.licenseData.saidiEnabled ? "Available to License" : '';
      info += "<tbody><tr><td>"+ phase +"</td><td>"+ siteData.phases[phase].status +"</td><td>"+ siteData.phases[phase].serialNumber +"</td></tr>";
      if(siteData.phases[phase].deviceType === 'VC10') vc10DeviceExist = true;
    }
      
    info += "</tbody></table>";

    let lat = siteData.latitude;
    let long = siteData.longitude;
    let streetViewLabel = " " +siteData.region + " - " + siteData.substation + " - "+ siteData.feeder + " - " + siteName;
    let streetViewAngle = (siteData.siteDeviceType === "UM3+") ? 0 : 30;
    
    let dataToBeEnabled = "";

    if (this.props.roleAndPermission.licenseData.saidiEnabled && this.props.roleAndPermission.licenseData.monitorEnabled) {
      // Disable fault events if device type is VC10
      if (vc10DeviceExist)
        dataToBeEnabled += "<span class='tooltip-links' title='Not applicable for VC10'><span class='disabled pointer-events-visible cursor-not-allowed'>Fault Events</span></span>" +
          "<span class='tooltip-links txt-color' onclick='javascript:mapScope.navigatePage(\"logi\"," + JSON.stringify(siteData) + ")'>Log-I</span>";
      else 
      dataToBeEnabled +=
        "<span class='tooltip-links txt-color' onclick='javascript:mapScope.navigatePage(\"faultevents\"," + JSON.stringify(siteData) + ")'>Fault Events</span>" +
        "<span class='tooltip-links txt-color' onclick='javascript:mapScope.navigatePage(\"logi\"," + JSON.stringify(siteData) + ")'>Log-I</span>";
    }
    else if (!this.props.roleAndPermission.licenseData.saidiEnabled && !this.props.roleAndPermission.licenseData.monitorEnabled) {
      dataToBeEnabled +=
        "<span class='tooltip-links' title='Available to License'><span class=' pointer-events-visible cursor-not-allowed'>Fault Events</span></span>"+
        "<span class='tooltip-links' title='Available to License'><span class=' pointer-events-visible cursor-not-allowed'>Log-I</span></span>";
    }
    else if (this.props.roleAndPermission.licenseData.saidiEnabled) {
      // Disable fault events if device type is VC10
      if (vc10DeviceExist)
        dataToBeEnabled += "<span class='tooltip-links' title='Not applicable for VC10'><span class='disabled pointer-events-visible cursor-not-allowed'>Fault Events</span></span>" +
          "<span class='tooltip-links txt-color' onclick='javascript:mapScope.navigatePage(\"logi\"," + JSON.stringify(siteData) + ")'>Log-I</span>";
      else 
      dataToBeEnabled +=
        "<span class='tooltip-links txt-color' onclick='javascript:mapScope.navigatePage(\"faultevents\"," + JSON.stringify(siteData) + ")'>Fault Events</span>" +
        "<span class='tooltip-links' title='Available to License'><span class='disabled pointer-events-visible cursor-not-allowed'>Log-I</span></span>";
    }
    else if (this.props.roleAndPermission.licenseData.monitorEnabled) {
      dataToBeEnabled += "<span class='tooltip-links' title='Available to License'><span class=' pointer-events-visible cursor-not-allowed'>Fault Events</span></span>"+
        "<span class='tooltip-links txt-color' onclick='javascript:mapScope.navigatePage(\"logi\"," + JSON.stringify(siteData) + ")'>Log-I</span>";
    }

    info += "</div><hr><div>" +
      "<span class='tooltip-links txt-color' onclick='javascript:mapScope.navigatePage(\"managedevices\"," + JSON.stringify(siteData) + ")'>Device Details</span>" +
      dataToBeEnabled +
      "<span class='tooltip-links txt-color' onclick='javascript:mapScope.toggleStreetView(" + lat + "," + long + "," + JSON.stringify(streetViewLabel) + "," + streetViewAngle + ")'>Street View</span>" +
      "<a class='tooltip-links txt-color' target='_blank' href='https://www.google.com/maps/dir/?api=1&destination="+ lat +","+ long +"' ><u>Get Directions<u/></a>" +
      "</div>";

    return info;
      
  };


  reloadMapData = async() => {
    if (this.markerCluster) this.markerCluster.clearMarkers();
    if (this.oms) this.unspiderfyHandler();
    this.setState({ loading: true });
    this.fetchMapDataStart = true;
    const params = {'ORGID': this.props.tree[0].id, 'apiType': 'data'};
    let data = await getMapViewData(params);
    this.setState({ loading: false });
    if (data && data.hasOwnProperty('sites')) {
      markers = [];
      if(infoWindow) infoWindow.close();
      infoWindow = new googleRef.InfoWindow();
      let content = '';
      let infoCallback = function(marker, content, infowindow){
        return function() {
          infoWindow.close();
          infoWindow.setContent(marker.customInfo);
          infoWindow.open(googleMapRef, marker);  
        };
      };
      for (let site in data.sites) {
        let markerInfo = data.sites[site];
        let phasesList = Object.values(markerInfo.phases);
        let serialNumber = null;
        for (let i = 0; i < phasesList.length; i++) {
          if (phasesList[i].serialNumber) {
            serialNumber = phasesList[i].serialNumber;
            break;
          }
        }
        if (!serialNumber) { continue; }
        let latLng = new googleRef.LatLng(markerInfo.latitude, markerInfo.longitude);
        let markerIconName = this.siteMarkerIcon(markerInfo);
        let markerIconPath = require('../../../resources/images/' + markerIconName + '.png');
        let markerOptions = (markerIconName.length === 10) ? markerSettings.pshadow : ((markerIconName.length === 8) ? markerSettings.ptrio : ((markerIconName.length === 7) ? markerSettings.plus : ((markerIconName.length === 6) ? markerSettings.shadow : ((markerIconName.length === 4) ? markerSettings.trio : markerSettings.plain))));
  
        let marker = new googleRef.Marker({
          position: latLng,
          serialNumber: serialNumber,
          status: markerInfo.status ? markerInfo.status : '',
          siteDeviceType: markerInfo.siteDeviceType ? markerInfo.siteDeviceType : '',
          positionNo: markerInfo.position ? markerInfo.position : 0,
          positionCount: markerInfo.um3TotalPositonCount ? markerInfo.um3TotalPositonCount : 0,
          numberOfActiveFaults: markerInfo.activeFaultsCount ? markerInfo.activeFaultsCount : 0,
          numberOfActiveInterruptions: markerInfo.activeInterruptionsCount ? markerInfo.activeInterruptionsCount : 0,
          markerIconPath: '../../../resources/images/' + markerIconName + '.png',
          icon: {
            url: markerIconPath,
            size: markerOptions.size,
            origin: markerOptions.origin,
            anchor: markerOptions.anchor
          },
          zIndex: markerInfo.activeFaultsCount ? 8888 : (markerInfo.activeInterruptionsCount ? 8887 : 8886),
          customInfo: this.getTooltipInfo('A', markerInfo.phases['A'], markerInfo)	// TODO: not clear yet
        });
        googleRef.event.addListener(marker, 'mouseover', infoCallback(marker, content, infoWindow));
        markers.push(marker);
        markerPositions[serialNumber] = latLng;
      }  
    }
    this.drawMarkers()
    this.setState({ loading: false });
  }

  stopInterval = () => {
    clearInterval(this.intervalId);
  }

  drawMarkers = () => {
    this.stopInterval();
    this.intervalId = setInterval(this.checkMarkers, 1000);
  }
  
  checkMarkers = () => {
    if(markers && markers.length > 0) {
      this.setState({ loading: true });
      MarkerClusterer.CALCULATOR = function (markers, numStyles) {
        let index = 0;
        let count = 0;
        for(let marker in markers){
        if(markers[marker].status != null)
          count++
        }
        count = count.toString();
        let title = "Number of devices in this cluster: " + count + ". Click to zoom";

        let dv = count;
        while (dv !== 0) {
          dv = parseInt(dv / 10, 10);
          index++;
        }

        index = Math.min(index, numStyles);
        return {
          text: count,
          index: index,
          title: title
        };
      };
      if (this.markerCluster) this.markerCluster.clearMarkers();
      this.markerCluster = new MarkerClusterer(googleMapRef, markers, this.clusterOptions);
      // Spidefier construction
      this.oms = new this.OverlappingMarkerSpiderfier.OverlappingMarkerSpiderfier(googleMapRef, this.spiderfierOptions);

      this.oms.addListener('spiderfy', function(smarkers) {
        // Update the marker icons
        for(let i = 0; i < smarkers.length; i++) {
          let markerIcon = smarkers[i].markerIconPath;
          if(markerIcon && markerIcon.indexOf("UM3")) {
            let newMarkerIconUrl = markerIcon.replace("-UM3", "");
            let markerIconName = newMarkerIconUrl.split("/").splice(-1)[0].replace(".png", "");
            let markerOptions = (markerIconName.length === 10) ? markerSettings.pshadow : ((markerIconName.length === 8) ? markerSettings.ptrio : ((markerIconName.length === 7) ? markerSettings.plus : ((markerIconName.length === 6) ? markerSettings.shadow : ((markerIconName.length === 4) ? markerSettings.trio : markerSettings.plain))));
            let markerIconPath = require('../../../resources/images/' + markerIconName + '.png');
            smarkers[i].setIcon({
              url: markerIconPath,
              size: markerOptions.size,
              origin: markerOptions.origin,
              anchor: markerOptions.anchor
            });
          }
        }

        // Enable the center for spiderfier view
        if (spiderCenterMarker) {
          spiderCenterMarker.setMap(googleMapRef);
          spiderCenterMarker.setPosition(markerPositions[smarkers[0].serialNumber] ? markerPositions[smarkers[0].serialNumber] : smarkers[0].getPosition());	
        }
      });

      this.oms.addListener("unspiderfy", smarkers => {
        // Update the marker icons
        for(let i = 0; i < smarkers.length; i++) {
          if((smarkers[i].siteDeviceType === 'UM3+') && (smarkers[i].positionCount > 1)) {
            let newMarkerIconUrl = smarkers[i].markerIconPath;
            let markerIconName = newMarkerIconUrl.split("/").splice(-1)[0].replace(".png", "");
            let markerOptions = (markerIconName.length === 10) ? markerSettings.pshadow : ((markerIconName.length === 8) ? markerSettings.ptrio : ((markerIconName.length === 7) ? markerSettings.plus : ((markerIconName.length === 6) ? markerSettings.shadow : ((markerIconName.length === 4) ? markerSettings.trio : markerSettings.plain))));
            let markerIconPath = require('../../../resources/images/' + markerIconName + '.png');
            smarkers[i].setIcon({
              url: markerIconPath,
              size: markerOptions.size,
              origin: markerOptions.origin,
              anchor: markerOptions.anchor
            });
          }
        }

        // Disable the spiderfier center
        if(spiderCenterMarker) spiderCenterMarker.setMap(null);
        if(infoWindow) infoWindow.close();
      });

        // Handle spiderfier construction
      let constructSpiderfierSet = () => {
        if (googleMapRef.getZoom() > this.spiderfierAllowedZoom) {
          // Find the markers inside visible area
          let bounds = googleMapRef.getBounds();
          for(let marker in markers) {
            let currMarker = markers[marker];
            if(bounds.contains(currMarker.getPosition())) {
              this.oms.addMarker(currMarker);
            }
          }
        }
      }
      
      constructSpiderfierSet();
      
      // Handle zoom change event for spiderfier
      googleMapRef.addListener('zoom_changed', function() {
        constructSpiderfierSet();
      });

      this.setState({ loading: false });
      this.stopInterval();
    } else {
      if(!this.fetchMapDataStart) {
        this.setState({ loading: true });
        this.reloadMapData();
      }
    }
  };


  extendMap = (data) => {
    let place1 = new googleRef.LatLng(data.lat_min,data.lon_min);
    let place2 = new googleRef.LatLng(data.lat_max,data.lon_max);
    let bounds = new googleRef.LatLngBounds();
    bounds.extend(place1);
    bounds.extend(place2);
    googleMapRef.fitBounds(bounds);
    let mapcenter = googleMapRef.getCenter();
    googleMapRef.setCenter(new googleRef.LatLng((mapcenter.lat() + 0.0000001), mapcenter.lng()));
  }

  getMapData = async() => {
    if(this.state.treeNode && this.state.treeNode.routeParams){
      const params = {'NODEPATH': getNodePath(this.state.treeNode.routeParams, false, false, 'mapView'), 'apiType': 'data'};
      this.setState({ 
        loading: true,
        isOpenStreetViewModal: false
      });
      getlatlong(params)
      .then(data => {
        setTimeout(() => {
          let isNodeviceLocationFound = false;
          if(data && (data.lat_min !== null) && (data.lon_min !== null) && (data.lat_max !== null) && (data.lon_max !== null)) {
            this.extendMap(data);
            this.reloadMapData();
          } else {
            googleMapRef.setOptions({zoom:5});
            if(this.state.treeNode.type === "SITE" || this.state.treeNode.type === "LATERAL_SITE"){
              isNodeviceLocationFound = true;
            }
            this.setState({ loading: false,isNodeviceLocationFound:isNodeviceLocationFound });
          }
        }, 1000);
      }).catch(err => {
        this.setState({ loading: false })
      });
    }
  }

  modalClose = () => {
    this.setState({
      isNodeviceLocationFound: false
    })
}

  componentDidUpdate(prevProps) {
    if (prevProps.treeNode && this.props.treeNode) {
      if ((prevProps.treeNode['name'] !== this.props.treeNode['name']) || (prevProps.treeNode['type'] !== this.props.treeNode['type']) || (prevProps.treeNode['id'] !== this.props.treeNode['id']) ) {
        this.setState({ 
          treeNode: this.props.treeNode,
          breadCrumb: this.props.breadCrumb
        }, () => this.getMapData() );
      }
    } else if(this.props.treeNode) {
      this.setState({
        treeNode: this.props.treeNode,
        breadCrumb: this.props.breadCrumb

      });
    }
  }

  getMapOptions = (maps) => {
    return {
      streetViewControl: false,
      styles: [{
          featureType: "poi.business",
          elementType: "labels",
          stylers: [{
              visibility: "off"
          }]
      }],
      infoWindow: {
        show: false,
        center: this.state.center,
        options: {
          boxStyle: {
            backgroundColor: "#CCC",
            border: "1px solid #999",
            borderRadius: "5px",
            width: "200px",
            height: "auto",
            padding: "10px"
          },
          content: "Device Info",
          zIndex: 99999999
        }
      },
      markersEvents: {
        mouseover: function (marker, eventName, model, args) {
          googleMapRef.infoWindow.options.content = model.customInfo;
          googleMapRef.infoWindow.center = {latitude: model.latitude, longitude: model.longitude};
          googleMapRef.infoWindow.show = true;
        }
      },
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
          style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: maps.ControlPosition.TOP_LEFT,
          mapTypeIds: [
              maps.MapTypeId.ROADMAP,
              maps.MapTypeId.SATELLITE,
              maps.MapTypeId.HYBRID,
              maps.MapTypeId.TERRAIN
          ]
      },
    };
  }

  unspiderfyHandler = () => {
    this.oms.unspiderfy();
  };

  onGoogleApiLoaded = async(map, maps) => {
      googleMapRef = map;
      googleRef = maps;
      markerSettings = {
        // Default marker properties
        plain: {
          size: new googleRef.Size(45, 64),
          origin: new googleRef.Point(0, 0),
          anchor: new googleRef.Point(23, 64)
        },
  
        // Marker with plus properties
        plus: {
          size: new googleRef.Size(45, 77),
          origin: new googleRef.Point(0, 0),
          anchor: new googleRef.Point(23, 77)
        },
  
        // Marker with triangle properties
        trio: {
          size: new googleRef.Size(66, 78),
          origin: new googleRef.Point(0, 0),
          anchor: new googleRef.Point(22, 78)
        },
  
        // Marker with plus & triangle properties
        ptrio: {
          size: new googleRef.Size(66, 97),
          origin: new googleRef.Point(0, 0),
          anchor: new googleRef.Point(22, 97)
        },
  
        // Marker with shadow
        shadow: {
          size: new googleRef.Size(58, 88),
          origin: new googleRef.Point(0, 0),
          anchor: new googleRef.Point(29, 88)
        },
  
        // Marker with shadow & plus
        pshadow: {
          size: new googleRef.Size(57, 87),
          origin: new googleRef.Point(0, 0),
          anchor: new googleRef.Point(29, 87)
        }
      };  
      this.OverlappingMarkerSpiderfier = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
      const params = {'ORGID': this.props.tree[0].id, 'apiType': 'data'};
      this.setState({ loading: true });
      let center = await getMapCenter(params);
      this.setState({ loading: false });
      if (center && center.length > 0) {
        this.setState({
          center: {
            lat: center[0],
            lng: center[1]
          }
        });
      }
      this.drawMarkers();
      spiderCenterMarker = new googleRef.Marker({
        icon: {
          url: require(`../../../resources/images/minus.png`),
          size: new googleRef.Size(19, 19),
          origin: new googleRef.Point(0, 0),
          anchor: new googleRef.Point(9, 9)
        },
        zIndex: 9999
      });
      googleRef.event.addListener(spiderCenterMarker, 'click', this.unspiderfyHandler);
  }

  render() {
    return (
      <MapContext.Provider value={{
        state: this.state,
        getOptions: this.getMapOptions,
        googleApiLoaded: this.onGoogleApiLoaded,
        reloadMap: this.reloadMapData,
        onCloseModal: this.closeModal,
        modalClose:this.modalClose,
        getMapData:this.getMapData
      }}>
        {this.props.children}
      </MapContext.Provider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    roleAndPermission: state.loginData.roleAndPermission,
    tree: state.treeviewData.tree
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      updateSelectedTreeFromDashboard: (data) => dispatch(updateSelectedTreeFromDashboard(data))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MapProvider));

export const MapConsumer = MapContext.Consumer;