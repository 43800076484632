import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Loader from '../../../../../hoc/loader';
import BlankPage from '../../../../../hoc/loader';
import ModalWindow from '../../../../../hoc/modelWindow';
import HighchartsReact from 'highcharts-react-official';
import HighCharts from "highcharts/highcharts";
import moment from 'moment-timezone';

class ConnectedPercentageModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    updateYAxis = (yAxix) => {
        let tmpArr = [];
        yAxix.forEach((item, key) => {
            if (key === 0) {
                tmpArr.push({
                    ...item,
                    title: { ...item.title, text: false },
                    labels: {
                        formatter: function () {
                            if (this.value === 0) {
                                return 'Disconnected';
                            } else if (this.value === 1) {
                                return 'Connected';
                            } else {
                                return null;
                            }
                        }
                    }
                })
            } else { tmpArr.push({ ...item, title: { ...item.title, text: false }, }) }
        })
        return tmpArr
    }

    updateName = (series, formatMessage) => {
        series.map(item => item.name = this.updateLable(item.id, formatMessage));
        return series;
    }

    updateLable = (s, formatMessage) => {
        var name = ''
        if (s === 'current') name = formatMessage({id: "groupmanagement.connectPercentage.label.current"});
        else if (s === 'disturbance') name = formatMessage({id: "groupmanagement.connectPercentage.label.tcpConnection"});
        else if (s === 'current_A') name = formatMessage({id: "groupmanagement.connectPercentage.label.phaseACurrent"});
        else if (s === 'current_B') name = formatMessage({id: "groupmanagement.connectPercentage.label.phaseBCurrent"});
        else if (s === 'current_C') name = formatMessage({id: "groupmanagement.connectPercentage.label.phaseCCurrent"});
        return name;
    }

    render() {
        var timeZone = this.props.timezone;
        const { formatMessage } = this.props.intl;
        return (
            <ModalWindow 
            show={true}
            size="xl"
            dialogClassName={"connectedPercentageModal"}
            title={<span className="popup-title">{this.props.rowData.serialNumber} : {formatMessage({id: "dm.connectiondetail.connection.title"})} {this.props.rowData.connected}% {formatMessage({ id: "dm.connectiondetail.inlastweek.title"})}</span>}
            onBtnClickHandler={this.onBtnClickHandlera}
            onHide={this.props.onCloseModal}
            modeldata={{
                content: <React.Fragment>
                     {this.props.isLodingData === undefined || this.props.isLodingData ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                        {this.props.chartData.series !== undefined && this.props.chartData.series.length !== 0 ?
                        <HighchartsReact
                            highcharts={HighCharts}
                            options={{
                                ...this.props.chartData,
                                series: this.updateName(this.props.chartData.series, formatMessage),
                                tooltip: {
                                    useHTML: true,
                                    ...this.props.chartData.tooltip,
                                    formatter: function () {
                                        var s = moment(new Date(this.x)).tz(timeZone).format('dddd, MMM DD, YYYY hh:mm:ss A') + " (" +
                                            // fetchBrowserTimeZone('browserTimeZone', timeZone ) 
                                            moment.tz(timeZone).format('z') + ")";

                                        this.points.forEach((point) => {
                                            var name = point.series.name;
                                            if (name === 'TCP connection') {
                                                var val = (point.y === 0) ? 'Disconnected' : 'Connected';
                                                s += '<br/>' + name + ': ' + val + '';
                                            } else {
                                                s += '<br/>' + name + ': ' + point.y + '';
                                            }
                                        });
                                        return s;
                                    }
                                },
                                time: {
                                    getTimezoneOffset: function (timestamp) {
                                        // var timeZone = this.props.timezone,
                                        var timezoneOffset = -moment.tz(timestamp, timeZone).utcOffset();
                                        return timezoneOffset;
                                    }
                                },
                                yAxis: this.updateYAxis(this.props.chartData.yAxis, timeZone),
                            }}
                        />
                        : <BlankPage blankText={formatMessage({id:'db.common.noDataAvailable'}) } />}
                </React.Fragment>
            }}

            
            />
        )
    }
}

export default injectIntl(ConnectedPercentageModal);