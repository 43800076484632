import React, { Component } from 'react'
import 'react-table-6/react-table.css';
import { EProfileContext } from '../provider/eProfileProvider'
import ModalWindow from '../../../hoc/modelWindow';
import { FormattedMessage } from 'react-intl';
import Loader from '../../../hoc/loader';
import AlertMessage from '../../../hoc/alert';
import TextField from '../../../hoc/form/textField';
import CustomImgIcon from '../../../hoc/customImgIcon';
import { Row, Col, Form, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import PopOverMessage from '../../../hoc/popOver';
import { equipmentProfileData } from '../../../utility/equimentprofileUtility';

class AddOrEditEProfilesModal extends Component {
    static contextType = EProfileContext;

    getPopOver() {
        return (<PopOverMessage
            trigger="focus"
            placement="auto"
            popoverBody={<CustomImgIcon icon="TPDefinitions.png" width='35rem' height='20rem' />}
            displayPopOver={<FontAwesomeIcon icon={faQuestionCircle} size="sm" />} />)
    }

    render() {
        const { modalLoader, errmsg, selectedProfile, transformerFormData, cableFormData, transformerFields, cableFields, openAddOrEditModal, isEditClicked } = this.context.state;

        const formFields = selectedProfile === equipmentProfileData.profileList[0] ? transformerFields : cableFields;
        const formValue = selectedProfile === equipmentProfileData.profileList[0] ? transformerFormData : cableFormData;
        const createTitle = selectedProfile === equipmentProfileData.profileList[0] ? <FormattedMessage id='db.equipmentprofile.createTProfile' /> : <FormattedMessage id='db.equipmentprofile.createCProfile' />;
        const editTitle = selectedProfile === equipmentProfileData.profileList[0] ? <FormattedMessage id='db.equipmentprofile.editTProfile' /> : <FormattedMessage id='db.equipmentprofile.editCProfile' />;

        let modalTitle = <><div className="popup-title" id="contained-modal-title-sm">{isEditClicked ? editTitle : createTitle} {selectedProfile === equipmentProfileData.profileList[0] && this.getPopOver()}</div></>
        let modalBody = <>
            {modalLoader && <Loader elementClass="tree-ajax-loader center-element" />}
            {errmsg && <><AlertMessage position="w-100" message={errmsg} type="danger" setVisible={this.context.closeErrorMessages} /><br /></>}
            <Row>
                <Col>
                    {formFields.map((item, key) => {
                        return (
                            <Form.Row key={key} className='inline-center-item mb-3 add-edit-profile'>
                                <Col className={`col-5 offset-1 ${item.isErr ? 'font-weight-bold form-err-color-red' : 'font-weight-bold'}`}>{item.label}{item.isMandatory ? '*' : ""}:</Col>
                                <Col className="col-5 form-right">
                                    <TextField
                                        type={item.type}
                                        field={item.value}
                                        value={formValue[item.value]}
                                        placeholder={item.label}
                                        handleOnchange={this.context.handleOnchangeForm}
                                        size={'sm'}
                                        isDisabled={isEditClicked && (item.value === 'transformerProfileName' || item.value === 'cableProfileName')}
                                        className={item.isErr ? 'form-err-border-red' : ""}
                                    />
                                    {item?.isErr && <Row className='text-right mt-1 mb-1 form-err-color-red'><Col>{item.isErr}</Col></Row>}
                                </Col>
                            </Form.Row>
                        )
                    }
                    )}
                </Col>
            </Row>
        </>
        let modalFooter = [{ className: "modalCancelBtn", name: <FormattedMessage id='configure.popup.button.cancel' />, value: "cancel" }, { className: 'modalSaveBtn', name: <FormattedMessage id='dm.column.save' />, value: "add" }]

        return (
            <>
                {openAddOrEditModal &&
                    <ModalWindow
                        show={openAddOrEditModal}
                        onHide={() => this.context.closeModal()}
                        size={"lg"}
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.context.onClickSaveOrUpdateBtn}
                    />}
            </>
        );
    }
}

export default AddOrEditEProfilesModal;
