import React, { useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt, faCompressAlt, faRetweet, faTimes } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../../../hoc/tooltip';
import Table from '../../../../../hoc/table';
import BlankPage from '../../../../../hoc/blankPage'
import { FormattedMessage } from 'react-intl';
import { tableData } from '../../../../../utility/virtualDevicerManagementUtility';
import {VirtualDeviceContext} from '../../provider/virtualDeviceProvider';
import ModalWindow from '../../../../../hoc/modelWindow';
import Loader from '../../../../../hoc/loader';
import Alert from '../../../../../hoc/alert';
import CloudOfflineOutLine from '../../../../../resources/images/cloud-offline-outline.svg'
import CloudOutline from '../../../../../resources/images/cloud-outline.svg'

const VirtualDeviceLiveViewModal=()=>{

    const contextData = useContext(VirtualDeviceContext);
    const [columnsData] = useState([...tableData.deviceLiveViewColumn,]);
    const [exapandOrCompress, setExapandOrCompress] = useState('compress')
    const [pageSize, setPageSize] = useState(100)


    const handleClickExpandOrCollapse=(val)=> setExapandOrCompress(val)

    const onPageSizeChange = (sizePerPage) => {
        setPageSize(sizePerPage)
    }
    return(
        <Row>
            <ModalWindow
                show={true}
                size="md"
                dialogClassName={navigator.platform.match('Mac') ? "live-view-dnpc" : "live-view-dnpc-window" }
                title={<FormattedMessage id='vdm.modal.liveView' />}
                onBtnClickHandler={(val)=> ()=>{}}
                onHide={() => contextData.onCloseModal()}
                modeldata={{
                    content: <div>
                    <Row className='device-live-view' style={{height: exapandOrCompress === 'compress'? '41%' : '90%' }}>
                    {contextData.state.isLoadingLiveViewPointUpdate && <Loader elementClass="tree-ajax-loader center-element" /> }
                        <Col>
                            <Row >
                                {contextData.state.alertMessage.for ==='updateLiveview' && contextData.state.alertMessage.message !=='' && 
                                    <Alert type={contextData.state.alertMessage?.type} message={contextData.state.alertMessage?.message} setVisible={()=>contextData.closeAlertMessage()} position={'position-center'} timer={4000}/> 
                                }
                                <Col className='font-weight-bold m-1'> <FormattedMessage id='vdm.modal.liveView' /> - {contextData.state.clickedDeviceName} <span className='pl-4'> {!contextData.state.isLoadingLiveViewData && <> <FormattedMessage id='vdm.modal.liveView.lastTimeStamp' /> : {contextData.state.lastTimeStamp ?  contextData.state.lastTimeStamp : "N/A"}</>} </span> </Col>
                                    <Col className='text-right'>
                                        <div className="float-right btn-toolbar mb-1" role="toolbar">
                                        <div className="btn-group btn-group-icons" role="group">
                                                <span className='lock-loader-position'> {contextData.state.isLoadingLockResData && <Loader elementClass="tree-ajax-loader center-element" /> }
                                                    <Tooltip baseContent={<span onClick={()=> contextData.handleLockVirtualDevice(!contextData.state.clickedRowData.locked)} className={contextData.state.isLoadingLiveViewData || contextData.roleAndPermission.roleName !== 'ROLE_ADMIN' && contextData.roleAndPermission.roleName.roleName !== 'ROLE_ORG_ADMIN' || contextData.state.liveViewTableData.length === 0 ? "lockunlockoutline btn btn-outline-secondary disabled btn-light" : "lockunlockoutline btn btn-outline-secondary btn-light"}><img src={ contextData.state.clickedRowData.locked ? CloudOfflineOutLine : CloudOutline } className='lockUnlock' /> </span>} message={contextData.state.clickedRowData.locked ? <FormattedMessage id='vdm.tooltip.locked' /> : <FormattedMessage id='vdm.tooltip.unlocked' />} placement={'top'} />&nbsp;
                                                </span>
                                                <Tooltip baseContent={<span onClick={()=> contextData.handleRefreshLiveView()} className={contextData.state.isLoadingLiveViewData ? "btn btn-outline-secondary disabled btn-light" : "btn btn-outline-secondary btn-light"}> <FontAwesomeIcon icon={ faRetweet} size="sm" /> </span>} message={<FormattedMessage id='vdm.modal.liveView.refresh' />} placement={'top'} />&nbsp;
                                                <Tooltip baseContent={
                                                <span onClick={()=>handleClickExpandOrCollapse( exapandOrCompress === 'compress' ? 'expand' : 'compress')} className={ (contextData.state.isLoadingLiveViewData || contextData.state.liveViewTableData.length === 0) ? 'btn btn-outline-secondary disabled btn-light' : "btn btn-outline-secondary btn-light"}>
                                                    <FontAwesomeIcon icon={ exapandOrCompress === 'compress'? faExpandAlt : faCompressAlt } size="sm" />
                                                </span>} message={exapandOrCompress === 'compress'? 'Expand' : 'Compress' } placement={'top'} />&nbsp;
        
                                            <Tooltip baseContent={
                                                <span onClick={() => contextData.handleCloseDeviceLiveViewModal(false)} className={ contextData.state.isLoadingLiveViewData ? "btn btn-outline-secondary disabled btn-light" : "btn btn-outline-secondary btn-light"}>  <FontAwesomeIcon icon={ faTimes} size="sm" /> </span>} message={<FormattedMessage id='db.common.close' />} placement={'top'}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    { contextData.state.isLoadingLiveViewData ? <div className='table-area'><Loader elementClass="tree-ajax-loader center-element" /></div> 
                                        : 
                                    contextData.state.liveViewTableData.length ?
                                        <span className={exapandOrCompress === 'expand' ? 'expand-view' : 'compress-view'}>
                                            <Table
                                                tableColumn={columnsData}
                                                tableRowData={contextData.state.liveViewTableData}
                                                pageSize={pageSize}
                                                totalSize={contextData.state.liveViewTableData.length}
                                                pageSizeOptions={tableData.pageSizeOptions}
                                                onPageSizeChange={(sizePerPage)=>onPageSizeChange(sizePerPage)}
                                                multiSort={false}
                                                getTableSortedData={contextData.getTableSortedData}
                                            />
                                        </span>
                                        :
                                        <div className='table-area'><BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} /></div>}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                }}
            />
        </Row>
    )
}

export default VirtualDeviceLiveViewModal;