import { FormattedMessage } from 'react-intl';
import React, { Fragment } from 'react';
import Actions from '../mainContent/manage/details/components/columnActionsComponent';
import DeviceDetails from '../mainContent/manage/details/components/deviceDetails/deviceDetails';
import DisplayTextEllipsis from '../hoc/displayTextEllipsis';
import SystemLogsTableAction from '../mainContent/manage/details/components/deviceDetails/tabComponents/systemLogTableAction';
import ConnectedPercentage from '../mainContent/manage/details/components/connectedPercentage/connectedPercentage';
import { faCheckCircle, faExchangeAlt, faExclamationTriangle, faMinusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import '../mainContent/manage/index.css';
import { fetchBrowserTimeZone, getDateConvertedString } from '../services/utilityService';
import { formatDateAndTime } from '../filters/dateHelper';
import Tooltip from '../hoc/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function intlFormater(column) {
    return (
        <Fragment>
            {
                column.dataField ? <FormattedMessage id={`dm.column.${column.dataField}`} /> :
                    <Fragment>
                        <FormattedMessage id={`dm.column.${column.column.id}`} /> <span className={column.column.sortedOrder > 0 ? "disp-sortedOrder" : ""}>{column.column.sortedOrder > 0 ? `${column.column.sortedOrder}` : ""}</span>
                    </Fragment>
            }
        </Fragment>
    );
}

function getBatteryRemaining(row) {
    return row.original.deviceType === "ZM1" ? row.value ? `${row.value} %` : "" : "N/A"
}

function displayMultiInfo(item,type) {
    if (item.row.deviceType === "UM3+") {
        if (item.original.positions.length > 0) {
            return <div>{item.original.positions.map((pos, i) => <div className={!pos[type] ? 'visibile-hide' : ''} key={i}>{pos[type] ? pos[type] : '-'}</div>)}</div>
        }
    }
    else {
        return item.row[type]
    }
}

export const tableOptions = {
    pageNo: 1,
    pageSize: 100,
    pageSizeOptions: [100, 200, 500, 1000],
    paginationSize: 4,
    firstPageText: 'First',
    prePageText: 'Prev',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Prev page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    disableFilter: false, // This flag is used to enable/disable the column filter
    filterSelectionCount: 0,
    summaryFilterSelectionCount: 0,
    selectedRows: [],
    tableColumns: [
        {
            Header: intlFormater, 
            accessor: 'region',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'substation',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'feeder',
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
            Cell: row => (
                displayMultiInfo(row, 'feeder')
            )
        }, {
            Header: intlFormater,
            accessor: 'lateral',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
            Cell: row => (
                displayMultiInfo(row,'lateral')
            )
        }, {
            Header: intlFormater,
            accessor: 'site',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
            Cell: row => (
                displayMultiInfo(row,'site')
            )
        }, {
            Header: intlFormater,
            accessor: 'serialNumber',
            onFilterClicked: false,
            enteredText: "", //Entered Text
            sortedOrder: 0, //For displaying Sorted Order
            Cell: (row) => {
                return row.original.status !== "UNREGISTERED" ? (<DeviceDetails data={row.original} orgId={tableOptions.orgId} />) : row.value;
            }
        }, {
            Header: intlFormater,
            accessor: 'phase',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
            Cell: row => (
                displayMultiInfo(row,'phase')
            )
        }, {
            Header: intlFormater,
            accessor: 'status',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
            Cell: row => (
                `${row.value}` === "ONLINE" ?<Tooltip baseContent={<FontAwesomeIcon icon={faCheckCircle} size="sm"/>} message={"Online"} placement={'top'} /> :
                    `${row.value}` === "UNREGISTERED" ?<Tooltip baseContent={<FontAwesomeIcon icon={faExchangeAlt} size="sm"/>} message={"Unregistered"} placement={'top'} />:
                        `${row.value}` === "STANDBY" ? <Tooltip baseContent={<FontAwesomeIcon icon={faMinusCircle} size="sm"/>} message={"Stand By"} placement={'top'} />:
                            `${row.value}` === "OFFLINE" ?<Tooltip baseContent={<FontAwesomeIcon icon={faTimesCircle} size="sm"/>} message={"Offline"} placement={'top'} />:
                                `${row.value}` === "INTERMITTENT" ?<Tooltip baseContent={<FontAwesomeIcon icon={faExclamationTriangle} size="sm"/>} message={"Intermittent"} placement={'top'} />: ""
            ),
        }, {
            Header: intlFormater,
            accessor: 'deviceType',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'deviceState',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'softwareVersion',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'profileName', //profile Name
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'configStatus', //Profile Status
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'otapStatus', //FW Upgrade Status
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'siteLocation',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'dnp3PointMapVersion',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'ipAddress',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'description',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'fieldNotes',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
            Cell: (row) => {
                return (<DisplayTextEllipsis data={row.original.fieldNotes} />);
            },
        },
        {
            Header: intlFormater,
            accessor: 'gpsCoordinates',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'lastSuccess',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order,
            Cell: row => (
                getDateConvertedString(row.value)
            ),
        }, {
            Header: intlFormater,
            accessor: 'connected',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
            Cell: (row) => {
                return (<ConnectedPercentage data={row} />);
            },
        }, {
            Header: intlFormater,
            accessor: 'zm1BatteryRemaining',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
            Cell: row => (
                getBatteryRemaining(row)
            ),
        },{
            Header: intlFormater,
            accessor: 'imei',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'iccid',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'apn',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
        }, {
            Header: intlFormater,
            accessor: 'reason',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
            minWidth: 130,
            Cell: (row) => {
                return (<DisplayTextEllipsis data={row.original.reason} />);
            },
        }, {
            Header: intlFormater,
            accessor: 'tier',
            onFilterClicked: false,
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            sortedOrder: 0, //For displaying Sorted Order
            Cell: (row) => {
                return row.original.deviceType === "UM1" ? row.value ? row.value : "" : "N/A"
            }
        }, {
            Header: intlFormater,
            accessor: 'actions',
            isDummyField: true,
            Cell: (row) => {
                return (<Actions data={row.original} orgId={tableOptions.orgId} />);
            },
            headerStyle: { width: '120px' },
            sortable: false,
            filterable: false
        }
    ],
    customFilterColumns: [
        {
            dataField: 'serialNumber',
            hidden: false,
            headerFormatter: intlFormater,
            type: 'textBox',
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            summaryItems: [], //Persistance Summary Filter Items
            summarySelectedItems: [], //Saved Filters for Summary
            length: 0
        },
        {
            dataField: 'state',
            hidden: false,
            headerFormatter: intlFormater,
            type: 'dropdown',
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            summaryItems: [], //Persistance Summary Filter Items
            summarySelectedItems: [], //Saved Filters for Summary
            length: 0
        },
        {
            dataField: 'softwareVersions',
            hidden: false,
            headerFormatter: intlFormater,
            type: 'dropdown',
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            summaryItems: [], //Persistance Summary Filter Items
            summarySelectedItems: [], //Saved Filters for Summary
            length: 0
        },
        {
            dataField: 'deviceType',
            hidden: false,
            headerFormatter: intlFormater,
            type: 'dropdown',
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            summaryItems: [], //Persistance Summary Filter Items
            summarySelectedItems: [], //Saved Filters for Summary
            length: 0
        },
        {
            dataField: 'profileStatus',
            hidden: false,
            headerFormatter: intlFormater,
            type: 'dropdown',
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            summaryItems: [], //Persistance Summary Filter Items
            summarySelectedItems: [], //Saved Filters for Summary
            length: 0
        },
        {
            dataField: 'profileNames',
            hidden: false,
            headerFormatter: intlFormater,
            type: 'dropdown',
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            summaryItems: [], //Persistance Summary Filter Items
            summarySelectedItems: [], //Saved Filters for Summary
            length: 0
        },
        {
            dataField: 'statuses',
            hidden: false,
            headerFormatter: intlFormater,
            type: 'dropdown',
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            summaryItems: [], //Persistance Summary Filter Items
            summarySelectedItems: [], //Saved Filters for Summary
            length: 0
        },
        {
            dataField: 'fwUpgradeStatus',
            hidden: false,
            headerFormatter: intlFormater,
            type: 'dropdown',
            data: [],
            selectedItems: [], //Saved Filters
            items: [], //Persistance Filter Items
            summaryItems: [], //Persistance Summary Filter Items
            summarySelectedItems: [], //Saved Filters for Summary
            length: 0
        },
        
    ],
    customFilterButtons: [
        {
            dataField: 'apply',
            hidden: false,
            headerFormatter: intlFormater,
            action: 'apply'
        },
        {
            dataField: 'resetAll',
            hidden: false,
            headerFormatter: intlFormater,
            action: 'reset'
        },
        {
            dataField: 'saveapply',
            hidden: true,
            headerFormatter: intlFormater,
            action: 'saveandapply'
        }
    ],
//    paginationTotalRenderer: this.customTotal,
    sizePerPageList: [{
        text: '10', value: 10
    }, {
        text: '50', value: 50
    }, {
        text: '100', value: 100
    }, {
        text: '250', value: 250
    }],
    pageComingFrom: ""
}

export const payload = {
    commTypes: [],
    deviceTypes: [],
    fwUpgradeStatuses: [],
    networkGroupNames: [],
    profileStatuses: [],
    searchPattern: "",
    softwareVersions: [],
    states: [],
    statuses: []
}

export const modelOptions = {
    title: '',
    show: false,
    onHide: false,
    size: 'large',
    content: ''
}

export const mangeDetailsColumFilterData = {
    columFilter: tableOptions.tableColumns.filter(values => !values.isDummyField).map(column => {
        return { label: <FormattedMessage id={`dm.column.${column.accessor}`} />, value: column.accessor }
    })
}

export const displayColumns = {
    data: ['checkbox', 'serialNumber', 'phase', 'status', 'deviceType', 'deviceState', 'softwareVersion', 'connected', 'actions']
}

export const detailsModalData = {
    currentTab: 'summary',
    allTabs: [
        { label: <FormattedMessage id={"nav.manage.submain.summary"}/>, value: 'summary' },
        { label: <FormattedMessage id={"dm.tab.name.logI"}/>, value: 'logi' },
        { label: <FormattedMessage id={"dm.tab.name.aed"}/>, value: 'anomaly' },
        { label: <FormattedMessage id={"dm.tab.name.sensorSettings"}/>, value: 'nonCfci' },
        { label: <FormattedMessage id={"dm.tab.name.cFCI"}/>, value: 'cfci' },
        { label: <FormattedMessage id={"dm.tab.name.processes"}/>, value: 'processes' },
        { label: <FormattedMessage id={"db.heatMap.device"}/>, value: 'device' },
        { label: <FormattedMessage id={"dm.tab.name.batteryDetails"}/>, value: 'battery' },
        { label: <FormattedMessage id={"dm.tab.name.healthReport"}/>, value: 'healthReport' },
        { label: <FormattedMessage id={"configure.title.security"}/>, value: 'security' },
        { label: <FormattedMessage id={"dm.tab.name.diagnostics"}/>, value: 'systemlogs' },
        { label: <FormattedMessage id={"dm.tab.name.diagnostics"}/>, value: 'loggerFiles' },
    ],
    processesData: [
        { accessor: 'jobType', Header: <FormattedMessage id={"dm.tab.processes.table.ProcessType"}/>, style: { whiteSpace: 'unset' } },
        { accessor: 'status', Header: <FormattedMessage id={"dm.tab.processes.table.processStatus"}/>, style: { whiteSpace: 'unset' } },
        { accessor: 'jobSource', Header: <FormattedMessage id={"dm.tab.processes.table.createdBy"}/>, style: { whiteSpace: 'unset' } },
        { accessor: 'createdDate', Header: <><FormattedMessage id={"dm.tab.processes.table.createdAtIST"}/>{fetchBrowserTimeZone()}</>, style: { whiteSpace: 'unset' }, Cell: (original) => formatDateAndTime(original.row.createdDate) },
    ],
    generatedLogsData: [
        { accessor: 'requestedDate', Header: <><FormattedMessage id={"dm.tab.generatedLog.table.requestedAtIST"}/>{fetchBrowserTimeZone()}</>, style: { whiteSpace: 'unset' }, Cell: (original) => formatDateAndTime(original.row.requestedDate) },
        { accessor: 'userName', Header: <FormattedMessage id={"dm.tab.generatedLog.table.user"}/>, style: { whiteSpace: 'unset' } },
        { accessor: 'status', Header: <FormattedMessage id={"db.sysAdmin.status"} />, style: { whiteSpace: 'unset' } },
        { accessor: 'action', Header: <FormattedMessage id={"db.common.action"} />, 
            Cell: (row) => {
            return (<SystemLogsTableAction data={row.original} />);
            }, style: { whiteSpace: 'unset' } 
        },
    ],
}

export const optionsData = {
    tempUnit: '(F)',
    colors: ['#6cc66c', '#FF7474', '#7495BE', '#434348'], // controls which colors are used. '#ff3300','#6cc66c', '#4b75aa','#ff8566', '#37fb37','#b3d0f4'
    dashStyles: ['Solid', 'ShortDash', 'ShortDot'],
    dataColumns: ['disturbance'],
    credits: {
        enabled: false
    },
    chart: {
        alignTicks: false, //required for multiple series
        zoomType: 'x',
        minRange: 1000 * 60 * 60,
        height: 400,
        reflow: true,
        resetZoomButton: {
            theme: {
                states: {
                    hover: {
                        fill: '#D98C15',
                        stroke: '#BF7400',
                    }
                }
            },
            position: {
                align: 'right', // right by default
                verticalAlign: 'top',
                x: -80,
                y: 10
            },
            relativeTo: 'chart'
        }
    },
    title: {
        text: '',
        style: {
            display: 'none'
        }
    },
    exporting: {
        enabled: false,
    },
    navigator: {
        enabled: false
    },
    rangeSelector: {
        enabled: false
    },
    scrollbar: {
        enabled: false
    },
    tooltip: {
        borderWidth: 1,
        borderColor: '#aeceea',
        backgroundColor: '#fafafa',
        useHTML: true,
        style: { fontFamily: "'univers', Arial, sans-serif", fontSize: '1.5vh', color: "#333333" },
        split: false,
        shared: true,
        valueDecimals: 1,
        // formatter: function () {
        //     var s = moment(new Date(this.x)).tz("America/Los_Angeles").format('dddd MMM DD YYYY hh:mm:ss A') + " " +
        //     fetchBrowserTimeZone('browserTimeZone', "America/Los_Angeles") 
        //     // moment.tz('America/Los_Angeles').format('z')  // need to pass role and permission response timeZone data
        //     ;
        //     this.points.forEach((point)=> {
        //         var name = point.series.name;
        //         if (name == 'TCP connection') {
        //             // var val = (point.y === 0) ? 'Disconnected' : 'Connected';
        //             var val = (point.y === 0) ?  <FormattedMessage id={"dm.connectiondetail.connection.title.disconnected"}/> : <FormattedMessage id={"dm.connectiondetail.connection.title"}/>;
        //             s += '<br/>'+ name +': ' + val + '';
        //         } else {
        //             s += '<br/>'+ name +': ' + point.y + '';
        //         }
        //     });

        //     return s;
        // }

    },
    xAxis: {
        title: {
            text: '',
            style: { fontFamily: "'univers', Arial, sans-serif", fontSize: 14, color: "#333333" }
        },
        labels: {
            style: { fontFamily: "'univers', Arial, sans-serif", fontSize: 14, color: "#333333" },
        },
        gridLineWidth: 1,
        gridLineDashStyle: 'dot',
        type: 'datetime',
        dateTimeLabelFormats: {
            day: '%e. %b'
        },
        
        lineColor: "#ddd",
        ordinal: false,        
    },
    // time: {
    //     getTimezoneOffset: function (timestamp) {
    //         var zone = 'America/Los_Angeles',
    //             timezoneOffset = -moment.tz(timestamp, zone).utcOffset();

    //         return timezoneOffset;
    //     }
    //  },
    yAxis: [
        {
            allowDecimals: false,
            stackLabels: {
                enabled: false
            },
            // labels: {
            //     style: { fontFamily: "'univers', Arial, sans-serif", fontSize: 14, color: "#333333" },
            //     x: -10,
            //     align: 'right'
            // },
            title: {
                text: false,
                style: { fontFamily: "'univers', Arial, sans-serif", fontSize: 14, color: "#333333" }
            },
            min: 0,
            max: 1.5,
            tickInterval: 0.5,
            tickPixelInterval: 5,
            // labels: {
            //     formatter: function () {
            //         if (this.value === 0) {
            //             return 'Disconnected';
            //         } else if (this.value === 1) {
            //             return 'Connected';
            //         } else {
            //             return null;
            //         }
            //     }
            // },
            gridLineWidth: 1,
            gridLineDashStyle: 'dot',
            lineWidth: 1,
            opposite: false,
            showEmpty: false
        },
        {
            allowDecimals: true,
            labels: {
                style: { fontFamily: "'univers', Arial, sans-serif", fontSize: 14, color: "#333333" },
                x: 10,
                align: 'left'
            },
            title: {
                text: false,
                style: { fontFamily: "'univers', Arial, sans-serif", fontSize: 14, color: "#333333" }
            },
            lineWidth: 1,
            opposite: true,
            gridLineWidth: 0,
            gridLineDashStyle: 'dot',
            showEmpty: false
        }
    ],
    legend: {
        enabled: true,
        itemStyle: { fontFamily: "'univers', Arial, sans-serif", fontSize: 14, color: "#333333" },
        borderRadius: 3,
        borderColor: '#ccc',
        symbolHeight: 10,
        symbolWidth: 10,
        symbolRadius: 3,
        useHTML: true,
    },
    plotOptions: {
        line: {
            connectNulls: false,
            marker: {
                enabled: true,
                symbol: 'circle',
                radius: 0
            }
        },
        series: {
            connectNulls: false,
            gapSize: 36000000,
            marker: {
                radius: 0
            },
        },
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false
            },
            marker: {
                enabled: true,
                symbol: 'circle',
                radius: 2
            }
        }
    },
}

export const manageReportconfig = {
    reportTreeData: [],
    treeCheckboxVisible: 'all',
    treeCheckboxDisable: false,
    endDate: '',
    startDate: '',
    phaseIdList: ['conflictReport', 'allSites'],
    selectedPhaseId: 'conflictReport',
    phasesList : [ { value: true, name: 'A', shortname: "A" },{ value: true, name: 'B', shortname:"B" },{ value: true, name: 'C', shortname:"C" }],
    pointsList:[
      // { value: true, name: 'Feeder', shortname: "Feeder" },
      // { value: true, name: 'Site', shortname: "Site" },
      { value: true, name: 'Conductor Current (A<sub>rms</sub>)', shortname: "Conductor_RMS_Current" },
      { value: true, name: 'Conductor Temperature ', shortname: "Conductor_Temperature" },
      { value: true, name: 'Active Up Time (Seconds) ', shortname: "Active_Up_Time" },
      { value: true, name: 'Total Up Time (Seconds)', shortname: "Total_Up_Time" },
      { value: false, name: 'Energy Reserve Voltage (mV)', shortname: "Energy_Reserve_Voltage" },
      { value: false, name: 'Latitude', shortname: "Latitude" },
      { value: false, name: 'Longitude', shortname: "Longitude" },
      { value: false, name: 'Altitude', shortname: "Altitude" },
      { value: false, name: 'Current Direction (maps to CurrentDirectionReversed)', shortname: "Current_Direction" },
      { value: false, name: 'Disturbance Counter', shortname: "Disturbance_Counter" }
      // { value: false, name: 'RSSI', shortname: "RSSI" }
    ],
    amperage: '',
    haBelowAmp: '',
    fileFormat : [{ value: "HTML", label: "HTML" },{ value: "EXCEL", label: "EXCEL" },{ value: "TEXT", label: "TEXT" }],
    fileType :[{ value: "COMMA", label: "COMMA" },{ value: "PIPE", label: "PIPE" },{ value: "TAB", label: "TAB" }],
    errorMsgObj: {type : 'warning', msg: ''},
    showInlineMsg: false,
    healthPointsList:[
        {value: true, name: 'Power supply problem', shortname:"MM3_PowerSupply", group:'MM3' },
        {value: false, name: 'Power supply problem', shortname:"UM3_PowerSupply", group:'UM3+' },
        {value: false, name: 'Sensor EEPROM problem', shortname:"UM3_SensorEeprom", group:'UM3+'  },
        {value: false, name: 'Configuration problem', shortname:"UM3_Configuration", group:'UM3+'  },
        {value: false, name: 'Invalid number of active positions', shortname:"UM3_NumInvPositions", group:'UM3+' },
        {value: false, name: 'Invalid RTC time', shortname:"UM3_InvRTCTime", group:'UM3+' },
        {value: false, name: 'GPS problem', shortname:"UM3_GPSProblem", group:'UM3+' },
        {value: false, name: 'Power supply problem', shortname:"ZM1_PowerSupply", group:'ZM1' },
        {value: false, name: 'SD card detached', shortname:"ZM1_SDDetached", group:'ZM1' },
        {value: false, name: 'EField board detached', shortname:"ZM1_EFieldDetached", group:'ZM1' },
        {value: false, name: 'SIM card detached', shortname:"ZM1_SimDetached", group:'ZM1' },
        {value: false, name: 'SD card not bound to MCU', shortname:"ZM1_SDNotBound", group:'ZM1' },
        {value: false, name: 'File system low', shortname:"ZM1_FileSysLow", group:'ZM1' },
        {value: false, name: 'File system full', shortname:"ZM1_FileSysFull", group:'ZM1' },
        {value: false, name: 'Battery low', shortname:"ZM1_BatteryLow", group:'ZM1' }
    ]
}

export const allDeviceData = {
    "MM3": [],
    "MM3ai": [],
    "UM3+": [],
    "ZM1": [],
    "UM1":[],
    "VC10":[],
}

export const classAssignmentTabs = [{ label: "UM3+", value: "UM3+" }, { label: "ZM1", value: "ZM1" }, { label: "MM3", value: "MM3" }, { label: "MM3ai", value: "MM3ai" }];

export const exportDeviceData ={
    exportData: [{ label: <FormattedMessage id='common.text.download.excel' />, value: 'excel' }, { label: <FormattedMessage id='common.text.download.csv' />, value: 'csv' }]
}