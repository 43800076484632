import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { disturbancesData, countersChartData } from '../../../utility/disturbanceAnalyticsUtility';
import { getNodePath, dateConvertion, getBrowserTimeZone } from '../../../services/utilityService';
import { getCountersData, exportData } from '../services/disturbancesService';

export const DisturbanceAnalyticsContext = React.createContext();

class DisturbanceAnalyticsProvider extends Component {

  constructor(props) {
    super(props)
    this.state = {
      treeLoading: false,
      treeNode: this.props.treeNode,
      countersData: {},
      currentTabVal: disturbancesData.currentTab,
      tableColumnData: [],
      tableRowData: [],
      isLoadingData: false,
      timeZone: this.props.roleAndPermission.timezone,
      formData: disturbancesData.selectedDataByUser,
      chartOptions: countersChartData,
      isOpenValidateModal: false,
      isValidationErr : false
    }
  }

  componentDidMount() {
    if (this.state.treeNode && this.state.treeNode.length !== 0) this.setDate();
    if (this.state.treeNode && this.state.treeNode.length !== 0 && (!this.props.treeLoader)){ 
      !this.props.dashboardFlag&&this.dashboardFlagCheckLoadData() 
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      treeNode: nextProps.treeNode,
      breadCrumbName: nextProps.breadCrumbName,
      treeLoading: nextProps.treeLoader
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.state.treeLoading && JSON.stringify(this.state.breadCrumbName) !== JSON.stringify(prevProps.breadCrumbName) && this.state.treeNode.type !== 'ROOTNODE' && this.state.treeNode.type !== 'LATERAL' && this.state.treeNode.siteDeviceType !== 'ZM1') {
      this.setDate();
      !this.props.dashboardFlag&& this.dashboardFlagCheckLoadData()
    }
  }

  setDate = () => {
    let currentDate = new Date().getTime();
    let endDateTimestamp = dateConvertion(currentDate, 'endDate', this.props.roleAndPermission.timezone);
    let startDateTimestamp = endDateTimestamp + 1 - (7 * 1000 * 60 * 60 * 24);
    disturbancesData.selectedDataByUser.endTimestamp = disturbancesData.selectedDataByUser.endTimestamp !== "" ? disturbancesData.selectedDataByUser.endTimestamp : dateConvertion(endDateTimestamp, 'short', this.props.roleAndPermission.timezone);
    disturbancesData.selectedDataByUser.startTimestamp = disturbancesData.selectedDataByUser.startTimestamp !== "" ? disturbancesData.selectedDataByUser.startTimestamp : dateConvertion(startDateTimestamp, 'short', this.props.roleAndPermission.timezone)
    this.setState({ formData: disturbancesData.selectedDataByUser });
  }

  dashboardFlagCheckLoadData = ()=>{
    this.getAllCountersData();
    this.props.setDashboardFlag()
  }

  getAllCountersData = async () => {
    let checkValidate = this.beforeApplyFilters(disturbancesData.selectedDataByUser);
    if (checkValidate) {
      this.setState({ isOpenValidateModal: true, tableRowData: [], tableColumnData: [] })
    } else {
      let requestParams = this.updateReqParams(disturbancesData.selectedDataByUser)
      const params = { ...requestParams, 'NODEPATH': getNodePath(this.state.treeNode.routeParams, true), 'apiType': 'data' };
      this.setState({ tableColumnData: [], tableRowData: [], isLoadingData: true })
      getCountersData(params)
        .then(data => {
          if (data.status !== 'FAIL') {
            this.setState({ countersData: data, isLoadingData: false })
            let tableData = Object.keys(data.map).length > 0 ? data : [];
            this.processTableData(tableData);
          }
          else{
            this.setState({ isLoadingData: false })
          }
        }).catch(err => this.setState({ isLoadingData: false }))
    }
    !this.props.dashboardFlag&&this.props.setDashboardFlag()
  }

  processTableData = (resData) => {
    let dataset = [];
    let columnOrder = [];
    let mapData = resData && (Object.keys(resData.map).length > 0) ? resData.map : [];
    let isSiteLevel = (this.state.treeNode && (this.state.treeNode.type === 'SITE'));

    Object.entries(mapData[Object.keys(mapData)[0]]).forEach(([value, key]) => {
      columnOrder.push({ 'name': value });
    })

    if (mapData && (Object.keys(mapData).length > 0)) {
      if (isSiteLevel) {
        let data = mapData[this.state.treeNode['name']];
        let phase = ['A', 'B', 'C'];
        phase.forEach((str) => {
          let o = {};
          o['name'] = str;
          columnOrder.forEach((obj) => {
            let dateObj = data[obj.name];
            o[obj.name] = dateObj[str.toLowerCase()];
          });
          dataset.push(o);
        });

      } else {
        Object.entries(mapData).forEach(([keys, values]) => {
          let obj = {};
          obj['name'] = keys;
          Object.entries(values).forEach(([key, value]) => {
            obj[key] = value;
            if (value && (typeof (value) === 'object')) {
              let total = 0;
              Object.entries(value).forEach(([k, val]) => {
                total = total + val;
              });
              obj[key + '_total'] = total;
            }
          });
          dataset.push(obj);
        });
      }
    }
    this.setState({ tableColumnData: columnOrder, tableRowData: dataset })
  }

  handleTabSelect = (crntVal) => {
    disturbancesData.currentTab = crntVal
    this.setState({ currentTabVal: crntVal })
  }

  updateFilterFormData = (field, start) => this.setState({ formData: { ...this.state.formData, [field]: start } })

  handleClickApplyBtn = () => {
    let data = this.state.formData;
    let checkValidate = this.beforeApplyFilters(data);
    disturbancesData.selectedDataByUser = { startTimestamp: data.startTimestamp, endTimestamp: data.endTimestamp, interval: data.interval }
    if (checkValidate) {
      this.setState({ isOpenValidateModal: true, isValidationErr : true, tableRowData: [], tableColumnData: [] })
    } else {
      this.setState({ isValidationErr: false, tableRowData: [], tableColumnData: [] }, () => this.getAllCountersData())
    }
  }

  beforeApplyFilters = (formData) => {
    var isErr = false;
    var startDate = moment(formData.startTimestamp);
    var endDate = moment(formData.endTimestamp);
    var interval = formData.interval;
    var intervalInt = {
      Daily: 1,
      Weekly: 7,
      Monthly: 30
      // Quarterly: 90,
      // HalfYearly: 180,
      // Yearly : 365
    }
    if (startDate && endDate) {
      var timeDiff = Math.abs(endDate - startDate);
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      var slots = diffDays / intervalInt[interval];
      if (slots > 12) isErr = true
    }
    return isErr
  }

  handleValidateModalBtnClick = () => this.setState({ isOpenValidateModal: false })

  updateReqParams = (data) => {
    let timeZone = this.state.timeZone;
    let startDateStr = dateConvertion(new Date(data.startTimestamp).getTime(), 'short')
    let startDate = dateConvertion(`${startDateStr} 00:00:00:000`, 'offsetTimestamp', timeZone);
    let endDateStr = dateConvertion(new Date(data.endTimestamp).getTime(), 'short')
    let endDate = dateConvertion(`${endDateStr} 23:59:59:999`, 'offsetTimestamp', timeZone);
    let finalData = { 'STARTDATE': dateConvertion(startDate, 'serviceFormat', timeZone), 'ENDDATE': dateConvertion(endDate, 'serviceFormat', timeZone), 'INTERVAL': data.interval, 'TIMEZONE': getBrowserTimeZone(timeZone) }
    return finalData
  }

  handleExportData = (fileFormat) => {
    let requestParams = this.updateReqParams(this.state.formData);
    const params = { ...requestParams, 'NODEPATH': getNodePath(this.state.treeNode.routeParams, true), 'apiType': 'data' };
    this.setState({ isLoadingData: true }, () => {
      exportData({ ...params, FORMAT: fileFormat }) //appended 'format' property for export api call and its required with params
        .then(data => {
          navigator.onLine ? window.location.href = data : '';
          this.setState({ isLoadingData: false });
        }).catch(err => { this.setState({ isLoadingData: false }) })
    })
  }

  render() {
    return (
      <DisturbanceAnalyticsContext.Provider value={{
        state: this.state,
        handleTabSelect: this.handleTabSelect,
        updateFilterFormData: this.updateFilterFormData,
        handleClickApplyBtn: this.handleClickApplyBtn,
        handleExportData: this.handleExportData,
        handleValidateModalBtnClick: this.handleValidateModalBtnClick
      }}>
        {this.props.children}
      </DisturbanceAnalyticsContext.Provider>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    roleAndPermission: state.loginData.roleAndPermission,
  }
}
export default connect(mapStateToProps, {})(DisturbanceAnalyticsProvider)

export const DisturbanceAnalyticsConsumer = DisturbanceAnalyticsContext.Consumer;