import React, { Component } from 'react';
import { Row,Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import TreeProvider from '../treeview/provider/treeProvider';
import Treeview from '../treeview';
import Routes from '../routes';
import * as headerActions from '../header/actions';
import { updateGlobalTree, updateSearchQuery, notify, openErrorWindow } from "../login/actions/loginActionsBuilder";
import { updateTreeRefArr, updateExpandCollapse } from '../treeview/actions/treeViewActionBuilder';
import { updateTreeDataKeyArr } from '../treeview/actions/treeViewActionBuilder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { displaySubMenu } from '../services/utilityService';
import Header from '../header';
import ErrorHandler from '../hoc/errorHandler';
import { FormattedMessage } from 'react-intl';

class MainContent extends Component {
    updatePathName = (menuLink) => {
        if (this.props.location.pathname.includes(menuLink)) {
            this.props.location.pathname = '/licenseManagement';
            this.props.location.search = "";
            window.history.pushState({}, '', `${window.location.href.split("/amplemanage")[0]}/amplemanage/licenseManagement`);
            this.props.openErrorModalWindow(<FormattedMessage id='login.invalidlicense' />, <FormattedMessage id='common.label.warning' />)
        }
        this.props.onRouteChange(this.props.location.pathname, this.props.disableTree.includes(this.props.location.pathname.slice(1)))
    }

    componentDidUpdate() {
        if (Object.keys(this.props.roleAndPermission).length > 0 && Object.keys(this.props.roleAndPermission.licenseData).length > 0) {
            if ((!this.props.roleAndPermission.licenseData.saidiEnabled && this.props.location.pathname.includes("/events")) || (!this.props.roleAndPermission.licenseData.saifiEnabled && this.props.location.pathname.includes("/disturbances")) || (!this.props.roleAndPermission.licenseData.monitorEnabled && this.props.location.pathname.includes("/monitor")) || (!this.props.roleAndPermission.licenseData.manageEnabled && (this.props.location.pathname.includes("/manage") || this.props.location.pathname.includes("/mapView"))) || (!this.props.roleAndPermission.licenseData.saidiEnabled && !this.props.roleAndPermission.licenseData.monitorEnabled && this.props.location.pathname.includes("/dashboard"))) {
                this.updatePathName(this.props.location.pathname);
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <TreeProvider>
                <Row className="p-0 m-0"><Col sm={12} className="p-0 m-0"><Header /></Col></Row>
                    <Row className="content-view" style={displaySubMenu(this.props.currentRoute, this.props.enableSubMenus) ? { top: '8vh' } : { top: '6vh' }}>
                        {
                            (this.props.openErrorWindow) ? <ErrorHandler /> : null
                        }
                        <div className="col-xs-2 col-2 p-0 m-0 pr-3 left-col" style={(this.props.location.pathname === '/' || this.props.disableTreeView || this.props.hideAndShowTreeView) ? { display: 'none' } : null}>
                             <Treeview />
                        </div>
                        <div className={"right-col p-0 m-0" + ((this.props.location.pathname === '/' || this.props.disableTreeView || this.props.hideAndShowTreeView) ? 'col-xs-12 col-12' : 'col-xs-10 col-10' ) } >
                        {this.props.disableTreeView || !this.props.hideAndShowTreeView ? "" :
                             <span className="onhvr-expnd-clps2" onClick={ ()=> this.props.updateExpandCollapse(false) }> <FontAwesomeIcon icon={faChevronRight} size="lg" /> </span> }
                            {Object.entries(this.props.roleAndPermission).length !== 0 && <Routes />}
                        </div>
                    </Row>  
                </TreeProvider>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        globalDataTree: state.loginData.treeData,
        currentRoute: state.headerData.currentRoute,
        disableTree: state.headerData.disableTree,
        disableTreeView: state.headerData.disableTreeView,
        notifyMe: state.loginData.notify,
        treeRefArrVal: state.treeviewData.treeRefArr,
        TreeDataKeyValuePairArr: state.treeviewData.TreeDataKeyValuePair,
        roleAndPermission: state.loginData.roleAndPermission,
        hideAndShowTreeView: state.treeviewData.hideAndShowTreeView,
        enableSubMenus: state.headerData.enableSubMenus,
        openErrorWindow: state.loginData.showErrorModal
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onRouteChange: (route, isTreeDisable) => dispatch(headerActions.onRouteChange(route, isTreeDisable)),
        updateGlobalTree: (updatedTreeVal) => dispatch(updateGlobalTree(updatedTreeVal)),
        updateSearchQuery: (updatedSearchQuery) => dispatch(updateSearchQuery(updatedSearchQuery)),
        notify: (updateNotify) => dispatch(notify(updateNotify)),
        treeRefArr: (updatetreeRefArr) => dispatch(updateTreeRefArr(updatetreeRefArr)),
        treeDataKeyValArr: (updatetreeDataKeyValArr) => dispatch(updateTreeDataKeyArr(updatetreeDataKeyValArr)),
        updateExpandCollapse: (val) => dispatch(updateExpandCollapse(val)),
        openErrorModalWindow: (message, title) => dispatch(openErrorWindow(message, title))

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContent));