import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Tabs from '../../../../../hoc/tabs';
import HealthReportTab from './tabComponents/healthReportTab';
import DiagnosticsTab from './tabComponents/diagnosticsTab';
import { DeviceContext } from '../../provider/deviceProvider';
import { detailsModalData } from '../../../../../utility/deviceManagementUtility';
import Loader from '../../../../../hoc/loader';
import ModalWindow from '../../../../../hoc/modelWindow';
import Table from '../../../../../hoc/table';
import SubLabel from '../../../../../hoc/subLabel';
import { dateConvertion, fetchBrowserTimeZone, userPreferences } from '../../../../../services/utilityService';
import { formatDateAndTimeWithoutSeconds, formatUptime } from "../../../../../filters/dateHelper";

class DeviceDetailsModal extends Component {
    static contextType = DeviceContext;
    state = {
        currentTab: detailsModalData.currentTab,
        allTabs: detailsModalData.allTabs,
        columnsData: detailsModalData.processesData
    }

    handleTabSelect = crntVal => this.setState({ currentTab: crntVal })

    updateCfciandNoncfciData = (data, from) => {
        return Object.entries(data).map(([properties, value], dkey) =>
            <React.Fragment key={dkey}>
                <Row className={dkey === 0 ? "padding002" : "padding402"}>
                    <Col><b> <FormattedMessage id={`dm.configure.tab.${from}.header.${properties}`} /> </b></Col>
                </Row>
                {value.length !== 0 ?
                    this.displayDataComponent(value.sort((a, b) => a.name.localeCompare(b.name)), from)
                    : ""
                }
            </React.Fragment>
        )
    }

    displayDataComponent = (data, from) => {
        let updateData = data.map((item, key) => {
            return <Row key={key} className="row-padding">
                <Col>
                    {item.status !== 'success' ? '* ' : ""}
                    {<span><FormattedMessage id={`dm.configure.tab.${from}.${item.name}`} /> {this.ampToDisplay(item.name)}</span>}
                </Col>
                <Col>
                    {item.value === null || item.value === "" ? "-" : item.value}
                </Col>
            </Row>
        })
        return updateData
    }

    ampToDisplay = (field) => {
        if(field === "LogIIrmsThreshold1" || field ==="LogIIrmsAlert2" || field === "LogIIrmsAlert1" || field ==="LogIIrmsThreshold2" || field === "CndIrms" || 
            field === "CndIrmsThresh" || field === "YesterdayMaxIrms" || field === "FciDiDtFaultCurrent" || field === "FciDiDtLimit" || field === "FciLossOfSourceFinalLevel" || 
            field === "FciNormalLimit" || field === "FciPercentAutoThreshold" || field === "FciPercentFaultCurrent" || field === "FciPercentMinLimit" || field === "FciThreshLimit" || 
            field === "FciThreshFaultCurrent") {
            return <span>({<SubLabel type="Arms" />})</span>
        }else if(field === "LogITempThreshold1"||field==="LogITempThreshold2"||field==="LogITempAlert1"||field==="LogITempAlert2"||field==="YesterdayMaxTemp"
            ||field==="CndTempThresh"||field==="CndTemp"||field==="CndTempExcept"){
            return <span>({userPreferences.tempUnit})</span>
        }
    }

    render() {
        const { displayTabByRes, deviceDetailsData, rowDetails } = this.context.state;
        const { currentTab } = this.state;
        return (
            <ModalWindow
                show={true}
                size="lg"
                dialogClassName={"detailsModal"}
                title={<span className="popup-title"><FormattedMessage id={"dm.details.modal.title.deviceDetails"} /> : {rowDetails.name}</span>}
                onBtnClickHandler={this.onBtnClickHandlera}
                onHide={this.props.onCloseModal}
                modeldata={{
                    content: <React.Fragment>
                        {this.context.state.isLodingDeviceDetailsData ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                        <Tabs
                            currentTab={currentTab}
                            handleTabSelect={this.handleTabSelect}
                            allTabs={this.state.allTabs.filter(item => displayTabByRes.includes(item.value))}
                            tabBody={
                                <div className="tab-body">
                                    {/*======= for summery Tab ======= */}
                                    {currentTab === 'summary' ?
                                        deviceDetailsData.summary !== undefined && deviceDetailsData.summary.map((item, key) => {
                                            return <div key={key}>
                                                <Row className={key === 0 ? "padding002" : "padding402"}>
                                                    <Col><b>{item.items && Object.keys(item.items).length !== 0 && item.label !== undefined ? item.label : ""}</b></Col>
                                                </Row>

                                                {item.items&&Object.keys(item.items).length !== 0 ?
                                                    Object.entries(item.items).map(([properties, value], subKey) =>
                                                        properties === "Tier" ? null :
                                                        <Row key={subKey} className="row-padding">
                                                            <Col>{(properties === 'Active Uptime' || properties === 'Total Uptime') ? properties + ' (seconds)' :
                                                                properties === 'Timestamp' ? properties + fetchBrowserTimeZone() :
                                                                    properties}</Col>
                                                            <Col>{value ? (properties === "Timestamp" || properties === "Manufacturer Date") ? dateConvertion(value) : (properties === "IP Address" && value === "-1") ? "N/A" : (properties==="Device Type"&&deviceDetailsData.summary[0].items.Tier) ? <>{value} - {deviceDetailsData.summary[0].items.Tier}</> : value : "-"}</Col>
                                                            
                                                        </Row>
                                                    )
                                                    : ""
                                                }
                                                {item.subItems !== null && item.subItems.length !== 0 ?
                                                    item.subItems.map((subitems, subItemskey) => {
                                                        return <div key={subItemskey}>
                                                            <Row className="position-title"><Col> <FormattedMessage id={"dm.details.modal.position"} /> {subItemskey + 1}</Col></Row>
                                                            {Object.entries(subitems).map(([subItemsProperties, subItemsValue], subItemsPkey) =>
                                                                subItemsProperties === 'UM3+ Position' ? ""
                                                                    : <Row key={subItemsPkey}>
                                                                        <Col><span className='position-subitem'>{subItemsProperties}</span></Col>
                                                                        <Col>{subItemsValue === null || subItemsValue === "" ? "-" : subItemsValue}</Col>
                                                                    </Row>
                                                            )}
                                                        </div>
                                                    })
                                                    : ""
                                                }
                                            </div>
                                        })
                                        : ""}

                                    {/*======= for battery Tab =======*/}
                                    {currentTab === 'battery' ?
                                        Object.entries(deviceDetailsData.battery).map(([properties, value], key) =>
                                            <Row key={key} className="row-padding">
                                                <Col>{properties}</Col>
                                                <Col>{value === null || value === "" ? '-' : properties === 'Battery remaining' ? value + ' %' :
                                                    properties === 'Battery manufactured Timestamp' || properties === 'Battery use starting' ? formatDateAndTimeWithoutSeconds(value) :
                                                        properties === 'Device runtime' || properties === 'Device radio runtime' ? formatUptime(value) : value}</Col>
                                            </Row>)
                                        : ""}

                                    {/*======= for healthReport Tab =======*/}
                                    {currentTab === 'healthReport' ? <HealthReportTab deviceDetailsData={deviceDetailsData} /> : ""}

                                    {/* //for logi Tab */}
                                    {currentTab === 'logi' ? this.displayDataComponent(deviceDetailsData.logi, 'logi') : ""}

                                    {/*========for Aed Tab =========*/}
                                    {currentTab === 'anomaly' ? this.displayDataComponent(deviceDetailsData.anomaly, 'anomaly') : ""}

                                    {/*========for SensorSettings Tab======*/}
                                    {currentTab === 'nonCfci' ? this.updateCfciandNoncfciData(deviceDetailsData.nonCfci, 'nonCfci') : ""}

                                    {/*=======for cfci Tab=======*/}
                                    {currentTab === 'cfci' ? this.updateCfciandNoncfciData(deviceDetailsData.cfci, 'cfci') : ""}

                                    {/*======= for processes Tab======= */}
                                    {currentTab === 'processes' ?
                                        <Row>
                                            <Col>
                                                <Table 
                                                    tableColumn={this.state.columnsData}
                                                    tableRowData={deviceDetailsData.processes}
                                                    pageSize={deviceDetailsData.processes.length}
                                                    showPagination={false}
                                                    multiSort={false}
                                                />
                                            </Col>
                                        </Row>
                                        : ""}

                                    {/*======= for device Tab=======*/}
                                    {currentTab === 'device' ? this.updateCfciandNoncfciData(deviceDetailsData.device, 'device') : ""}

                                    {/*=======for security Tab=======*/}
                                    {currentTab === 'security' ?
                                        Object.entries(deviceDetailsData.security).map(([properties, value], key) => {
                                            return <React.Fragment key={key}>
                                                {value === "" || value === " " ? ""
                                                    : <React.Fragment>
                                                        <Row className="security-tab-property" style={{ color: properties === 'message' && value !== "" ? '#a94442' : "" }}>
                                                            <Col>
                                                                <span><b>{<FormattedMessage id={`dm.configure.tab.security.${properties}`} />}</b></span>
                                                            </Col>
                                                        </Row>
                                                        <Row className="security-tab-value" style={{ color: properties === 'message' && value !== "" ? '#a94442' : "" }}>
                                                            <Col>
                                                                {properties === 'certificate' ? <span dangerouslySetInnerHTML={{__html: value }} /> : value}
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        })
                                        : ""}
                                    {/* ======= for systemlogs and loggerFiles Tab =======*/}
                                    {currentTab === 'systemlogs' || currentTab === 'loggerFiles' ? <DiagnosticsTab deviceDetailsData={deviceDetailsData} deviceId={rowDetails.id} /> : ""}
                                </div>
                            }
                        />

                    </React.Fragment>
                }}
            />
            // <Modal centered show={true} onHide={this.props.onCloseModal} size={'lg'} dialogClassName='detailsModal' backdrop="static">
            //     <Modal.Header closeButton>
            //         <Modal.Title className="popup-title"> <FormattedMessage id={"dm.details.modal.title.deviceDetails"} /> {rowDetails.name}</Modal.Title>
            //     </Modal.Header>
            //     <Modal.Body>
            //         {this.context.state.isLodingDeviceDetailsData ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
            //         <Tabs
            //             currentTab={currentTab}
            //             handleTabSelect={this.handleTabSelect}
            //             allTabs={this.state.allTabs.filter(item => displayTabByRes.includes(item.value))}
            //             tabBody={
            //                 <div className="tab-body">
            //                     {/*======= for summery Tab ======= */}
            //                     {currentTab === 'summary' ?
            //                         deviceDetailsData.summary !== undefined && deviceDetailsData.summary.map((item, key) => {
            //                             return <div key={key}>
            //                                 <Row className={key === 0 ? "padding002" : "padding402"}>
            //                                     <Col><b>{item.label !== undefined ? item.label : ""}</b></Col>
            //                                 </Row>

            //                                 {Object.keys(item.items).length !== 0 ?
            //                                     Object.entries(item.items).map(([properties, value], subKey) =>
            //                                         <Row key={subKey} className="row-padding">
            //                                             <Col>{properties}</Col>
            //                                             <Col>{value === null || value === "" ? "-" : value}</Col>
            //                                         </Row>
            //                                     )
            //                                     : ""
            //                                 }
            //                                 {item.subItems !== null && item.subItems.length !== 0 ?
            //                                     item.subItems.map((subitems, subItemskey) => {
            //                                         return <div key={subItemskey}>
            //                                             <Row className="position-title"><Col> <FormattedMessage id={"dm.details.modal.position"} /> {subItemskey + 1}</Col></Row>
            //                                             {Object.entries(subitems).map(([subItemsProperties, subItemsValue], subItemsPkey) =>
            //                                                 subItemsProperties === 'UM3+ Position' ? ""
            //                                                     : <Row key={subItemsPkey}>
            //                                                         <Col><span className='position-subitem'>{subItemsProperties}</span></Col>
            //                                                         <Col>{subItemsValue === null || subItemsValue === "" ? "-" : subItemsValue}</Col>
            //                                                     </Row>
            //                                             )}
            //                                         </div>
            //                                     })
            //                                     : ""
            //                                 }
            //                             </div>
            //                         })
            //                         : ""}

            //                     {/*======= for battery Tab =======*/}
            //                     {currentTab === 'battery' ?
            //                         Object.entries(deviceDetailsData.battery).map(([properties, value], key) =>
            //                             <Row key={key} className="row-padding">
            //                                 <Col>{properties}</Col>
            //                                 <Col>{value === null || value === "" ? "-" : value}</Col>
            //                             </Row>)
            //                         : ""}

            //                     {/*======= for healthReport Tab =======*/}
            //                     {currentTab === 'healthReport' ? <HealthReportTab deviceDetailsData={deviceDetailsData} /> : ""}

            //                     {/* //for logi Tab */}
            //                     {currentTab === 'logi' ? this.displayDataComponent(deviceDetailsData.logi, 'logi') : ""}

            //                     {/*========for Aed Tab =========*/}
            //                     {currentTab === 'anomaly' ? this.displayDataComponent(deviceDetailsData.anomaly, 'anomaly') : ""}

            //                     {/*========for SensorSettings Tab======*/}
            //                     {currentTab === 'nonCfci' ? this.updateCfciandNoncfciData(deviceDetailsData.nonCfci, 'nonCfci') : ""}

            //                     {/*=======for cfci Tab=======*/}
            //                     {currentTab === 'cfci' ? this.updateCfciandNoncfciData(deviceDetailsData.cfci, 'cfci') : ""}

            //                     {/*======= for processes Tab======= */}
            //                     {currentTab === 'processes' ?
            //                         <Row>
            //                             <Col>
            //                                 <ReactTable
            //                                     columns={this.state.columnsData}
            //                                     data={deviceDetailsData.processes}
            //                                     showPagination={false}
            //                                     isDisplayPageRows={false}
            //                                     minRows={0}
            //                                     className="-striped -highlight"
            //                                     totalSize={deviceDetailsData.processes.length}
            //                                 />
            //                             </Col>
            //                         </Row>
            //                         : ""}

            //                     {/*======= for device Tab=======*/}
            //                     {currentTab === 'device' ? this.updateCfciandNoncfciData(deviceDetailsData.device, 'device') : ""}

            //                     {/*=======for security Tab=======*/}
            //                     {currentTab === 'security' ?
            //                         Object.entries(deviceDetailsData.security).map(([properties, value], key) => {
            //                             return <React.Fragment key={key}>
            //                                 {value === "" || value === " " ? ""
            //                                     : <React.Fragment>
            //                                         <Row className="security-tab-property" style={{ color: properties === 'message' && value !== "" ? '#a94442' : "" }}>
            //                                             <Col>
            //                                                 <span><b>{<FormattedMessage id={`dm.configure.tab.security.${properties}`} />}</b></span>
            //                                             </Col>
            //                                         </Row>
            //                                         <Row className="security-tab-value" style={{ color: properties === 'message' && value !== "" ? '#a94442' : "" }}>
            //                                             <Col>
            //                                                 {properties === 'certificate' ? ReactHtmlParser(value) : value}
            //                                             </Col>
            //                                         </Row>
            //                                     </React.Fragment>
            //                                 }
            //                             </React.Fragment>
            //                         })
            //                         : ""}
            //                     {/* ======= for systemlogs and loggerFiles Tab =======*/}
            //                     {currentTab === 'systemlogs' || currentTab === 'loggerFiles' ? <DiagnosticsTab deviceDetailsData={deviceDetailsData} deviceId={rowDetails.id} /> : ""}
            //                 </div>
            //             }
            //         />
            //     </Modal.Body>
            // </Modal>
        )
    }
}

export default DeviceDetailsModal;