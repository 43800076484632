import React from 'react';
import { FormattedMessage } from 'react-intl';
import TableMultiSortNumber from '../hoc/tableMultiSortNumber';
import { formatDateAndTime } from '../filters/dateHelper';
import { fetchBrowserTimeZone } from '../services/utilityService';

function getHeaderName(original) {
    let timeZone = (original.column.id === 'startDate' || original.column.id === 'endDate') ? fetchBrowserTimeZone() : '';
    return <TableMultiSortNumber column={original} label={<><FormattedMessage id={`customReports.manageSchedule.column.header.${original.column.id}`} />{timeZone}</>} />
}

const accessorValues = ['name', 'reportType', 'frequency', 'startDate', 'endDate', 'commaSeparatedEmails', 'status', 'format']

const tableColumnsArr = accessorValues.map(e => {
    if (e === 'frequency' || e === "startDate" || e === "endDate") {
        return ({
            Header: getHeaderName,
            accessor: e,
            sortedOrder: 0, //For displaying Sorted Order
            Cell: ({ row }) => {
                if (e === "frequency") {
                    return reportFrequency[row.frequency]
                } else {
                    return formatDateAndTime(row[e])
                }
            }
        })
    } else {
        return ({
            Header: getHeaderName,
            accessor: e,
            sortedOrder: 0, //For displaying Sorted Order
        })
    }
});

export const tableOptions = {
    tableColumns: tableColumnsArr
}

export const mangeScheduleColumFilterData = {
    columFilter: tableOptions.tableColumns.map(column => {
        return { label: <FormattedMessage id={`customReports.manageSchedule.column.header.${column.accessor}`} />, value: column.accessor }
    })
}

export const displayColumns = {
    data: ['name', 'reportType', 'frequency', 'startDate', 'endDate', 'commaSeparatedEmails', 'action']
}

export const reportFrequency = {
    QUARTER_HOUR: "15 Minutes",
    HALF_HOUR: "30 Minutes",
    THREE_QUARTER_HOUR: "45 Minutes",
    HOUR: "60 Minutes",
    DAILY: "Daily",
    WEEKLY: "Weekly",
    MONTHLY: "Monthly",
    YEARLY: "Yearly"
};