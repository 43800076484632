import React, { createContext } from 'react';
import { connect } from 'react-redux';
import SortNumber from '../../../mainContent/userManagement/components/sortNumber';
import { getMasterFileHistoryURL, getCertificateFileHistoryURL, getMenufacturingFile, getFirmwareFile, getGridMapingFile, exportMenufacturingFile, downloadSampleFile, getGridUnmappedCount, uploadManufacturingAPIRequest, uploadFirmwareAPIRequest, getImportGridMappingDataURL, getImportCertificateDataURL, getImportMasterDataURL } from '../services/systemAdminService';
import { FormattedMessage } from 'react-intl';
import { getCommServerDetails } from '../../manage/details/services/deviceService';
import * as configurePropertiesBuilderAction from '../../configureProperties/actions/index';
import { saveAs } from 'file-saver';
import { dateConvertion } from '../../../services/utilityService';
import { tableOptions } from '../../../utility/deviceManagementUtility'

export const SystemAdminContext = createContext();

class SystemAdminProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menufacturingData: [],
            firmwareFile: [],
            gridMapingFile: [],
            inventoryMasterFile:[],
            certificateFile:[],
            gridUnmappedCount: 0,
            requestParams: { 'ORGID': this.props.tree[0].id, 'apiType': 'data' },
            lodingMenufacturingData: false,
            lodingFirmwareData: false,
            loadingGridMappingData: false,
            loadingInventoryData: false,
            loadingCertificateData: false,
            file: null,
            manufactureFileRes: { type: "", msg: "" },
            firmwareFileRes: { type: "", msg: "" },
            certificateFileRes: { type: "", msg: "" },
            inventoryFileRes: { type: "", msg: "" },
            gridMappingFileRes: { type: "", msg: "" },
            OSName : 'other'
        }
    }

    componentDidMount() {
        this.getMasterFileHistoryURLData();
        this.getCertificateFileHistoryURLData();
        this.getConfigData();
        this.getMenufacturingData();
        this.getFirmwareData();
        this.getGridMapingData();
        this.getGridUnmappedCountData();
        this.checkOperatingSystem()
        tableOptions.pageComingFrom = "systemAdminFileUpload";
    }

    checkOperatingSystem=()=>{
        if (navigator.appVersion.indexOf("Mac")!==-1) this.setState({ OSName : 'MacOS' })
    }

    getMenufacturingData = () => {
        this.setState({ lodingMenufacturingData: true }, () => {
            getMenufacturingFile(this.state.requestParams)
                .then((data) => {
                    let newData = data.length !== 0 ? data : [];
                    this.setState({ menufacturingData: newData, lodingMenufacturingData: false })
                }).catch((err) => this.setState({ lodingMenufacturingData: false }))
        })
    }

    getFirmwareData = () => {
        this.setState({ lodingFirmwareData: true }, () => {
            getFirmwareFile(this.state.requestParams)
                .then((data) => {
                    let newData = data.length !== 0 ? data : [];
                    this.setState({ firmwareFile: newData, lodingFirmwareData: false })
                }).catch((err) => this.setState({ lodingFirmwareData: false }))
        })

    }

    getGridMapingData = () => {
        this.setState({ loadingGridMappingData: true })
        getGridMapingFile(this.state.requestParams)
            .then((data) => {
                let newData = data.length !== 0 ? data : [];
                this.setState({ gridMapingFile: newData, loadingGridMappingData: false })
            }).catch((err) => this.setState({ loadingGridMappingData: false }))
    }

    getMasterFileHistoryURLData=()=>{
        this.setState({ loadingInventoryData: true })
        getMasterFileHistoryURL(this.state.requestParams)
            .then((data) => {
                let newData = data.length !== 0 ? data : [];
                this.setState({ inventoryMasterFile: newData, loadingInventoryData: false })
            }).catch((err) => this.setState({ loadingInventoryData: false }))
    }

    getCertificateFileHistoryURLData=()=>{
        this.setState({ loadingCertificateData: true })
        getCertificateFileHistoryURL(this.state.requestParams)
            .then((data) => {
                let newData = data.length !== 0 ? data : [];
                this.setState({ certificateFile: newData, loadingCertificateData : false })
            }).catch((err) => this.setState({ loadingCertificateData : false })
            )
    }

    getGridUnmappedCountData = () => {
        getGridUnmappedCount(this.state.requestParams)
            .then((data) => {
                var newData = this.state.gridUnmappedCount;
                if( typeof data === 'object' ) newData = data.data
                else newData = data
                this.setState({ gridUnmappedCount: newData })
            }).catch((err) => { })
    }

    getConfigData = () => {
        let  defaultMtfUpload = this.props.oldMtfUploadViewData===''? this.props.roleAndPermission.defaultMtfUpload.toString(): this.props.oldMtfUploadViewData;
        this.props.updateOldMtfUploadViewData(defaultMtfUpload);
    }

    handleDownloadFile = (original, from) => {
        this.setState({ 
            lodingMenufacturingData: from === "menuFacFilestatus" || from === "jwtFile" ? true : false, 
            loadingGridMappingData: from === 'gridmapStatus' ? true : false,
            loadingInventoryUploadDownload: from === 'inventoryResult' ? true: false
         }, () => {
            exportMenufacturingFile(original.id, from)
                .then((data) => {
                    this.setState({ lodingMenufacturingData: false, loadingGridMappingData: false, loadingInventoryUploadDownload: false })
                    window.location.href = data;
                }).catch((err) => this.setState({ lodingMenufacturingData: false, loadingGridMappingData: false, loadingInventoryUploadDownload: false }))
        })
    }

    handleDownloadSampleFile = (from) => {
        this.setState({ loadingGridMappingData: from === 'gridMapingSampleFile' ? true : false, loadingInventoryUploadDownload: from === 'inventorySampleFile' ? true: false }, () => {
            downloadSampleFile(this.state.requestParams, from)
                .then((data) => {
                    navigator.onLine ? window.location.href = data : '';
                    this.setState({ loadingGridMappingData: false, loadingInventoryUploadDownload: false })
                }).catch((err) => this.setState({ loadingGridMappingData: false, loadingInventoryUploadDownload: false }))
        })
    }

    handleFileUpload = (from, e) => {
        if (e.target.files.length !== 0) {
            this.setState({ file: e.target.files[0], fileName: e.target.files[0].name, loadingInventoryUploadDownload: from === 'inventoryFile' ? true : false, loadingCertificateUpload: from === 'certificateFile' ? true : false,  lodingMenufacturingData: from === 'manufacturingFile' ? true : false, lodingFirmwareData : from === 'firmwareFile' ? true : false, loadingGridMappingData : from === 'gridMappingFile' ? true : false }, () => {
                let formData = new FormData();
                formData.append('fileData', this.state.file);
                if (from === 'manufacturingFile') this.uploadMenufacturingFile(formData)
                else if (from === 'firmwareFile') this.uploadFirmwareFile(formData)
                else if (from === 'certificateFile') this.uploadCertificateFile(formData)
                else if (from === 'inventoryFile') this.uploadInventoryFile(formData)
                else this.uploadGridMappingFile(formData)
            })
        }
    }

    uploadMenufacturingFile = (formData) => {
        if (this.state.file.size <= 10485760) {
            uploadManufacturingAPIRequest(this.state.requestParams, formData)
                .then(res => {
                    // var message = res.message ? res.message : null;
                    // let manufacturingFileUploadResMsg = (res.status === "SUCCESS") ? { type: 'success', msg: "The file has been uploaded successfully." } : { type: 'danger', msg: res.data };
                    if(res){
                    let manufacturingFileUploadResMsg = (res.status === "SUCCESS") ? { type: 'success', msg: <FormattedMessage id='db.sysAdmin.fileUploadedSuccessfully'/> } : { type: 'danger', msg: res.message ? res.message : res };
                    this.setState({ manufactureFileRes: manufacturingFileUploadResMsg, file: null }, () => {
                        if (res.status === "SUCCESS") {
                            this.getMenufacturingData();
                            this.getGridUnmappedCountData();
                        } else this.setState({ lodingMenufacturingData: false, file: null, })
                    })
                }else{
                    this.setState({ lodingMenufacturingData: false, file: null, })
                }
                }).catch(err => {
                    this.setState({ manufactureFileRes: { type: 'danger', msg: err.data },lodingMenufacturingData: false, file: null })
                })
        } else {
            this.setState({ manufactureFileRes: { type: 'danger', msg: <FormattedMessage id='db.sysAdmin.invalidSize'/> },lodingMenufacturingData: false, file: null })
        }
    }

    uploadFirmwareFile = (formData) => {
        if (this.state.file.size <= 10485760) {
            let params = { 'apiType': 'data' };
            uploadFirmwareAPIRequest(params, formData)
                .then(res => {
                    if(res){
                    let firmwareUploadResMsg = (res.indexOf('uploaded successfully') !== -1) ? { type: 'success', msg: res } : { type: 'danger', msg: res };
                    this.setState({ firmwareFileRes: firmwareUploadResMsg, file: null }, () => {
                        if (res.indexOf('uploaded successfully') !== -1) {
                            this.getFirmwareData();
                        } else this.setState({ lodingFirmwareData: false, file: null, })
                    })
                }else{
                    this.setState({ lodingFirmwareData: false, file: null, });
                }
                }).catch(err => {
                    this.setState({ firmwareFileRes: { type: 'danger', msg: err.data },lodingFirmwareData: false, file: null })
                })
        } else {
            this.setState({ firmwareFileRes: { type: 'danger', msg: <FormattedMessage id='db.sysAdmin.invalidSize'/> }, lodingFirmwareData: false, file: null })
        }
    }

    uploadCertificateFile =(formData) =>{
        if (this.state.file.size <= 10485760) {
            let params = { 'apiType': 'data' };
            getImportCertificateDataURL(params, formData)
                .then(res => {
                    if(res){
                    let certificateUploadResMsg = (res.indexOf('uploaded successfully') !== -1) ? { type: 'success', msg: res } : { type: 'danger', msg: res };
                    this.setState({ certificateFileRes: certificateUploadResMsg, loadingCertificateUpload: false, file: null }, () => {
                        if (res.indexOf('uploaded successfully') !== -1) {
                            this.getCertificateFileHistoryURLData();
                        } else this.setState({ file: null, })
                    })
                }else{
                    this.setState({loadingCertificateUpload: false, file: null });
                }
                }).catch(err => {
                    this.setState({ certificateFileRes: { type: 'danger', msg: err.data },loadingCertificateUpload: false, file: null })
                })
        } else {
            this.setState({ certificateFileRes: { type: 'danger', msg: <FormattedMessage id='db.sysAdmin.invalidSize'/>},loadingCertificateUpload:false, file: null })
        }
    }

    uploadInventoryFile =(formData) =>{
        if (this.state.file.size <= 10485760) {
            let params = { 'apiType': 'data' };
            getImportMasterDataURL(params, formData)
                .then(res => {
                    if(res){
                    let inventoryFileUploadResMsg = (res.indexOf('uploaded successfully') !== -1) ? { type: 'success', msg: <FormattedMessage id='db.sysAdmin.fileUploadedSuccessfully'/> } : { type: 'danger', msg: 'Failed to upload' };
                    this.setState({ inventoryFileRes: inventoryFileUploadResMsg, file: null, loadingInventoryUploadDownload: false }, () => {
                        
                        this.getMasterFileHistoryURLData();
                        if (res.indexOf('uploaded successfully') === -1) {
                            this.setState({
                                errorModalData: {
                                    title: <span><FormattedMessage id="db.sysAdmin.errorDetailsForMTFFile" />&nbsp;<b>{this.state.fileName}</b>&nbsp;<FormattedMessage id="db.sysAdmin.UploadedOn" />&nbsp;<b>{dateConvertion((new Date).getTime())}</b></span>,
                                    dateTimeErrModal: dateConvertion((new Date).getTime()),
                                    body: res
                                }
                            })
                        }
                    })
                }else{
                    this.setState({file: null,loadingInventoryUploadDownload: false });
                }
                }).catch(err => {
                    this.setState({ inventoryFileRes: { type: 'danger', msg: err.data },loadingInventoryUploadDownload: false, file: null })
                })
        } else {
            this.setState({ inventoryFileRes: { type: 'danger', msg: <FormattedMessage id='db.sysAdmin.invalidSize'/> },loadingInventoryUploadDownload: false, file: null })
        }
    }

    handleUploadErrorDetailsClick=(trueOrFalse)=>{
        this.setState({
            openUploadErrDetailsModal : trueOrFalse
        })
    }

    uploadGridMappingFile = (formData) => {
        if (this.state.file.size <= 10485760) {
            getImportGridMappingDataURL(this.state.requestParams, formData)
                .then(res => {
                    // var message = res.message ? res.message : null;
                    if(res){
                    let gridMappingUploadResMsg = (res.status === "SUCCESS") ? { type: 'success', msg: <FormattedMessage id='db.sysAdmin.fileUploadedSuccessfully'/> } : { type: 'danger', msg: res.message ? res.message : res };
                    this.setState({ gridMappingFileRes: gridMappingUploadResMsg, file: null }, () => {
                        if (res.status === "SUCCESS") {
                            this.getGridMapingData();
                            this.getGridUnmappedCountData();
                        } else this.setState({ loadingGridMappingData: false, file: null, errorModalResMsg: res })
                    })
                }else{
                    this.setState({file: null, loadingGridMappingData: false});
                }
                }).catch(err => {
                    this.setState({ gridMappingFileRes: { type: 'danger', msg: err.data },loadingGridMappingData: false, file: null })
                })
        } else {
            this.setState({ gridMappingFileRes: { type: 'danger', msg: <FormattedMessage id='db.sysAdmin.invalidSize'/> },loadingGridMappingData: false, file: null })
        }
    }

    handleRemoveResponseAlert = (from) => {
        var removeResponseFrom = "";
        if (from === 'menufactringFile') { removeResponseFrom = 'manufactureFileRes' }
        else if (from === 'firmwareFile') removeResponseFrom = 'firmwareFileRes'
        else if (from === 'certificateFile') removeResponseFrom = 'certificateFileRes'
        else if (from === 'inventoryFile') removeResponseFrom = 'inventoryFileRes'
        else removeResponseFrom = 'gridMappingFileRes'
        this.setState({ [removeResponseFrom]: { type: "", msg: "" } })
    }

    updateHeaderdata=(column, data) => <SortNumber data={data} columnData={column} />

    updateSortNumber=(rowData, columnsData)=>{
        rowData.forEach((item, key, itemArr) => {
            columnsData.forEach(items => {
                if (item.id === items.accessor) {
                    items.sortNumber = itemArr.length > 1 ? key + 1 : "";
                } else {
                    let arr = itemArr.map(itemId => itemId.id);
                    if (!arr.includes(items.accessor) && items.hasOwnProperty('sortNumber')) delete items.sortNumber
                }
            })
        })
        return columnsData
    }

    errorModalActionButton =()=>{
        const fileName = 'error_messages.txt';
        const fileData = new Blob([`Error details for MTF File ${this.state.fileName} uploaded on ${this.state.errorModalData.dateTimeErrModal} \r\n${this.state.errorModalData.body}`], { type: "application/octet-stream" });
        saveAs(fileData, fileName)
    }

    render() {
        return (
            <SystemAdminContext.Provider value={{
                state: this.state,
                handleDownloadFile: this.handleDownloadFile,
                downloadSampleFile: this.handleDownloadSampleFile,
                handleFileUpload: this.handleFileUpload,
                handleRemoveAlert: this.handleRemoveResponseAlert,
                updateHeaderdata: this.updateHeaderdata,
                updateSortNumber: this.updateSortNumber,
                handleUploadErrorDetailsClick: this.handleUploadErrorDetailsClick,
                onBtnClickHandleBtn: this.errorModalActionButton
            }}>
                {this.props.children}
            </SystemAdminContext.Provider>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateOldMtfUploadViewData: (data) => dispatch(configurePropertiesBuilderAction.updateOldMtfUploadViewData(data))
    }
}

const mapStateToProps = state => {
    return {
        tree: state.treeviewData.tree,
        oldMtfUploadViewData: state.configurePropertiesData.oldMtfUploadView,
        roleAndPermission: state.loginData.roleAndPermission
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemAdminProvider)