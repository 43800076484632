import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { BrowserRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../header';
import Footer from '../footer';
import MainContent from '../mainContent';
import Login from '../login';
import * as actionBuilder from "./actions";
import * as treeBuilderActions from '../treeview/actions';
import Loader from '../hoc/loader';
import ForgotPassword from '../login/containers/forgotPasswordContainer';
import ResetPassword from '../login/containers/resetPasswordContainer';
import SelectOrganization from '../login/containers/selectOrganization';

class HomeComponent extends Component {
   
    componentDidMount() {
        this.props.onCheckSession({ samlLogout : this.props.samlLogout, samlFailed: this.props.samlFailed });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.loggedIn) {
            if ((newProps.sessionData === 'undefined') || (!newProps.sessionData)) {
                this.props.onCheckSession()
            } else {
                this.props.getAllRoleAndUserPermission();
                this.props.onInitTree(newProps.sessionData.data);
            }
        }
    }
    render() {
        let content = null;
        if (this.props.loading) {
            content = <Loader elementClass="tree-ajax-loader center-element" />
        } else if (this.props.loggedIn) {
            content =
            <React.Fragment>
                <Container fluid className="p-0">
                    <div className="main">
                        {/* <Row className="p-0 m-0"><Col sm={12} className="p-0 m-0"><Header /></Col></Row> */}
                        <MainContent />
                    </div>
                </Container>
                <div className="position-relative footer clearfix mb-0 p-0">
                    <Footer />
                </div>
            </React.Fragment>          
        } else {
            content =
                <Switch>
                    <Route exact path='/' render={() => (<Redirect to='/login' />)} />
                    <Route path="/forgotPassword"><ForgotPassword /></Route>
                    <Route path="/selectOrganization"><SelectOrganization /></Route>
                    <Route path="/reset-password"><ResetPassword /></Route>
                    {['/login', '/login/disabled', '/login/failed', '/login/invalidlicense', '/samlLogout', '/login/samlFailed'].map(path => <Route exact strict path={path} key={path}><Login /></Route>)}
                    <Redirect from="*" to="/login" />
                </Switch>
        }

        return (
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                {content}
            </BrowserRouter>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        loggedIn: state.loginData.loggedIn,
        sessionData: state.loginData.sessionData,
        loading: state.loginData.loading,
        samlLogout: state.headerData.samlLogout,
        samlFailed: state.headerData.samlFailed
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // onCheckSession: (formRef,inputRef) => dispatch(actionBuilder.setSession(this.state.formRef,this.state.inputRef)),
        onCheckSession: (samlInfo) => dispatch(actionBuilder.setSession(samlInfo)),
        onInitTree: (data) => dispatch(treeBuilderActions.initTree(data)),
        getAllRoleAndUserPermission: () => dispatch(actionBuilder.getAllRoleAndUserPermission()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeComponent));