import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getNodeRouteParams } from '../containers/treeMethods';
import { nodeOperations } from '../services/treeService';
import { TreeContext } from '../provider/treeProvider';
import ModalWindow from '../../hoc/modelWindow';
import { reloadTreeview } from '../../utility/treeViewUtility';

class DeleteNodeComponent extends Component {
    static contextType = TreeContext;

    state = {
        errorMessage: ""
    }

    reloadtree = () => {
        let obj = reloadTreeview(this.context.state);
        this.context.autoSelectFromDashboard(obj, true);
    }

    onDeleteClickHandler = async () => {
        let nodeDetails = {};
        nodeDetails.name = this.props.clickedNode.node.name;
        nodeDetails.type = this.props.clickedNode.node.type;
        const routeParams = getNodeRouteParams(this.props.clickedNode);
        const res = await nodeOperations(routeParams, 'deleteNode', nodeDetails);
        if (res) {
            if (res.status !== "FAIL") {
                this.props.closeModal();
                //const reDirect = this.context.state.highlightedNode && this.context.state.highlightedNode.node && (this.props.clickedNode.node.type === this.context.state.highlightedNode.node.type) && (this.props.clickedNode.node.name === this.context.state.highlightedNode.node.name) ? "deleteNode" : "";
                //this.context.loadNextLevel({ "node": this.props.clickedNode.parentNode }, this.props.clickedNode.parentNode.routeParams, null, reDirect);
                
                this.findLastSelectionNode(this.props.clickedNode, this.context.state.highlightedNode)       
            }
            else {
                this.setState({ errorMessage: res.message })
            }
        }
    }

    findLastSelectionNode=(clickedNode, highlightedNode)=>{
        //clickedNode is : which is user want to delete.
        //highlightedNode is: which is already highlighted in ui.

        let selectedNode = '';
        highlightedNode.node.routeParams.length && highlightedNode.node.routeParams.map((item)=>{
            // 1st condition : if user want to delete the Region node then checking condition with deleted region name with highlighted route param Region name. //dont hv id for region and same region name cant be duplicate so checking with reagion name.
            // 2nd condition : if user want to delete sub, feed, lat, lat-site then checking condition with deleted node id and highlighted route params id.

            //if condition match inside the highlighted group tree route param then selected node should be the parent node name.
            if ((clickedNode.node.type === 'REGION' && item.type === 'REGION' && clickedNode.node.name === item.name) || (item.id !== null && item.id === clickedNode.node.id) ){ 
                    selectedNode = clickedNode.parentNode.name
            }
        })

         //if user trying to delete a different region and highlighted reason group tree node is different then selected node should be the same highlighted node.
        if(selectedNode === '') {
            selectedNode = highlightedNode.node.name
        }

        //updating the dashboard flag here, and this flag will remove the loader.
        this.context.updateDashboardFlagAndHighlNode(selectedNode)
        this.reloadtree();  //reloading the tree
        this.context.setLoadDevicesList(true);
    }

    // On Button click we will recieve the button name from the Modal Window hoc based on that will call the corresponding function
    onClickHandler = (btnName) => {
        return btnName === "delete" ? this.onDeleteClickHandler() :
            btnName === "ok" ? this.props.closeModal() : null;
    }

    render() {
        const modalTitle = <div className="popup-title"><FontAwesomeIcon icon={faExclamationTriangle} className="danger-color" />&nbsp;&nbsp;<FormattedMessage id={`groupmanagement.context.delete.title`} /></div>;
        const modalBody = <div className="min-vh-25">
            {!this.state.errorMessage ?
                <span>All child nodes of the <strong>'{this.props.clickedNode.node.name}'</strong> will be deleted</span> :
                <div className={this.state.errorMessage ? "alert alert-danger" : "alert alert-danger dspNone"} role="alert"> {this.state.errorMessage}</div>}
        </div>
        const modalFooter = !this.state.errorMessage ? [{ className: "modalSaveBtn", name: <FormattedMessage id={`groupmanagement.context.delete.button`} />, value: "delete" }] : [{ className: "modalSaveBtn", name: <FormattedMessage id={`groupmanagement.context.ok.button`} />, value: "ok" }];
        return (
            <Fragment>
                {this.props.isModalOpen ?
                    <ModalWindow
                        show={this.props.isModalOpen}
                        onHide={() => this.props.closeModal()}
                        size={"md"}
                        title={modalTitle}
                        modeldata={{ content: modalBody }}
                        footer={modalFooter}
                        onBtnClickHandler={this.onClickHandler} /> : ""}
            </Fragment>
        )
    }
}

export default DeleteNodeComponent;