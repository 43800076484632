import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import TextField from '../../../hoc/form/textField';
import ToggleSwitch from '../../../hoc/toggleSwitch';
import { configPropertiesData } from '../../../utility/configurePropertiesUtility';
import { ConfigurePropertiesContext } from '../provider/configurePropertiesProvider';
import { FormattedMessage } from 'react-intl';
import SubLabel from '../../../hoc/subLabel';
import Table from '../../../hoc/table';
import BlankPage from '../../../hoc/blankPage';
import { connect } from 'react-redux';

class ConfigForm extends Component {
    static contextType = ConfigurePropertiesContext;
    constructor(props) {
        super(props);
        this.state = {
            formFields: configPropertiesData.formFields,
            profileTemplate: configPropertiesData.profileTemplateTable
        }
    }
    handleShowHide = (item) => {
        let prevData = this.state.formFields;
        prevData.forEach((items) => {
            if (items.category === item.category) items.isDisplay = !item.isDisplay;
            if(items.isSubLabel){
                items.data.forEach((subItem)=>{
                    if (subItem.category === item.category) subItem.isDisplay = !item.isDisplay;
                })
            }
        })
        this.setState({ formFields: prevData })
    }

    componentWillUnmount(){
        let prevData = this.state.formFields;   //while closed the component closing all the subcategory
        prevData.forEach((items) => {
            if(items.isSubLabel){
                items.data.forEach((subItem)=>{
                    subItem.isDisplay = false
                })
            }
        })
    }

    updateFormattedMessage = (label, rulesData) => {
        let updateLabel = "";
        if (label !== "") {
            let minMax = "";
            if (rulesData !== null && rulesData[label].min !== null && rulesData[label].max !== null) {   //getting all the min and max if rulesData is available
                minMax = (`${rulesData[label].min} ${' - '} ${rulesData[label].max}`);
            }
            let addSecOrByte = "";
            if (label.indexOf('.') !== -1) {
                // let findData = ["seconds", 'bytes', 'minutes', 'meters', 'mins']
                // let strToArr = label.split(".");
                // addSecOrByte = findData.includes(strToArr[strToArr.length - 1]) ? strToArr[strToArr.length - 1] : ""
                if (label.indexOf('seconds') !== -1 || label.indexOf('timeinseconds') !== -1) addSecOrByte = <FormattedMessage id={"db.sg.nwg.seconds"} />
                else if (label.indexOf('bytes') !== -1) addSecOrByte = <FormattedMessage id={"db.sg.nwg.bytes"} />
                else if (label.indexOf('minutes') !== -1 || label.indexOf('mins') !== -1 || label.indexOf('duration') !== -1 || label.indexOf("poll") !== -1) addSecOrByte = <FormattedMessage id={"db.sg.nwg.minutes"} />
                else if (label.indexOf('meters') !== -1) addSecOrByte = <FormattedMessage id={"db.sg.nwg.meters"} />
                else if (label === 'default.lpf.fault.threshold') addSecOrByte = <SubLabel type="Arms" />
            }
            if (minMax !== "" || addSecOrByte !== "") { // updating the message lable
                updateLabel = <span>({minMax}{(minMax && addSecOrByte) && <span>&nbsp;</span>}{addSecOrByte})</span>
            }
        }
        return <span><FormattedMessage id={`configure.${label}`} /> {updateLabel} </span>
    }

    updateFormCategoryTitle = (item) => {
        let isAvailLable = this.findIslabelAvailable(item); //checking, is response data's property available in the formFields category
        //if isAvailLable is empty or blank array dont display that category
        return (item.type === "title" && isAvailLable.length !== 0) || item.label === 'Virtual Devices' || item.label === 'Global Setting' || item.label === 'Default Setting' || item.label === 'Profile Template' ? <div className="form-category-section">
            <span onClick={() => this.handleShowHide(item)} className="icon-and-label">
                <span className="icon"><FontAwesomeIcon icon={item.isDisplay ? faCaretUp : faCaretDown} size="sm" /></span>
                {item.label}
            </span>
        </div> : ""
    }

    findIslabelAvailable=(item)=>{
        let resData = this.context.state.configData.comPropertyGeneralData !== undefined ? this.context.state.configData.comPropertyGeneralData.comPropertyDatas : ""
        let allResDataProperties = []    //storing all the object properties from responce Data
        Object.entries(resData).forEach(([key]) => allResDataProperties.push(key))
        //checking if configPropertiesData.formFields.data (item.data) available in allResDataProperties.
        let labelData = item.data.filter(datas => allResDataProperties.includes(datas.label))
        return labelData
    }

    render() {
        const { formFields } = this.state;
        const { configData, formError } = this.context.state;
        return (
            <Row>
                <Col>
                    <Row>
                        {configData.comPropertyGeneralData !== undefined ? formFields.map((item, key) => {
                            return <div key={key} className='container-fluid form-section'>
                                {/* display all the category title */}
                                {this.updateFormCategoryTitle(item)}

                                {/* display all the form */}
                                {item.data.length !== 0 && item.isDisplay && item.label !== 'Virtual Devices' && 
                                    item.data.map((items, keys) => {
                                        return <Row key={keys} className="label-left">
                                            {configData.comPropertyGeneralData.comPropertyDatas[items.label] !== undefined ?
                                                <React.Fragment>
                                                    <Col xs={8}>
                                                        <span className="customTooltip">{this.updateFormattedMessage(items.label, configData.comPropertyGeneralData.comPropertyRules)}
                                                            <span className="tooltiptext">{<FormattedMessage id={`configure.tooltip.${items.label}`} />}</span>
                                                        </span>
                                                    </Col>
                                                    <Col xs={4}>
                                                        {items.type === 'text' ?
                                                            <TextField
                                                                type="text"
                                                                isDisabled={((items.label === "wait.timeinminutes.before.sending.event.email" && this.props.roleAndPermission.roleId === 2) || configData.comPropertyGeneralData.comPropertyRules[items.label] === true) ? true : false}
                                                                value={configData.comPropertyGeneralData.comPropertyDatas[items.label].propertyValue}
                                                                field={items.label}
                                                                handleOnchange={(e) => this.context.updateFormData(items.label, e)}
                                                                className={formError[items.label] !== undefined && formError[items.label] === true ? "form-err-border-red" : ""}
                                                            />
                                                            :
                                                            <ToggleSwitch
                                                                value={configData.comPropertyGeneralData.comPropertyDatas[items.label].propertyValue === "true" ? true : false}
                                                                toggleSwitchValue={(e) => this.context.updateFormData(items.label, configData.comPropertyGeneralData.comPropertyDatas[items.label].propertyValue === "true" ? "false" : "true")}
                                                                leftValue="On"
                                                                rightValue="Off"
                                                                disabled={(["unsecured.cellular.network.groups","default.mtf.upload"].includes(items.label) && this.props.roleAndPermission.roleId === 2)}
                                                            />
                                                        }
                                                    </Col>
                                                </React.Fragment>
                                                : ""}
                                        </Row>
                                    })}
                                    
                                    { configData.vdGlobalSettings && configData.vdDefaultSettings && configData.vdProfileSettings && item.data.length && item.isDisplay && item.label === 'Virtual Devices' ?
                                        item.data.map((subCat, subCatKey)=>{
                                                return <Row key={subCatKey} className='pt-2'>
                                                        <Col>
                                                            <Row className='pl-4'><Col>{this.updateFormCategoryTitle(subCat)}</Col></Row>
                                                            
                                                            { subCat.isDisplay && subCat.data.map((subData, dataKey)=>{
                                                                return <React.Fragment key={dataKey}>
                                                                    
                                                                    {subCat.label === 'Global Setting' || subCat.label === 'Default Setting' ?
                                                                        <Row className='pl-4 pt-3' key={dataKey}>
                                                                                    <Col xs={8}>
                                                                                        <Row className='pl-4'>
                                                                                            <Col>
                                                                                                <span className="customTooltip"> 
                                                                                                    {subData.displayLabel} {configData[subData.mainDataName][subData.rulesCategory][subData.label]?.min !== null && configData[subData.mainDataName][subData.rulesCategory][subData.label]?.max !== null && "(" + configData[subData.mainDataName][subData.rulesCategory][subData.label]?.min + " - "+ configData[subData.mainDataName][subData.rulesCategory][subData.label]?.max + ")"}
                                                                                                    <span className="tooltiptext">{subData.toolTip}</span>
                                                                                                </span>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col xs={4}>
                                                                                        {subData.type === 'text' &&
                                                                                            <TextField
                                                                                                type={subData.type}
                                                                                                isDisabled={!configData[subData.mainDataName][subData.rulesCategory][subData.label]?.editable}
                                                                                                value={configData[subData.mainDataName][subData.subDataName][subData.label]?.propertyValue}
                                                                                                field={subData.field}
                                                                                                handleOnchange={(e) => this.context.updateFormData(subData.label, e, subData.mainDataName, subData.subDataName, subData.rulesCategory, 'virtualDeviceGlobalSettings')}
                                                                                                className={formError[subData.label] !== undefined && formError[subData.label] === true ? "form-err-border-red" : ""}
                                                                                            /> }
                                                                                    </Col>
                                                                                </Row> : 
                                                                                <React.Fragment>
                                                                                    { configData?.vdProfileSettings?.length !== 0 ?
                                                                                        <div className='pl-5 pt-4'>
                                                                                        <Table
                                                                                            tableColumn={this.state.profileTemplate}
                                                                                            tableRowData={configData.vdProfileSettings}
                                                                                            showPagination={false}
                                                                                            pageSize={15}
                                                                                            multiSort={false}
                                                                                            sortable={false}
                                                                                        />
                                                                                        </div>
                                                                                    :
                                                                                    <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />}
                                                                                </React.Fragment>
                                                                                }
                                                                        </React.Fragment>
                                                            })}
                                                        </Col>
                                                </Row>
                                            })
                                        :""
                                    }
                            </div>
                        }) 
                        : 
                         !this.context.state.isloadingData && <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />
                        }
                    </Row>
                </Col>
            </Row>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}

export default connect(mapStateToProps, {})(ConfigForm);