import React, { Component } from "react";
import Footer from '../../footer';
import { FormattedMessage } from 'react-intl';
import SingleSelect from "../../hoc/singleSelect";
import Button from "../../hoc/button";
import { Navbar } from 'react-bootstrap';
import { getOrganizations, selectOrganization } from "../../home/services/homeService";

class SelectOrganization extends Component {
    state = {
        orgDetails: [],
        selectedOrgId: { label: "Select Organization", value: "-1" }
    }

    //api call to fetch the list of organizations
    componentDidMount() {
        getOrganizations().then((data) => {
            if (data.data.status === "OK" && data.data.message === "Success") {
                this.setState({ orgDetails: data.data.data });
            }else{
                window.location.href = "/amplemanage"
            }
        });
    }

    // Change organization from dropdown list
    changeOrganization = (id) => {
        let list = this.state.orgDetails.length ? this.state.orgDetails.find(item => item.value === id) : { label: "Select Organization", value: "-1" }
        this.setState({ selectedOrgId: { label: list.label, value: id } });
    }

    onClickSubmit = () => {
        selectOrganization(this.state.selectedOrgId.value).then(res => {
            if (res.data.data) window.location.href = res.data.data
        })
    }

    render() {
        return (
            <React.Fragment>
                <Navbar variant="dark" fixed="top" className="width-100">
                    <Navbar.Brand>
                        <div className="logo"></div>
                    </Navbar.Brand>
                </Navbar>
                <div className="orgView">
                    <div className="orgContent">
                        <div className="orgWrap">
                            <span className='single-select-dropdown'>
                                <SingleSelect data={this.state.orgDetails.length ? this.state.orgDetails : [{ label: "Select Organization", value: "-1" }]} value={this.state.selectedOrgId.label} setType={this.changeOrganization} icon='fa-check' />
                            </span>
                            <Button btnText={<FormattedMessage id='login.form.submit' />} className="btn btn-warning btn-block mt-3 org-btn" handleButtonClick={() => { this.onClickSubmit() }} isDisabled={this.state.selectedOrgId.value === "-1"} />
                        </div>
                    </div>
                </div>
                <div className="login-footer">
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

export default SelectOrganization;