import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import NavigationItem from './navigationItem';

class SubNavigations extends Component {

    render() {
        let menus = this.props['menu'].map(menu => { 
            return <NavigationItem extraClass={menu.extraClass} isDisabled={menu.isDisabled} key={menu.key} link={`/${menu.link}`} exact="true"><FormattedMessage id={`${menu.key}`} /></NavigationItem> 
        })

        return (
            <Navbar fixed="top" className="width-100 secondary-navbar">
                <Navbar>
                    <Nav>
                        {menus}
                    </Nav>
                </Navbar>
            </Navbar>
        )
    }
}

export default SubNavigations;