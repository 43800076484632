import { getURL } from '../../../../providers/configProvider';
import { requestPOST, requestGET } from '../../../../services/extendedService';

export const getVirtualDeviceList = () => {
    var url = getURL('devicemanagement', 'devicelist', {apiType: "virtualdevicemanagement"});
    return requestGET(url);
}

export const getVdConfigurations = (addOrEdit, id) => {
    let vdId = (addOrEdit === "Edit" || addOrEdit === "View") ? id : '-1'
    var url = getURL('devicemanagement', 'configuration', {vdId: vdId, apiType: "virtualdevicemanagement"});
    return requestGET(url);
}

export const getVdTreeData = (params, clickedType, deviceId) => {

    let vdId = (clickedType === 'Edit' || clickedType === 'View') ? deviceId : '-1' //for add -1 and for edit need to pass actual ID.
    var url = "";

    switch (params.apiType) {
        case 'REGION':
            url = getURL('devicemanagement', 'substations', { 'regionname': params.REGION, vdId:vdId,  apiType: "virtualdevicemanagement" });
        break;
        case 'SUBSTATION':
            url = getURL('devicemanagement', 'feeders', { 'regionName': params.REGION, substationName: params.SUBSTATION,  vdId:vdId,  apiType: "virtualdevicemanagement" });
        break;
        case 'FEEDER':
            url = getURL('devicemanagement', 'sites', { 'regionName': params.REGION, substationName: params.SUBSTATION, feederName: params.FEEDER, vdId:vdId,  apiType: "virtualdevicemanagement" });
        break;
        case 'LATERAL':
            url = getURL('devicemanagement', 'lateralsites', { 'regionName': params.REGION, substationName: params.SUBSTATION, feederName: params.FEEDER, lateralName: params.LATERAL, vdId:vdId,   apiType: "virtualdevicemanagement" });
        break;
        case 'rootnode':
            url = getURL('devicemanagement', 'regions', {vdId:vdId,  apiType: "virtualdevicemanagement" });
        break;

        default:
                break;
    }

    return requestGET(url);
}

export const createOrUpdateVirtualDevice = (data, clickedType , id) => {
    let url = '';
    if(clickedType === "Add" ){
        url = getURL('devicemanagement', 'create', { apiType: "virtualdevicemanagement"});
    } else {
        url = getURL('devicemanagement', 'update', { vdId: id, apiType: "virtualdevicemanagement"});
    }
    return requestPOST(url, data, {}, { validateSuccess: false });
}

export const downloadVirtualDevice = (id) => {
    const url = getURL('devicemanagement', 'download', { vdId: id, apiType: "virtualdevicemanagement"});
    return requestGET(url)
        .then(() => {
            return url;
        })
};
export const deleteVdDevice = (id) => {
    const url = getURL('devicemanagement', 'delete', { vdId: id, apiType: "virtualdevicemanagement"});
    return requestPOST(url, id);
};

export const calculateDevicePoint = (data) => {
    const url = getURL('devicemanagement', 'calculate', { apiType: "virtualdevicemanagement"});
    return requestPOST(url, data, {}, { validateSuccess: false });
};

export const lockOrUnlock = (data) => {
    let url = "";
    if(data.locked ) url = getURL('devicemanagement', 'unlock', {vdId: data.id, apiType: "virtualdevicemanagement"});
    else url = getURL('devicemanagement', 'lock', {vdId: data.id, apiType: "virtualdevicemanagement"})
    return requestGET(url);
}

export const getLiveView = (vdId, uniqId) => {
    var url = getURL('devicemanagement', 'liveview', {vdId: vdId, requestId: uniqId, apiType: "virtualdevicemanagement"});
    return requestGET(url, {}, {validateSuccess: false });
}

export const updateLiveView = (vdId, pointId, value) => {
    var url = getURL('devicemanagement', 'liveviewedit', {vdId: vdId, pointId: pointId, value: value, apiType: "virtualdevicemanagement"});
    return requestGET(url, {}, {validateSuccess: false });
}

export const getOnlineStatus = (vdId) => {
    var url = getURL('devicemanagement', 'onlinestatus', {vdid: vdId, apiType: "virtualdevicemanagement"});
    return requestGET(url, {}, {validateSuccess: false });
}

export const uploadVDMPointsFile = (id, data) => {
    const url = getURL('devicemanagement', 'uploadPointFile', {vdId: id, apiType: "virtualdevicemanagement"});
    return requestPOST(url, data, {}, { validateSuccess: false })
};