import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import 'react-table-6/react-table.css';
import { FormattedMessage } from 'react-intl';
import Loader from '../../../hoc/loader'
import LicenceTable from './licenceTable'
import { LicenceContext } from '../provider/licenceProvider'
import UpdateMessage from '../components/updateMessage'

class LicenseManagementContainer extends Component {
    static contextType = LicenceContext;
    render() {
        return (
            <React.Fragment>
                {this.context.state.isLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                {this.context.state.fileUploadRes.type !== "" ?
                    <UpdateMessage />
                    : ""}
                <Row>
                    <Col className='p-0'><FormattedMessage id='db.common.setting' /> : <FormattedMessage id='db.licMngmnt.licenseManagement' /></Col>
                </Row>
                <Row>
                    <Col className='p-0'>
                        <LicenceTable />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default LicenseManagementContainer;
