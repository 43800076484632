import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { AuditTrailContext } from '../provider/auditTrailProvider'
import MultiSelectDropdown from '../../manage/details/components/multiselectDropdownComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import DateRangePicker from "react-bootstrap-daterangepicker";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import TextField from '../../../hoc/form/textField';
import Button from '../../../hoc/button';
import { auditTrailData } from '../../../utility/auditTrailUtility';

class AuditTrailTopContainer extends Component {
    static contextType = AuditTrailContext;
    render() {
        const { formData } = this.context.state;
        return (
            <Row className="at-top-section">
                <Col xs={10}>
                    <Row>
                        {auditTrailData.formFields.map((item, key) => {
                            return <Col key={key}>
                                {item['col' + key].map((colItem, colKey) => {
                                    return <Row key={colKey} className= { colKey === 0 ? "each-row-gap" : ""}>
                                        <Col className='top-label'>{colItem.label} </Col>
                                        <Col xs={7} className="p-0 m-0">
                                            {colItem.type === 'date' ?
                                                <div className="container-fluid p-0 m-0">
                                                    <span className="date-input-field float-left">
                                                        <input className="formatDate" value={moment(formData[colItem.fName]).format("MM/DD/YYYY")} disabled={true} />
                                                    </span>
                                                    <DateRangePicker
                                                        initialSettings={{
                                                            singleDatePicker: true,
                                                            showDropdowns: true,
                                                            startDate: moment(formData[colItem.fName]),
                                                            minYear: parseInt(moment().subtract(1, "year").format('YYYY'), 10),
                                                            maxYear: parseInt(moment().add(20, "year").format('YYYY'), 10),
                                                            maxDate: moment(new Date()),
                                                            showISOWeekNumbers: true,
                                                            opens: 'left',
                                                            autoApply: true,
                                                        }}
                                                        onCallback={(e, picker)=> this.context.updateCallenderValue(colItem.fName, e, picker)}
                                                    >
                                                        <span className="date-icon icon-width float-right"><FontAwesomeIcon icon={faCalendarAlt} size="lg" /></span>
                                                    </DateRangePicker>
                                                </div>
                                                : ""}

                                            {colItem.type === 'multiSelect' ?
                                                <MultiSelectDropdown
                                                    // selectedArrayList={auditTrailData.selectedDataByUser[colItem.fName]}
                                                    selectedArrayList={formData[colItem.fName]}
                                                    optionList={this.context.state[colItem.fName]}
                                                    onChange={this.context.onChangeHandler}
                                                    name={[colItem.fName]}
                                                    multiple={true}
                                                    includeSelectAll={false}
                                                    includeFilter={false}
                                                    open={false}
                                                    valueKey="value"
                                                    labelKey="text"
                                                    keepOpen={true}
                                                    placeholder="Select"
                                                    manySelectedPlaceholder={`%s/${this.context.state[colItem.fName].length} Checked, ... `}
                                                    allSelectedPlaceholder={`%s/${this.context.state[colItem.fName].length} Checked, ... `}
                                                    handleShowAllBtn={()=>this.context.handleShowAllBtn([colItem.fName])}
                                                    btmLabel={"Show All"}
                                                />
                                                : ""}

                                            {colItem.type === 'text' ? <TextField type={colItem.type} value={formData[colItem.fName]} field={[colItem.fName]} placeholder="Search" handleOnchange={this.context.onChangeHandler} size={'md'} /> : ""}
                                        </Col>
                                    </Row>
                                })}
                            </Col>
                        })}
                    </Row>
                </Col>
                <Col xs={2} className="apply-btn-area">
                    <Button btnText={<FormattedMessage id='dm.column.apply' />} className={"apply-btn"} handleButtonClick={this.context.handleClickApplyBtn} />
                </Col>
            </Row>
        );
    }
}

export default AuditTrailTopContainer;
