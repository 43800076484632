import React, { Component } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { RegisterContext } from '../provider/registerProvider';
import { FormattedMessage } from 'react-intl';

class FilterWithBadge extends Component {
    static contextType = RegisterContext;
    myRef = React.createRef();

    handleApply = () => {
        this.context.handleClickFilterApply()
        this.myRef.current.click();
    }

    handleReset = () => {
        this.context.handleClickFilterReset()
        this.myRef.current.click();
    }

    render() {
        return (
            <span className='dropdown-badge'>
                <Dropdown id="dropdown-custom-filters" className="pr-1" >
                    <Dropdown.Toggle className="btn-outline-secondary btn-group-icons btn-light grp-btn-first m-0" ref={this.myRef}>
                    <FontAwesomeIcon icon={faFilter} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-disable-autohide dropdown-filters">
                        {this.props.dropDownContent}
                        <Row>
                            <Col>
                                <button className={'btn btn-default'} disabled={this.props.isDisableApplyBtn}  onClick={this.handleApply}> <FormattedMessage id={"dm.column.apply"}/> </button>
                                <button className={'btn btn-default'} disabled={this.props.isDisableResetBtn}  onClick={this.handleReset}> <FormattedMessage id={"dm.column.resetAll"}/> </button>
                            </Col>
                        </Row>
                    </Dropdown.Menu>
                </Dropdown>
                {this.props.badgeNo !== 0 ?  <span className="badge-position">{this.props.badgeNo}</span> : ''}
            </span>
        )
    }
}

export default FilterWithBadge;