import * as actionTypes from './loginActionTypes';

export const onLogIn = (loginData) => {
    return {
        type: actionTypes.ON_LOGIN,
        loggedIn: loginData.username && loginData.password ? true: false,
        loginData: loginData
    };
};

export const onLogOut = () => {
    return {
        type: actionTypes.ON_LOGOUT,
        loggedIn: false,
        loginData: {}
    };
};

export const setSession = (sessionData) => {
    return {
        type: actionTypes.SET_SESSION,
        sessionData: sessionData
    };
};

export const updateFormLogin = (formlogin, errormsg, hideforgotpassword) => {
    return {
        type: actionTypes.UPDATE_FORM_LOGIN,
        formLogin: formlogin,
        errorMessage: errormsg,
        hideForgotPassword: hideforgotpassword
    }
};

export const updateGlobalTree = (newData) => {
    return {
        type: actionTypes.UPDATE_GLOBAL_TREE,
        globalTreeData: newData
    }
}
export const updateSearchQuery = (newData) => {
    return {
        type: actionTypes.UPDATE_SEARCH_QUERY,
        searchQuery: newData
    }
}
export const notify = (newData) => {
    return {
        type: actionTypes.UPDATE_NOTIFY,
        notify: newData
    }
}
export const showAlertToaster = (errorMessage, msgType) => {
    return {
        type: actionTypes.OPEN_ALERT_WINDOW,
        errorMessage: errorMessage,
        msgType: msgType
    };
}
export const closeAlertToaster = () => {
    return {
        type: actionTypes.CLOSE_ALERT_WINDOW,
        errorMessage: ''
    };
}
export const openErrorWindow = (errorMessage, errorTile) => {
    return {
        type: actionTypes.OPEN_ERROR_WINDOW,
        errorTile: errorTile,
        errorMessage: errorMessage
    };
}
export const closeErrorWindow = (errorMessage) => {
    return {
        type: actionTypes.CLOSE_ERROR_WINDOW,
        errorTile: '',
        errorMessage: ''
    };
}
export const setLoader = (loaderData) => {
    return {
        type: actionTypes.SET_LOADER,
        loaderData: loaderData
    };
};