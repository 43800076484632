import React, { createContext } from 'react';
import { getProfileData, createProfileData, deleteProfile, getProfileMapping } from '../services/eProfileService';
import { equipmentProfileData, cableProfileData } from '../../../utility/equimentprofileUtility';
import { connect } from 'react-redux';
import { showAlertToaster } from '../../../login/actions/loginActionDispatch';
import { FormattedMessage } from 'react-intl';
import ampleStore from '../../../store';

export const EProfileContext = createContext();

class EProfileProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayColumns: equipmentProfileData.selectedColumn,
            displayCableProfileColumns: cableProfileData.selectedColumn,
            transformerFields: equipmentProfileData.formFields,
            cableFields: cableProfileData.formFields,
            eProfileData: [],
            cableProfileData: [],
            orgId: this.props.tree[0].id,
            openDeleteModal: false,
            openAddOrEditModal: false,
            equipmentProfileAssignedtoDevice: [],
            modalLoader: false,
            TransformerLoader: false,
            CableLoader: false,
            errmsg: '',
            OSName: 'other',
            deletedRow: {},
            isEditClicked: false,
            selectedProfile: equipmentProfileData.profileList[0],
            transformerFormData: { transformerProfileName: "", manufacturerName: "", hvRating: "", lvRating: "", kvaRating: "", turnsRatio: "", impedancePercent: "" },
            cableFormData: { cableProfileName: "", cableType: "", nominalRating: "", summerEmergency: "", winterEmergency: "", conductorMaterial: "", conductorSize: "", insulationType: "" }
        }
    }

    componentDidMount() {
        this.getEProfileData();
        this.getCableProfileData();
        this.checkOperatingSystem();
        !sessionStorage.getItem("equipmentProfileExpandColumn") ? sessionStorage.setItem("equipmentProfileExpandColumn", JSON.stringify(this.state.displayColumns)) : this.setState({ displayColumns: [...JSON.parse(sessionStorage.getItem("equipmentProfileExpandColumn"))] });
        !sessionStorage.getItem("cableProfileExpandColumn") ? sessionStorage.setItem("cableProfileExpandColumn", JSON.stringify(this.state.displayCableProfileColumns)) : this.setState({ displayCableProfileColumns: [...JSON.parse(sessionStorage.getItem("cableProfileExpandColumn"))] });
    }

    checkOperatingSystem = () => {
        if (navigator.appVersion.indexOf("Mac") !== -1) this.setState({ OSName: 'MacOS' })
    }

    getEProfileData = () => {
        this.setState({ TransformerLoader: true })
        getProfileData("getTransformerProfileData", this.state.orgId)
            .then((data) => {
                this.setState({ TransformerLoader: false, eProfileData: Array.isArray(data) ? data : [] });
            }).catch((err) => { })
    }

    getCableProfileData = () => {
        this.setState({ CableLoader: true })
        getProfileData("getCableProfileData", this.state.orgId)
            .then((data) => {
                this.setState({ CableLoader: false, cableProfileData: Array.isArray(data) ? data : [] });
            }).catch((err) => { })
    }

    filterProfileColumns = (value, profileName) => {
        let prvData = (profileName === equipmentProfileData.profileList[0]) ? this.state.displayColumns : this.state.displayCableProfileColumns;
        let filterData = [];
        if (prvData.includes(value)) filterData = prvData.filter(item => item !== value)
        else filterData = [...prvData, value]
        if (profileName === equipmentProfileData.profileList[0]) {
            equipmentProfileData.selectedColumn = filterData;
            sessionStorage.setItem("equipmentProfileExpandColumn", JSON.stringify(filterData));
            this.setState({ displayColumns: filterData })
        }
        else {
            cableProfileData.selectedColumn = filterData;
            sessionStorage.setItem("cableProfileExpandColumn", JSON.stringify(filterData));
            this.setState({ displayCableProfileColumns: filterData })
        }
    }

    onClickAddOrEditOrDeleteIcon = (rowData, icon, profileName) => {
        switch (icon) {
            case 'add':
                this.resetFormFields();
                this.setState({ openAddOrEditModal: true, isEditClicked: false, selectedProfile: profileName, transformerFormData: { transformerProfileName: "", manufacturerName: "", hvRating: "", lvRating: "", kvaRating: "", turnsRatio: "", impedancePercent: "", orgId: this.props.tree[0].id }, cableFormData: { cableProfileName: "", cableType: "", nominalRating: "", summerEmergency: "", winterEmergency: "", conductorMaterial: "", conductorSize: "", insulationType: "", orgId: this.props.tree[0].id }, });
                break;
            case 'edit':
                this.resetFormFields();
                profileName === equipmentProfileData.profileList[0] ? this.setState({ transformerFormData: rowData, openAddOrEditModal: true, isEditClicked: true, selectedProfile: profileName }) : this.setState({ cableFormData: rowData, openAddOrEditModal: true, isEditClicked: true, selectedProfile: profileName })
                break;
            case 'delete':
                this.setState({ selectedProfile: profileName, deletedRow: rowData });
                this.openDeleteModalPopup(rowData.id)
                break;
            default:
                break;
        }
    }

    onClickSaveOrUpdateBtn = async (btnName) => {
        if (btnName === "cancel") this.setState({ openAddOrEditModal: false, errmsg: '' })
        else {
            if (this.state.selectedProfile === equipmentProfileData.profileList[0]) {
                let checkAllValidate = await this.onSaveValidation(this.state.transformerFields, this.state.transformerFormData, this.state.eProfileData);
                let errFields = checkAllValidate.find(item => item.hasOwnProperty('isErr'));
                this.setState({ transformerFields: checkAllValidate })
                !errFields && this.saveProfile(this.state.transformerFormData, 'createTransformerProfile');
            } else {
                let checkAllValidate = await this.onSaveValidation(this.state.cableFields, this.state.cableFormData, this.state.cableProfileData);
                let errFields = checkAllValidate.find(item => item.hasOwnProperty('isErr'));
                this.setState({ cableFields: checkAllValidate })
                !errFields && this.saveProfile(this.state.cableFormData, 'createCableProfile');
            }
        }
    }

    // Check all the mandatory fields are filled before saving or updating
    onSaveValidation = (formFields, formData, profileList) => {
        const updateIsErrData = formFields.map(item => {
            let emptyCheck = item.isMandatory && !formData[item.value]
            let val = (item.value === "transformerProfileName" || item.value === "cableProfileName") ? formData[item.value]?.length < 2 || formData[item.value]?.length > 64 || (item?.regEx && this.testValidation(item.regEx, formData[item.value])) : (item?.regEx && this.testValidation(item.regEx, formData[item.value]))
            let profileExistMessage = !this.state.isEditClicked && (item.value === "transformerProfileName" || item.value === "cableProfileName") ? this.profileExist(item.value, formData[item.value], profileList) : "";
            if (val || emptyCheck || profileExistMessage) return { ...item, isErr: emptyCheck ? <FormattedMessage id='db.equipmentprofile.emptyError' /> : val ? item.errorMessage : profileExistMessage }
            else {
                delete item.isErr;
                return item;
            }
        })
        return updateIsErrData
    }

    handleOnchangeForm = (value, field) => {
        if (this.state.selectedProfile === equipmentProfileData.profileList[0]) {
            // Calculation for turnsRatio = (hvRating / lvRating)
            let turnsratio = field === "turnsRatio" ? value : this.state.transformerFormData["turnsRatio"]
            if (field === "hvRating") turnsratio = value && !isNaN(Number(value)) && this.state.transformerFormData["lvRating"] && !isNaN(Number(this.state.transformerFormData["lvRating"])) ? (value / this.state.transformerFormData["lvRating"]) : ""
            else if (field === "lvRating") turnsratio = value && !isNaN(Number(value)) && this.state.transformerFormData["hvRating"] && !isNaN(Number(this.state.transformerFormData["hvRating"])) ? (this.state.transformerFormData["hvRating"] / value) : ""
            let updatedValue = { [field]: value, turnsRatio: turnsratio && !isNaN(Number(turnsratio)) ? Math.round(turnsratio) : turnsratio }

            let checkAllValidate = this.onChangeValidation(field, value, this.state.transformerFields, this.state.eProfileData)
            if (field === "hvRating" || field === "lvRating" || field === "turnsRatio") checkAllValidate.map(item => { item.value === "turnsRatio" && turnsratio && !isNaN(Number(turnsratio)) && delete item.isErr })
            this.setState({ transformerFormData: { ...this.state.transformerFormData, ...updatedValue }, transformerFields: checkAllValidate })
        } else {
            let checkAllValidate = this.onChangeValidation(field, value, this.state.cableFields, this.state.cableProfileData)
            this.setState({ cableFormData: { ...this.state.cableFormData, [field]: value }, cableFields: checkAllValidate })
        }
    }

    onChangeValidation = (field, value, formFields, profileList) => {
        const updateIsErrData = formFields.map(item => {
            if (item.value === field) {
                let emptyCheck = item.isMandatory && !value
                let val = (field === "transformerProfileName" || field === "cableProfileName") ? value?.length < 2 || value?.length > 64 || (item?.regEx && this.testValidation(item.regEx, value)) : item?.regEx && this.testValidation(item.regEx, value)
                let profileExistMessage = !this.state.isEditClicked && (field === "transformerProfileName" || field === "cableProfileName") ? this.profileExist(field, value, profileList) : "";
                if (val || emptyCheck || profileExistMessage) return { ...item, isErr: emptyCheck ? <FormattedMessage id='db.equipmentprofile.emptyError' /> : val ? item.errorMessage : profileExistMessage }
                else {
                    delete item.isErr;
                    return item;
                }
            }
            else return item
        })
        return updateIsErrData
    }

    profileExist = (field, value, profileList) => {
        let profileExist = profileList.filter(item => (item[field])?.toLowerCase() === (value)?.toLowerCase())
        const profileExistMessage = profileExist?.length ? field === "transformerProfileName" ? <FormattedMessage id='db.equipmentprofile.profileExist' /> : <FormattedMessage id='db.cableprofile.profileExist' /> : "";
        return profileExistMessage;
    }

    testValidation = (regex, value) => {
        return !regex.test(value)
    }

    resetFormFields = () => {
        let efield = this.state.transformerFields;
        efield.forEach(item => { delete item.isErr });
        let cfield = this.state.cableFields;
        cfield.forEach(item => { delete item.isErr });
        this.setState({ transformerFields: efield, cableFields: cfield })
    }

    saveProfile = (formData, formName) => {
        this.setState({ modalLoader: true }, () => {
            createProfileData(formData, formName)
                .then(resp => {
                    if (resp && resp?.data?.status !== "FAIL") {
                        this.setState({ modalLoader: false, openAddOrEditModal: false, errmsg: '' })
                        this.state.selectedProfile === equipmentProfileData.profileList[0] ? this.getEProfileData() : this.getCableProfileData();
                        ampleStore.dispatch(showAlertToaster(<div><strong>{formName === "createTransformerProfile" ? formData.transformerProfileName : formData.cableProfileName}</strong> : {this.state.isEditClicked ? <FormattedMessage id='db.equipmentprofile.updated' /> : <FormattedMessage id='db.equipmentprofile.added' />}  </div>, 'success'))
                    } else {
                        this.setState({ modalLoader: false, errmsg: resp && resp?.data?.message ? resp.data.message : "Error occurred" })
                    }
                })
        })
    }

    openDeleteModalPopup = (id) => {
        this.setState({ equipmentProfileAssignedtoDevice: [], openDeleteModal: true, modalLoader: true }, () => {
            let name = this.state.selectedProfile === equipmentProfileData.profileList[0] ? 'getTransformerProfileMapping' : 'getCableProfileMapping';
            getProfileMapping([id], name, this.state.orgId)
                .then(resp => {
                    resp && Array.isArray(resp.data.data) ? this.setState({ equipmentProfileAssignedtoDevice: resp.data.data, modalLoader: false }) :
                        resp?.data ? resp.data.status === "FAIL" && this.setState({ equipmentProfileAssignedtoDevice: [], errmsg: resp?.data?.message ? resp.data.message : "Status failed", modalLoader: false }) : this.setState({ openDeleteModal: false, errmsg: '' })
                });
        });
    }

    onClickDeleteBtn = (btnName) => {
        (btnName === "cancel") ? this.setState({ openDeleteModal: false, errmsg: '' }) : this.deleteProfile();
    }

    deleteProfile = () => {
        this.setState({ modalLoader: true }, () => {
            let name = this.state.selectedProfile === equipmentProfileData.profileList[0] ? 'deleteTransformerProfile' : 'deleteCabeProfile';
            deleteProfile(name, this.state.deletedRow.id)
                .then(resp => {
                    if (resp && resp?.data?.status !== "FAIL") {
                        this.setState({ modalLoader: false, openDeleteModal: false, errmsg: '' })
                        this.state.selectedProfile === equipmentProfileData.profileList[0] ? this.getEProfileData() : this.getCableProfileData();
                        ampleStore.dispatch(showAlertToaster(<><strong>{this.state.selectedProfile === equipmentProfileData.profileList[0] ? this.state.deletedRow.transformerProfileName : this.state.deletedRow.cableProfileName}</strong> : {resp?.data?.message ? resp.data.message : "Profile deleted successfully"}</>, 'success'))
                    } else {
                        this.setState({ modalLoader: false, errmsg: resp && resp?.data?.message ? resp.data.message : "Error occurred" })
                    }
                });
        })
    }

    closeModal = () => {
        this.setState({ openDeleteModal: false, openAddOrEditModal: false, errmsg: '' });
    }
    closeErrorMessages = () => {
        this.setState({ errmsg: '' });
    }

    render() {
        return (
            <EProfileContext.Provider value={{
                state: this.state,
                filterProfileColumns: this.filterProfileColumns,
                onClickAddOrEditOrDeleteIcon: this.onClickAddOrEditOrDeleteIcon,
                closeModal: this.closeModal,
                handleOnchangeForm: this.handleOnchangeForm,
                onClickSaveOrUpdateBtn: this.onClickSaveOrUpdateBtn,
                onClickDeleteBtn: this.onClickDeleteBtn,
                closeErrorMessages: this.closeErrorMessages
            }}>
                {this.props.children}
            </EProfileContext.Provider>
        )
    }
}
// Getting the state from the store
const mapStateToProps = state => {
    return {
        tree: state.treeviewData.tree
    };
}

export default connect(mapStateToProps)(EProfileProvider);