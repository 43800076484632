import React from 'react';
import { FormattedMessage } from 'react-intl';
import AdditinalInfoModal from '../mainContent/auditTrail/components/additionalInfoModal';
import TableMultiSortNumber from '../hoc/tableMultiSortNumber';
import { formatDateAndTime } from '../filters/dateHelper';
import {fetchBrowserTimeZone} from '../services/utilityService'

export const auditTrailData = {
    pageNo: 1,
    pageSize: 10,
    // pageSizeOptions: [10, 25, 50, 75],
    // previousText: <FormattedMessage id='db.common.pgntn.previous' />,
    // nextText: <FormattedMessage id='db.common.pgntn.next' />,
    // pageText: <FormattedMessage id='db.common.pgntn.rowsPerPage' />,
    // ofText: <FormattedMessage id='db.common.pgntn.of' />,
    // rowsText: <FormattedMessage id='db.common.pgntn.rows' />,
    // firstText: <FormattedMessage id='db.common.pgntn.first' />,
    // lastText: <FormattedMessage id='db.common.pgntn.last' />,

    formFields : [
        {
            col0: [
                { label: <><FormattedMessage id='dm.audittrail.form.start_Date_IST' />{fetchBrowserTimeZone()}</>, fName: 'startTimestamp', type: 'date' },
                { label: <FormattedMessage id='dm.tab.generatedLog.table.user' />, fName: 'userIds', type: 'multiSelect' },
            ]
        },
        {
            col1: [
                { label: <><FormattedMessage id='dm.audittrail.form.end_Date_IST' />{fetchBrowserTimeZone()}</>, fName: 'endTimestamp', type: 'date'},
                { label: <FormattedMessage id='dm.column.ipAddress' />, fName: 'ipAddress', type: 'text' },
            ]
        },
        {
            col2: [
                { label: <FormattedMessage id='db.common.action' />, fName: 'actionTypes', type: 'multiSelect' },
                { label: <FormattedMessage id='dm.audittrail.form.serial_no' />, fName: 'serialNumber', type: 'text' },
            ]
        }
    ],

    columnsData: [
        { accessor: 'action', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dm.audittrail.table.action_type' />} />, sortedOrder: 0, },
        { accessor: 'timeOfAction', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dm.configure.tab.nonCfci.header.time' />}/>, sortedOrder: 0, Cell: (original) => formatDateAndTime(original.row.timeOfAction) },
        { accessor: 'userId', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dm.tab.generatedLog.table.user' />}/>, sortedOrder: 0, },
        { accessor: 'ipAddress', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dm.column.ipAddress' />}/>, sortedOrder: 0, },
        { accessor: 'serialNumber', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dm.audittrail.form.serial_no' />}/>, sortedOrder: 0, },
        { accessor: 'additionalInfo', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dm.audittrail.table.additional_info' />}/>, sortedOrder: 0, Cell: (original) => <AdditinalInfoModal data={original} title={'Additional Info'}/>},
    ],

    selectedDataByUser:{
        startTimestamp:'',
        endTimestamp: new Date().setHours(23,59,59,999),
        userIds: [],
        actionTypes:[],
    }
}