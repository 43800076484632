import axios from 'axios';
import React from 'react';
import ampleStore from '../store';
import { openErrorWindow } from '../login/actions/loginActionsBuilder';
import { FormattedMessage } from 'react-intl';

const CancelToken = axios.CancelToken;
let cancel;
let configParams = {
    timeout : 180000	// 3 * 60 * 1000 - 3 minutes in milliseconds
};

export const requestPOST = async(url, params, reqConfig, options) => {
    if (!reqConfig) reqConfig = {};
    reqConfig['timeout'] = configParams.timeout;
    return axios.post(url, params, reqConfig)
        .then(response => {
            if(options && !options.validateSuccess){
                return response;
            }
            if (typeof response.data === 'object') {
                var data = response.data.data ? response.data.data : response.data;
                return data;
            }
        })
        .catch(err => {
            return ampleStore.dispatch(openErrorWindow(<FormattedMessage id='common.error.server'/>));
        })
}

export const requestPOSTtreeView = async(url, params) => {
    if (cancel !== undefined ) {
        cancel();
    }
    return axios.post(url, params, {
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
    }).then(response => {
        if (typeof response.data === 'object') {
            var data = response.data.data ? response.data.data : response.data;
            return data;
        }
    }).catch(err => {
        if (axios.isCancel(err)) {
            return { message: "data canceled" };
        } else {
            return ampleStore.dispatch(openErrorWindow(<FormattedMessage id='common.error.server'/>));
        }
    })
}

export const requestGET = async(url, reqConfig, options) => {
    if (!reqConfig) reqConfig = {};
    reqConfig['timeout'] = configParams.timeout;
    return axios.get(url, reqConfig)
        .then(response => {
            if(options && !options.validateSuccess){
                return response;
            }
            if (typeof response.data === 'object') {
                var data = response.data.data ? response.data.data : response.data;
                return data;
            } else if (typeof response.data === 'string'){
                return url
            }
        })
        .catch(err => {
            return ampleStore.dispatch(openErrorWindow(<FormattedMessage id='common.error.server'/>));
        })
}

export const requestDELETE = async (url, reqConfig, options) => {
    if (!reqConfig) reqConfig = {};
    reqConfig['timeout'] = configParams.timeout;
    return axios.delete(url, reqConfig)
        .then(response => {
            if (options && !options.validateSuccess) {
                return response;
            }
            if (typeof response.data === 'object') {
                var data = response.data.data ? response.data.data : response.data;
                return data;
            } else if (typeof response.data === 'string') {
                return url
            }
        })
        .catch(err => {
            return ampleStore.dispatch(openErrorWindow(<FormattedMessage id='common.error.server' />));
        })
}

export const requestQAll = async(urls) => {
    var urlCalls = urls.map(url => axios.get(url));
    return axios.all(urlCalls)
        .then(responses => {
            var res = [];
            responses.forEach(response => {
                if (response.data === '') {
                    res.push(0);
                } else if (typeof response.data === 'object') {
                    var data = response.data.data ? response.data.data : response.data;
                    res.push(data);
                }
            })
            return res
        })
        .catch(err => {
            return ampleStore.dispatch(openErrorWindow(<FormattedMessage id='common.error.server'/>));
        })
}
