import { getURL } from '../../../../providers/configProvider';
import { requestGET } from '../../../../services/extendedService';

export const getSummaryData = (params) => {
    const url = getURL('summaryevents', 'getData', params);
    return requestGET(url)
}

export const exportData = (params) => {
    const url = getURL('summaryevents', 'exportData', params);
    return requestGET(url)
        .then(() => {
            return url;
        })
}