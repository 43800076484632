import React from 'react';
import { connect } from 'react-redux';
import { saveUpdatedWatchlist } from '../services/dashboardService';
import TreeViewWithCheckbox from '../../../hoc/treeViewWithCheckbox';
import AlertMessage from '../../../hoc/alert';
import { FormattedMessage } from 'react-intl';
import ModalWindow from '../../../hoc/modelWindow';

class ManageWatchListModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            manageWatchListData: [],
            allCheckedData: [],
            watchlistErr:""
        }
    }

    handleClose = () => this.props.onHide();

    receiveUpdatedData = (allData) => this.setState({ allCheckedData: allData })

    handleSaveWatchlist = () => {
        saveUpdatedWatchlist(this.state.allCheckedData)
            .then(resData => {
                if (resData.status === "FAIL") {
                    this.setState({watchlistErr:resData.message})
                } else {
                    this.props.handleSuccessMessage(true)
                    this.props.getupdatedwatchlistdata();
                    this.handleClose()
                }
            })
    }

    onBtnClickHandlera = (value) => {
        if (value === 'cancel') this.handleClose();
        else if (value === 'save') this.handleSaveWatchlist();
    }

    render() {
        return (

            <ModalWindow
                show={this.props.show}
                size="lg"
                dialogClassName={"custom-dialog-watchlist-modal"}
                title={<span className="watchlist-header-title"> <FormattedMessage id='db.modal.manageYourWatchlist' /></span>}
                onBtnClickHandler={this.onBtnClickHandlera}
                onHide={this.handleClose}
                modeldata={{
                    content: <React.Fragment>
                        {this.state.watchlistErr && <AlertMessage position="mb-2" type={'danger'} message={this.state.watchlistErr}/>}
                        <div className="watchlist-data-area">
                            <div className="manage-watchlist">
                                <TreeViewWithCheckbox
                                    passUpdatedData={this.receiveUpdatedData}
                                    expandUpto={"FEEDER"} //display up to
                                    checkedLableName={"subscribedForWatchlist"} //for checkbox true or false by this value
                                    from={'dashboard'} //for api call
                                    checkBoxVisible={'all'} // visible checkbox level
                                />
                            </div>
                        </div>
                    </React.Fragment>
                }}
                footer={
                        [{ className: "modalCancelBtn", name: <FormattedMessage id='dm.column.cancel' />, value: "cancel"}, { className: "modalSaveBtn", name: <FormattedMessage id='dm.column.save' />, value: "save" }]
                }
            />
        )
    }
}
const mapStateToProps = state => {
    return {
        requestparams: state.dashboardData.requestParams
    };
}
export default connect(mapStateToProps)(ManageWatchListModal);