import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import Table from '../../../hoc/table';
import * as customReportsManagementUtility from '../../../utility/customReportsManagementUtility';
import { CustomReportsManagementContext } from '../provider/customReportsManagementProvider';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../hoc/tooltip';
import Loader from '../../../hoc/loader';
import BlankPage from '../../../hoc/blankPage';

class CustomReportsManagementTable extends Component {
    static contextType = CustomReportsManagementContext;
    constructor(props) {
        super(props);
        this.state = {
            tableColumns: [
                ...customReportsManagementUtility.tableOptions.tableColumns,
                {
                    accessor: 'action', Header: <FormattedMessage id='dm.column.actions' />, sortable: false, maxWidth: 130, Cell: ({ original }) => {
                        return <span>
                            <Tooltip baseContent={<FontAwesomeIcon className='cursor-pointer' onClick={() => this.context.handleEditOrDeleteIconClick(original, 'edit')} icon={faPencilAlt} size="sm" />} message={<FormattedMessage id='groupmanagement.context.edit' />} placement={'top'} />&nbsp;&nbsp;&nbsp;
                            <Tooltip baseContent={<FontAwesomeIcon className='cursor-pointer' onClick={() => this.context.handleEditOrDeleteIconClick(original, 'delete')} icon={faTrashAlt} size="sm" />} message={<FormattedMessage id='groupmanagement.context.delete' />} placement={'top'} />
                        </span>
                    }
                }
            ]
        }
    }


    render() {
        return (
            <Fragment>
                <Row>
                    <Col xs={12} className="pb-2">
                        <FormattedMessage id='db.common.setting' />&nbsp;:&nbsp;<FormattedMessage id='customReports.manageReports.breadcrumb' />
                    </Col>
                </Row>
                <div className={this.context.state.customReportData.length === 0 || this.context.state.loading ? 'borderForLoader' : ""}>
                    {this.context.state.loading ? <Loader elementClass="tree-ajax-loader center-element" /> : this.context.state.customReportData.length ?
                        <Col xs={12} md={12} className={"m-0 p-0"}>
                            <Table
                                tableColumn={this.state.tableColumns}
                                tableRowData={this.context.state.customReportData} />
                        </Col> : <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />}
                </div>
            </Fragment>
        )
    }
}

export default CustomReportsManagementTable;