import React, { Component } from 'react';
import { Row, Col, Form, FormControl } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Loader from '../../../hoc/loader';
import AlertMessage from '../../../hoc/alert';
import { changePasswordData } from '../../../utility/userManagementUtility';
import { updateLoggedinUserPassword, logout } from '../../userManagement/services/userService';
import ModalWindow from '../../../hoc/modelWindow';

class ChangePasswordContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: true,
            isLoading: false,
            formFields: changePasswordData.formFields,
            formData: { currentPassword: "", newPassword: "", confirmNewPassword: "" },
            formError: { currentPassword: "", newPassword: "", confirmNewPassword: "" },
            errMsg: "",
            type: '',
            isClickedUpdate: false
        }
    }

    closeConfigModal = () => {
        if (this.state.errMsg !== "" && this.state.type === 'success') this.handleLogOutUser()
        changePasswordData.isOpenChangePasswordeModal = false;
        this.setState({ isOpenModal: true, isClickedUpdate: false })
    }

    componentWillReceiveProps() {
        this.setState({
            formError: { currentPassword: "", newPassword: "", confirmNewPassword: "" },
            formData: { currentPassword: "", newPassword: "", confirmNewPassword: "" },
            errMsg: "", type: "",
        })
    }

    handleOnchangeForm = (value, field) => {
        this.setState({ formData: { ...this.state.formData, [field]: value }, fieldName: field }, () => {
            let {formError} = this.state;
            if(formError.newPassword !== "" || formError.currentPassword !== "" || formError.confirmNewPassword !== "" || this.state.isClickedUpdate ){
                let allErrMsg = this.handleValidateForm('onchange');
                this.setState({ formError: allErrMsg })
            }
        })
    }

    handleUpdatePassword = () => {
        this.setState({ isLoading: true }, () => {
            updateLoggedinUserPassword(this.state.formData)
                .then(data => {
                    if (data.status === "OK" && data.message === "Success") this.setState({ errMsg: <FormattedMessage id='db.changepassword.error.update.success' />, type: 'success', isLoading: false })
                    else this.setState({ errMsg: data.message, type: 'danger', isLoading: false })
                }).catch(err => {
                    this.setState({ errMsg: <FormattedMessage id='db.changepassword.error.update.failed' />, type: 'danger', isLoading: false })
                })
        })
    }

    handleLogOutUser = () => {
        this.setState({ isLoading: true }, () => {
            logout()
                .then(data => {
                    var url = `${window.location.href.split("/amplemanage")[0]}/amplemanage/login`;
                    window.location.href = url;
                    this.setState({ isLoading: false })
                }).catch(err => {
                    var url =`${window.location.href.split("/amplemanage")[0]}/amplemanage/login`;
                    window.location.href = url;
                    this.setState({ isLoading: false })
                })
        })
    }

    onBtnClickHandlera = (value) => {
        const { formError } = this.state;
        this.setState({ updateOrChange : 'update' })
        if (value === 'logout') this.handleLogOutUser()
        else if (value === 'cancel') this.closeConfigModal()
        else if (value === 'update') {
            let allErrMsg = this.handleValidateForm('update');
            this.setState({
                formError: allErrMsg,
                isClickedUpdate: true
            },()=>{
                if(this.state.formError.currentPassword === '' && this.state.formError.newPassword === '' && this.state.formError.confirmNewPassword === ''){
                    this.handleUpdatePassword();
                    this.setState({
                        isClickedUpdate: false
                    })
                }
            })
        }
    }

    handleValidateForm=(from)=>{
        const { formError, formData, fieldName } = this.state;
        let currentPasswordErrMsg = formError.currentPasswordErrMsg;
        let newPasswordErrMsg = formError.newPasswordErrMsg;
        let confirmNewPasswordErrMsg = formError.confirmNewPasswordErrMsg;
            if(formData.currentPassword === ''){
                currentPasswordErrMsg = <FormattedMessage id='db.changepassword.error.oldPassword.invalid' />
            } else{
                currentPasswordErrMsg = ''
            }

            if(formData.newPassword === ''){
                newPasswordErrMsg = <FormattedMessage id='resetpassword.newpwd.required' />
            } else if(formData.newPassword.length <= 7 || formData.newPassword.length >= 20 ){
                newPasswordErrMsg = 'Password should be between 8 to 20 characters.'
            } else if(formData.newPassword.length >= 7 && formData.newPassword.length <= 20){
                let passwordRegex = /^(?=.*[a-z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
                if(!passwordRegex.test(formData.newPassword)){
                    newPasswordErrMsg = <FormattedMessage id='resetpassword.splcharacter' />
                } else {
                    newPasswordErrMsg = ''
                }
            } else {
                newPasswordErrMsg = ''
            }

            if(formData.confirmNewPassword === "" ){
                confirmNewPasswordErrMsg = <FormattedMessage id='resetpassword.confirmpwd.required' />
            } else if(fieldName === 'confirmNewPassword' || from === 'update' || confirmNewPasswordErrMsg !== '' ){
                if( newPasswordErrMsg === "" && formData.confirmNewPassword !=="" && formData.newPassword !== formData.confirmNewPassword ){
                    confirmNewPasswordErrMsg = <FormattedMessage id='db.changepassword.error.confirmNewPassword.match' />
                    newPasswordErrMsg = true
                } else{
                    confirmNewPasswordErrMsg = ''
                }
            }
            return {currentPassword: currentPasswordErrMsg, newPassword: newPasswordErrMsg, confirmNewPassword: confirmNewPasswordErrMsg}
    }

    render() {
        return (
            <Row>
                <Col>
                    {changePasswordData.isOpenChangePasswordeModal ?
                        <ModalWindow
                            show={this.state.isOpenModal}
                            size="lg"
                            dialogClassName={"userModal changePasswordModal"}
                            title={<span><FormattedMessage id='nav.main.changepassword' /></span>}
                            onBtnClickHandler={this.onBtnClickHandlera}
                            onHide={this.closeConfigModal}
                            modeldata={{
                                content: <React.Fragment>
                                    {this.state.isLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                                    {this.state.errMsg !== "" ? <div><AlertMessage type={this.state.type} message={this.state.errMsg} position={'w-100'}/><br/></div> : ""}
                                    {this.state.type === 'success' ? ""
                                        : <Row>
                                            <Col>
                                                {this.state.formFields.map((item, key) => { 
                                                    return <Form.Row key={key} className='pb-3'>
                                                        <Col>
                                                            <Row className='inline-center-item'>
                                                                <Col xs={5} className="text-right">
                                                                    <span className={this.state.formError[item.value] !== "" ? "form-err-color-red" : ""}><b>{item.label}{item.isMandatory ?'*': ""} </b>:</span></Col>
                                                                <Col xs={7} className="form-right">

                                                                    <Form>
                                                                        <FormattedMessage id={item.placeholder}>
                                                                            {placeholder=>  
                                                                                <FormControl
                                                                                    type={item.type}
                                                                                    placeholder={placeholder}
                                                                                    onChange={(e)=>this.handleOnchangeForm(e.target.value, item.value)}
                                                                                    size={'sm'}
                                                                                    className={this.state.formError[item.value] !== ""  ? "form-err-border-red" : ""}
                                                                                />
                                                                            }
                                                                        </FormattedMessage>
                                                                    </Form>

                                                                </Col>
                                                            </Row>
                                                            <Row className="text-right">
                                                                <Col>
                                                                    {this.state.formError[item.value] !== "" ? <div style={{ color: '#a94442', fontSize: '1.4vh' }}> {this.state.formError[item.value]} </div> : ""} 
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Form.Row>
                                                })}
                                            </Col>
                                        </Row>}
                                </React.Fragment>
                            }}

                            footer={
                                this.state.type === 'success' ?
                                    [{ className: "modalSaveBtn", name: <FormattedMessage id='db.common.ok' />, value: "logout" }]
                                    : [
                                        { className: "modalSaveBtn", name: <FormattedMessage id='db.common.update' />, value: "update" }
                                    ]
                            }
                        />
                        : ""}
                </Col>
            </Row>
        );
    }
}

export default ChangePasswordContainer;