import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import * as treeViewUtility from '../utility/treeViewUtility';
import { getWatchlistSubstationsAndFeeder, getWatchlistSubstations } from '../mainContent/dashboard/services/dashboardService';
import { getAlertRegionData, getAlertSubstations } from '../mainContent/emailSubscription/services/emailSubscriptionService';
import { getVdTreeData } from '../mainContent/manage/virtualDeviceManagement/services/virtualDeviceService';
import { getTreeData } from '../treeview/services/treeService';
import Loader from './loader';
import 'react-sortable-tree/style.css';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BlankPage from './blankPage'

class TreeViewWithCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            treeData: []
        };
        this.payload = { 'ROOTNODE': '', 'REGION': '', 'SUBSTATION': '' };
    }

    componentDidMount() {
        if(this.props.isAvailaTreeData || this.props.isAvailaTreeData?.length === 0 ) this.getNodeData();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.hasOwnProperty('needToPersistence')) { // force to reload
            if((this.props.needToPersistence !== '') && (this.props.needToPersistence !== nextProps.needToPersistence)) {
                this.getNodeData();
            }
        }
    }

    getNodeData = () => {
        switch (this.props.from) {
            case 'dashboard': 
                this.getWatchlistdata()
            break;
            case 'emailSubscription': 
                this.getSubscribedRegionData()
            break;
            case 'vertualDeviceManagement': 
                this.getVirtualDeviceRegionData();
            break; 

            default:
                break;
        }

    }

    getWatchlistdata = () => {
        this.setState({ isLoding: true }, () => {
            getWatchlistSubstations({ 'ORGID': this.props.tree[0].id, apiType: "data" })
                .then(resData => {
                    if (resData && resData.length) this.setState({ treeData: this.updateResponseData(resData), isLoding: false },()=>{
                        this.updateCheckedData(this.state.treeData, this.props.checkedLableName, this.props.expandUpto )
                    })
                }).catch((err) => err)
        })
    }

    getSubscribedRegionData = () => {
        this.setState({ isLoding: true }, () => {
            getAlertRegionData({ 'ORGID': this.props.tree[0].id, 'apiType': 'data' }, 'selectedregions')
                .then(resData => {
                    if (resData && resData.length) this.setState({ treeData: this.updateResponseData(resData), isLoding: false })
                }).catch((err) => err)
        })
    }

    getVirtualDeviceRegionData=()=>{
        this.setState({ isLoding: true, treeData: [] }, () => {
            getVdTreeData({'ROOTNODE': 'rootnode', 'apiType' : 'rootnode'}, this.props.clickedType, this.props.rowdata?.id)
                .then(resData => {                    
                    if (resData && resData.length) {
                        let filterResData = this.props.isDisabledCheckBox ? resData.filter((item)=> item.subscribedForVirtualDevice || item.selectionIntermediate ) : resData;
                            this.setState({ isLoding: false, treeData: this.updateResponseData(filterResData, 'ROOT')}, ()=>{
                            this.props.clickedType === 'Edit' && this.updateCheckedData(this.state.treeData, this.props.checkedLableName, this.props.expandUpto)
                        });
                    }
                    this.setState({isLoding: false})
                }).catch((err) => {
                    this.setState({
                        isLoding: false
                    })
                })
        })
    }


    getAllRegionData = () => {
        this.setState({ isLoding: true, treeData: [] }, () => {
            getTreeData({ 'ROOTNODE': this.props.tree[0].id, 'apiType': 'ROOTNODE' }, 'nodes', '')
                .then(resData => {
                    if(this.props.from === "reports/custom") resData.forEach(e => e.subscribedForWatchlist =true);
                    if (resData && resData.length)  this.setState({ treeData: this.updateResponseData(resData, 'ROOT'), isLoding: false });
                        
                }).catch((err) => {
                    this.setState({
                        isLoding: false
                    })
                })
        })
    }

    updateTempId = (payloadData, item, key) => {
        let uniqKey = '';
        if (payloadData.REGION === "") uniqKey = item.name + key
        switch (payloadData.apiType) {
            case 'REGION': 
                uniqKey = payloadData.REGION + item.name + key
            break;
            case 'SUBSTATION': 
                uniqKey = payloadData.REGION + payloadData.SUBSTATION + item.name + key
            break;
            case 'FEEDER': 
                uniqKey = payloadData.REGION + payloadData.SUBSTATION + payloadData.FEEDER + item.name + key
            break; 
            case 'LATERAL': 
                uniqKey = payloadData.REGION + payloadData.SUBSTATION + payloadData.FEEDER + payloadData.SITE + item.name + key
            break; 

            default:
                break;
        }

        return uniqKey
    }

    updateResponseData = (resData, clickedInfo) => {
        let data = resData.sort((a, b) => a.name.localeCompare(b.name));
            data.forEach((item, key) => {
                item.title = item.deleted ?
                    <span>{(item.name)}<span className='label-deleted'>(<FormattedMessage id='vdm.treeview.label.deleted' />)</span></span> //if deleted true then need to display (deleted)
                    : item.name;  
                item.expanded = false;
                item.tempId = item.tempId = this.updateTempId(this.payload, item, key);
                item.routeParams= this.updateRouteParams(item, clickedInfo);
            })
            return data
    }

    updateRouteParams(item, clickedInfo){
        if(clickedInfo === 'ROOT') return {region: item.name}
        
        let rParams = {};
        switch (clickedInfo.node.type) {
            case 'REGION': 
                rParams = {region: clickedInfo.node.routeParams.region, substation: item.name}
            break; 
            
            case 'SUBSTATION': 
                rParams = {region: clickedInfo.node.routeParams.region, substation: clickedInfo.node.routeParams.substation, feeder: item.name}
            break; 

            case 'FEEDER': 
                rParams = {region: clickedInfo.node.routeParams.region, substation: clickedInfo.node.routeParams.substation, feeder: clickedInfo.node.routeParams.feeder, site: item.name}
            break; 

            case 'LATERAL': 
                rParams = {region: clickedInfo.node.routeParams.region, substation: clickedInfo.node.routeParams.substation, feeder: clickedInfo.node.routeParams.feeder, site: clickedInfo.node.routeParams.site, lateral: item.name }
            break; 

            default:
                break;
        }

        return rParams
    }

    generatePayload = (rowInfo, e) => {
        
        switch (rowInfo.node.type) {
            case 'REGION': 
                this.payload = { 'ROOTNODE': this.props.tree[0].id, 'REGION': rowInfo.node.routeParams.region,'apiType' :rowInfo.node.type}
            break; 

            case 'SUBSTATION': 
                this.payload = { 'ROOTNODE': this.props.tree[0].id, 'REGION': rowInfo.node.routeParams.region, 'SUBSTATION': rowInfo.node.routeParams.substation ,'apiType' :rowInfo.node.type}
            break; 

            case 'FEEDER': 
                this.payload = { 'ROOTNODE': this.props.tree[0].id, 'REGION': rowInfo.node.routeParams.region, 'SUBSTATION': rowInfo.node.routeParams.substation, 'FEEDER': rowInfo.node.routeParams.feeder, 'apiType' :rowInfo.node.type}
            break; 

            case 'LATERAL': 
                this.payload = { 'ROOTNODE': this.props.tree[0].id, 'REGION': rowInfo.node.routeParams.region, 'SUBSTATION': rowInfo.node.routeParams.substation, 'FEEDER': rowInfo.node.routeParams.feeder, "LATERAL": rowInfo.node.routeParams.site, 'apiType' :rowInfo.node.type}
            break; 
        
        }
    }

    handleClickOnItem = (rowInfo, event) => {
        
        let checked = event.target.checked;
        this.setState({ isLoding: true }, () => {
            if (!rowInfo.node.children && rowInfo.node.type !== this.props.expandUpto) {
                this.generatePayload(rowInfo, event);
                if (this.props.from === 'dashboard') {
                    getWatchlistSubstationsAndFeeder(this.payload, rowInfo.node.type)
                    .then(resData => {
                        rowInfo.node.type === "SITE" ? resData = [] : resData;
                        let data = resData && resData.length ? this.updateResponseData(resData) : []
                        this.showOrHideChildrenData(rowInfo, checked, data);
                    })
                } else if (this.props.from === 'emailSubscription') {
                    getAlertSubstations(this.payload, rowInfo.node.type)
                        .then(resData => {
                            rowInfo.node.type === "SITE" ? resData = [] : resData;
                            let data = resData && resData.length ? this.updateResponseData(resData) : []
                            this.showOrHideChildrenData(rowInfo, checked, data);
                        })
                } else if (this.props.from === 'vertualDeviceManagement') {
                    getVdTreeData(this.payload, this.props.clickedType, this.props.rowdata?.id )
                    .then(resData => {                        
                        rowInfo.node.type === "SITE" ? resData = [] : resData;
                        let data = resData && resData.length ? this.updateResponseData(resData, rowInfo) : [];
                        this.showOrHideChildrenData(rowInfo, checked, data);
                    })
                } else {
                    if(rowInfo.node.type !== "SITE" ){
                        getTreeData(this.payload, 'nodes', rowInfo.node.type)
                        .then(resData => {
                            if(this.props.from === "reports/custom") resData.forEach(e => e.subscribedForWatchlist =true);
                            
                            rowInfo.node.type === "SITE" ? resData = [] : resData;
                            let data = resData && resData.length ? this.updateResponseData(resData, rowInfo) : [];
                            
                            this.showOrHideChildrenData(rowInfo, checked, data);
                        })
                    } else {
                        this.showOrHideChildrenData(rowInfo, checked);
                    }
                }    
            } else {
                this.showOrHideChildrenData(rowInfo, checked);
            }
        })

    }

    updateSubscribedForWatchlist=(data)=>{

        let checkifAllAnyTrue = data.filter(item => item.selectionIntermediate === true);
        let checkifAllSubscTrue = data.filter(item => item.selectionIntermediate === false && item[this.props.checkedLableName] === true)
        let intermediate = {};
        if (checkifAllAnyTrue.length > 0) {
            intermediate.selectionIntermediate = true;
            intermediate[this.props.checkedLableName] = false;
        }
        if (checkifAllSubscTrue.length === data.length) {
            intermediate.selectionIntermediate = false;
            intermediate[this.props.checkedLableName] = true;
        }
        if (checkifAllSubscTrue.length < data.length) {
            intermediate.selectionIntermediate = true;
            intermediate[this.props.checkedLableName] = false;
        }
        if (checkifAllAnyTrue.length === 0 && checkifAllSubscTrue.length === 0) {
            intermediate.selectionIntermediate = false;
            intermediate[this.props.checkedLableName] = false;
        }
        return intermediate
    }

    showOrHideChildrenData=(rowInfo, checked, resData)=>{
        let prevData = this.props.isAvailaTreeData && this.props.isAvailaTreeData.length>0 ? this.props.isAvailaTreeData : this.state.treeData;

        let checkedValue = checked;
        prevData.forEach((eachRegion) => {
            if (eachRegion.tempId === rowInfo.node.tempId) {
                if (resData !== undefined) eachRegion.children = resData; // if data coming from response then assign it to children
                eachRegion.expanded = checkedValue !== undefined ? true : !eachRegion.expanded; // clicked from checkbox always axpand true or clicked from icon do it true/false
                
                if(checkedValue !== undefined ) {
                    eachRegion[this.props.checkedLableName] = checkedValue;
                    eachRegion.selectionIntermediate = false
                }

            }
            if(eachRegion.children){
                eachRegion.children.forEach((eachSubStation, itemsKey, subStationArr)=>{
                    
                    if(eachRegion[this.props.checkedLableName] && !eachRegion.selectionIntermediate ) {
                        eachSubStation[this.props.checkedLableName] = true;
                        eachSubStation.selectionIntermediate = false;
                    } else if(!eachRegion[this.props.checkedLableName] && !eachRegion.selectionIntermediate){
                        eachSubStation[this.props.checkedLableName] = false;
                        eachSubStation.selectionIntermediate = false;
                    }

                    if (eachSubStation.tempId === rowInfo.node.tempId) {
                        if(checkedValue !== undefined ) {
                            eachSubStation[this.props.checkedLableName] = checkedValue;
                            eachSubStation.selectionIntermediate = false;
                            
                            eachRegion.selectionIntermediate = this.updateSubscribedForWatchlist(subStationArr).selectionIntermediate;
                            eachRegion[this.props.checkedLableName] = this.updateSubscribedForWatchlist(subStationArr)[this.props.checkedLableName];
                        }

                        if (resData !== undefined) eachSubStation.children = resData; // if data coming from response then assign it to children
                        eachSubStation.expanded = checkedValue !== undefined ? true : !eachSubStation.expanded; // clicked from checkbox always axpand true or clicked from icon do it true/false
                    }
                    
                    if(eachSubStation.children){
                        eachSubStation.children.forEach((eachFeeder, subItemKey, feederArr)=>{
                            

                            if(eachSubStation[this.props.checkedLableName] && !eachSubStation.selectionIntermediate ) {
                                eachFeeder[this.props.checkedLableName] = true;
                                eachFeeder.selectionIntermediate = false;
                            } else if(!eachSubStation[this.props.checkedLableName] && !eachSubStation.selectionIntermediate){
                                eachFeeder[this.props.checkedLableName] = false;
                                eachFeeder.selectionIntermediate = false;
                            }


                            if (eachFeeder.tempId === rowInfo.node.tempId) {

                                if(checkedValue !== undefined ) {
                                    eachFeeder[this.props.checkedLableName] = checkedValue;
                                    eachFeeder.selectionIntermediate = false;

                                    eachSubStation.selectionIntermediate = this.updateSubscribedForWatchlist(feederArr).selectionIntermediate;
                                    eachSubStation[this.props.checkedLableName] = this.updateSubscribedForWatchlist(feederArr)[this.props.checkedLableName];

                                    eachRegion.selectionIntermediate = this.updateSubscribedForWatchlist(subStationArr).selectionIntermediate;
                                    eachRegion[this.props.checkedLableName] = this.updateSubscribedForWatchlist(subStationArr)[this.props.checkedLableName];
                                }

                                if (resData !== undefined) eachFeeder.children = resData; // if data coming from response then assign it to children
                                eachFeeder.expanded = checkedValue !== undefined ? true : !eachFeeder.expanded; // clicked from checkbox always axpand true or clicked from icon do it true/false
                            }

                            if(eachFeeder.children){    //if feeder have children data
                                eachFeeder.children.forEach((eachSite, subItemssKey, siteArr)=>{

                                    if(eachFeeder[this.props.checkedLableName] && !eachFeeder.selectionIntermediate ) {
                                        eachSite[this.props.checkedLableName] = true;
                                        eachSite.selectionIntermediate = false;
                                    } else if(!eachFeeder[this.props.checkedLableName] && !eachFeeder.selectionIntermediate){
                                        eachSite[this.props.checkedLableName] = false;
                                        eachSite.selectionIntermediate = false;
                                    }


                                    if (eachSite.tempId === rowInfo.node.tempId){
                                        if(checkedValue !== undefined ) {
                                            eachSite[this.props.checkedLableName] = checkedValue;
                                            eachSite.selectionIntermediate = false;

                                            eachFeeder.selectionIntermediate = this.updateSubscribedForWatchlist(siteArr).selectionIntermediate;
                                            eachFeeder[this.props.checkedLableName] = this.updateSubscribedForWatchlist(siteArr)[this.props.checkedLableName];

                                            eachSubStation.selectionIntermediate = this.updateSubscribedForWatchlist(feederArr).selectionIntermediate;
                                            eachSubStation[this.props.checkedLableName] = this.updateSubscribedForWatchlist(feederArr)[this.props.checkedLableName];

                                            eachRegion.selectionIntermediate = this.updateSubscribedForWatchlist(subStationArr).selectionIntermediate;
                                            eachRegion[this.props.checkedLableName] = this.updateSubscribedForWatchlist(subStationArr)[this.props.checkedLableName];
                                        }

                                        if (resData !== undefined) eachSite.children = resData; // if data coming from response then assign it to children
                                        eachSite.expanded = checkedValue !== undefined ? true : !eachSite.expanded; // clicked from checkbox always axpand true or clicked from icon do it true/false
                                    }

                                    if(eachSite.children && eachSite.type === 'LATERAL' ){    //if feeder have children data
                                        eachSite.children.forEach((eachLateral, subItemssKey, lateralArr)=>{
        
                                            if(eachSite[this.props.checkedLableName] && !eachSite.selectionIntermediate ) {
                                                eachLateral[this.props.checkedLableName] = true;
                                                eachLateral.selectionIntermediate = false;
                                            } else if(!eachSite[this.props.checkedLableName] && !eachSite.selectionIntermediate){
                                                eachLateral[this.props.checkedLableName] = false;
                                                eachLateral.selectionIntermediate = false;
                                            }
        
        
                                            if (eachLateral.tempId === rowInfo.node.tempId){
                                                if(checkedValue !== undefined ) {
                                                    eachLateral[this.props.checkedLableName] = checkedValue;
                                                    eachLateral.selectionIntermediate = false;

                                                    eachSite.selectionIntermediate = this.updateSubscribedForWatchlist(lateralArr).selectionIntermediate;
                                                    eachSite[this.props.checkedLableName] = this.updateSubscribedForWatchlist(lateralArr)[this.props.checkedLableName];
        
                                                    eachFeeder.selectionIntermediate = this.updateSubscribedForWatchlist(siteArr).selectionIntermediate;
                                                    eachFeeder[this.props.checkedLableName] = this.updateSubscribedForWatchlist(siteArr)[this.props.checkedLableName];
        
                                                    eachSubStation.selectionIntermediate = this.updateSubscribedForWatchlist(feederArr).selectionIntermediate;
                                                    eachSubStation[this.props.checkedLableName] = this.updateSubscribedForWatchlist(feederArr)[this.props.checkedLableName];
        
                                                    eachRegion.selectionIntermediate = this.updateSubscribedForWatchlist(subStationArr).selectionIntermediate;
                                                    eachRegion[this.props.checkedLableName] = this.updateSubscribedForWatchlist(subStationArr)[this.props.checkedLableName];
                                                }
                                            }
                                        })
                                    }

                                    // Add a new step here : if(eachSite.children.length > 0 ){ copy and paste feeder children data and change accrding to new step value }

                                })
                            }
                        })
                    }   
                })
            }
        })

        this.setState({ treeData: prevData, isLoding: false }); 
        if (checkedValue !== undefined) this.updateCheckedData(prevData, this.props.checkedLableName, this.props.expandUpto )
        
    }

    updateCheckedData = (allData, checkedLableName, expandUpto ) => {
        var data = [];
        allData.forEach((region)=>{
            var substations = [];
            var regChildren = region.hasOwnProperty("children") ? region.children : [];

            if( regChildren.length > 0){
                regChildren.forEach((subobj)=>{

                    var feeders = [];
                    var feedChildren = subobj.hasOwnProperty("children") ? subobj.children : [];
                    
                    if( feedChildren.length > 0){
                        feedChildren.forEach((feedobj)=>{

                            var sites = [];
                            var siteChildren = feedobj.hasOwnProperty("children") ? feedobj.children : [];
                            if( siteChildren.length > 0){
                                siteChildren.forEach((siteobj)=>{

                                    var lateralSites = [];
                                    var lateralChildren = siteobj.hasOwnProperty("children") ? siteobj.children : [];

                                    if( lateralChildren.length > 0){
                                        lateralChildren.forEach((lateraleobj)=>{
                                            lateralSites.push({
                                                "name": lateraleobj.name,
                                                "selected": lateraleobj.checked === true || lateraleobj.selectionIntermediate === true || (lateraleobj.selectionIntermediate === false && lateraleobj[checkedLableName] === true) ? true : false,
                                                "deleted": lateraleobj.deleted,
                                                "id": lateraleobj.id
                                            })
                                        })
                                    }
                                    
                                    sites.push({
                                        "nodeName": siteobj.name,
                                        "nodeSelected": siteobj.checked === true || siteobj.selectionIntermediate === true || (siteobj.selectionIntermediate === false && siteobj[checkedLableName] === true) ? true : false,
                                        "nodeId": siteobj.id,
                                        "type": siteobj.type,
                                        "deleted": siteobj.deleted,
                                        'lateralSites': lateralSites,
                                        parcialSelected:  siteobj.selectionIntermediate === true ? true : false
                                    })
                                })
                            }

                            feeders.push({
                                "feederName": feedobj.name,
                                "feederSelected": feedobj.checked === true || feedobj.selectionIntermediate === true || (feedobj.selectionIntermediate === false && feedobj[checkedLableName] === true) ? true : false,
                                "feederId": feedobj.id,
                                "deleted": feedobj.deleted,
                                'childs' : sites
                            })
                        })
                    }

                    substations.push({
                        "substationName": subobj.name,
                        "substationSelected": subobj.checked === true || subobj.selectionIntermediate === true || (subobj.selectionIntermediate === false && subobj[checkedLableName] === true) ? true : false,
                        "substationId": subobj.id,
                        "deleted": subobj.deleted,
                        'feeders' : feeders
                    })
                })
            }

            data.push({
                "regionName": region.name,
                "regionSelected": region.checked === true || region.selectionIntermediate === true || (region.selectionIntermediate === false && region[checkedLableName] === true) ? true : false,
                "deleted": region.deleted,
                "substations": substations
            })
        })
    this.props.passUpdatedData(data, this.state.treeData)
    }

    indetSetter = (checked, el) => {
        if (el) el.indeterminate = checked
    }

    render() {
        const { expandUpto, checkedLableName, checkBoxVisible, isDisabledCheckBox } = this.props;
        return (
            <div className="checkbox-tree" style={{ height: this.props.clickedType === 'View' && '60vh'}}>
                { this.state.isLoding ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                {
                    (this.state.treeData && this.state.treeData.length >0 ) || (this.props.isAvailaTreeData && this.props.isAvailaTreeData.length >0 ) ?
                    <SortableTree
                        treeData={ this.props.isAvailaTreeData === undefined || this.props.isAvailaTreeData?.length === 0 ? this.state.treeData.map(item => item) : (isDisabledCheckBox ? this.props.isAvailaTreeData.filter((item)=> item.subscribedForVirtualDevice || item.selectionIntermediate ) : this.props.isAvailaTreeData.map(item => item))}
                        onChange={treeData => this.setState({ treeData })}
                        theme={FileExplorerTheme}
                        canDrag={false}
                        generateNodeProps={clickedNode => ({
                            onClick: (event) => clickedNode.node.type !== 'SITE' && this.handleClickOnItem(clickedNode, event),
                            icons: [
                                <div className="container-fluid tree-wth-checkbox">
                                    <span className="watchListItemPlusMinus" >
                                       
                                        {clickedNode.node.type !== expandUpto && (clickedNode.node.type !== 'SITE') ? <FontAwesomeIcon className="cursor-pointer" icon={clickedNode.node.expanded ? faMinus : faPlus} size="sm" /> : ""}
                                    </span>
                                    <span className={ clickedNode.node.type === 'SITE' ? "watchListItemIconSite" : "watchListItemIcon"}> {treeViewUtility.getIcon(clickedNode)}</span>
                                    {
                                        ((checkBoxVisible === 'all') || (checkBoxVisible === clickedNode.node.type)) ?
                                        <span className="checkboxList">
                                            <input type="checkbox"
                                                checked={(clickedNode.node[checkedLableName] === true && clickedNode.node.selectionIntermediate === false) || (clickedNode.node[checkedLableName] === false && clickedNode.node.selectionIntermediate === true) ? true : false}
                                                onClick={e => e.stopPropagation()}
                                                ref={this.indetSetter.bind(this, clickedNode.node.selectionIntermediate)}
                                                disabled={this.props.isDisabledCheckBox ? true : false }
                                                onChange={(event) => this.handleClickOnItem(clickedNode, event)}
                                            />
                                        </span> : null
                                    }
                                </div>
                            ],
                        })}
                    /> : !this.state.isLoding && <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} />
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tree: state.treeviewData.tree,
    };
}

TreeViewWithCheckbox.propTypes = {
    passUpdatedData: PropTypes.func,    //oncheck receive the selected data
    expandUpto: PropTypes.string,   //  Like expand upto SUBSTATION, FEEDER, SITE level
    checkedLableName: PropTypes.string, // to update the checkbox checked or not based on this props name
    from: PropTypes.string, // spesifc condition for spesific page
    checkBoxVisible: PropTypes.string // for checkbox
}

export default connect(mapStateToProps, {})(TreeViewWithCheckbox);

//notes: for a new step add
//No1: update payload data for API (generatePayload()) 
//No2: inside the updateTempId function add one more condition to create uniqKey like - : else if(payloadData.apiType === "SITE") uniqKey = payloadData.REGION + payloadData.SUBSTATION + payloadData.FEEDER + payloadData.SITE + item.name + key
//No3: in showOrHideChildrenData function add one more step at last and update the correspinding data.
//No4: if required update the updateCheckedData()