import { getURL } from '../../../providers/configProvider';
import { requestGET } from '../../../services/extendedService';

export const getCountersData = (params) => {
  const url = getURL('disturbances', 'countersdata', params );
  return requestGET(url);
};

export const exportData = (params) => {
  const url = getURL('disturbances', 'exportData', params );
  return requestGET(url);
};