import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import Tabs from '../../../hoc/tabs';
import { userMngmntData } from '../../../utility/userManagementUtility';
import Loader from '../../../hoc/loader';
import { createNeworUpdateUser, deleteUsers, removeEMSessionOfDelUsers, userStateOrresetPassword, getAllRoleList, getAllTimeZoleList } from '../services/userService';
import AlertMessage from '../../../hoc/alert';
import ModalForm from '../components/modalForm';
import { UserContext } from '../provider/userProvider';
import { FormattedMessage } from 'react-intl';
import ModalWindow from '../../../hoc/modelWindow';
import { emailRegex } from '../../../filters/commonFunction';

class UserModal extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            currentTab: userMngmntData.currentTab,
            errMsg: "",
            isLoading: false,
            generalData: userMngmntData.formFields.general,
            preferencesData: userMngmntData.formFields.preferences,
        }
    }

    componentDidMount() {
        let roleNameData = this.state.generalData.find(item => item.value === 'role');  //finding role field from generalData ( all the form feilds )
        let timezoneData = this.state.preferencesData.find(item => item.value === 'timezone');  //finding timeZone field from generalData ( all the form feilds )
        if (roleNameData.options.length === 0 && (this.context.state.addOrEdit === 'add' || this.context.state.addOrEdit === 'edit')) this.getRoleList();      // only calling this api if role data is not available and it works on edit or add button
        if (timezoneData.options.length === 0 && (this.context.state.addOrEdit === 'add' || this.context.state.addOrEdit === 'edit')) this.getTimeZoneList();   // only calling this api if role data is not available and it works on edit or add button
    }

    handleTabSelect = crntVal => this.setState({ currentTab: crntVal })

    getRoleList = () => {
        getAllRoleList()
            .then(data => {
                let newData = data.length !== 0 ? data : [];
                let tmpArr = [];
                newData.map(item => tmpArr.push({ label: item.name, value: item.id }));
                this.updateResponseData('generalData', tmpArr, 'role')
            }).catch(error => {
            })
    }

    getTimeZoneList = () => {
        getAllTimeZoleList()
            .then(data => {
                let newData = data.length !== 0 ? data : [];
                let tmpArr = [];
                newData.map(item => tmpArr.push({ label: item, value: item }));   //structuing response data like [{label:"", value:""}]
                this.updateResponseData('preferencesData', tmpArr, 'timezone')
            }).catch(error => {
            })
    }

    updateResponseData = (stateName, tmpArr, field) => {   //appending tmpArr ([{label:"", value:""}]) into select options field
        this.state[stateName].find(item => item.value === field).options = tmpArr;
        this.setState({ [stateName]: this.state[stateName] })
    }

    handleOnchangeForm = async (value, field) => {
        await this.context.handleOnchangeForm(value, field)
        let checkAllValidate = this.onChangeValidation(field, this.context.state.formData, this.state.generalData)
        this.setState({ generalData: checkAllValidate })
    }

    onChangeValidation = (field, formData, generalData) => {        
        //eslint-disable-next-line
        let passwordRegex = /^(?=.*[a-z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        let confirmPassword = formData.newPassword === formData.confirmPassword;

        let value = null;
        if (field === 'email') value = this.testValidation(emailRegex, formData.email)
        else if (field === 'newPassword') value = this.testValidation(passwordRegex, formData.newPassword)
        else if (field === 'confirmPassword') value = !confirmPassword

        const updateIsErrData = generalData.map(item => {
            if (item.value === field) return { ...item, isErr: value }
            else return item
        })
        return updateIsErrData
    }

    testValidation = (regex, value) => {
        return !regex.test(value)
    }

    handleAddOrUpdateUser = (addOrEdit) => {
        this.setState({ isLoading: true })
        if (addOrEdit === 'delete') this.handleDeleteusers();   // if user clicked delete button
        else if (addOrEdit === 'userState' || addOrEdit === 'resetpassword') this.handleDisableorResetPassword(addOrEdit);  // if user clicked disable icon or reset icon on table row 
        else {      // if user clicked edit or add button
            let validationData = this.onClickCheckValidation(addOrEdit, this.context.state.formData, this.state.generalData) //before calling api checking all the form field error

            this.setState({ generalData: validationData }, () => {
                let findErrField = this.state.generalData.filter(item => item.isErr === true);
                if (findErrField.length !== 0) this.setState({ errMsg: this.updateErrorMessage(this.context.state.formData, findErrField), isLoading: false })
                else {  //if dont have any form error calling both user create and update API
                    let reqBodyData = { formData: this.context.state.formData, principalId: this.context.state.allChecked[0] }
                    createNeworUpdateUser(reqBodyData, addOrEdit)
                        .then(data => {
                            this.setState({ isLoading: false })
                            if (data.message === "Success" && data.status === "OK") this.context.getAllUsersList(); //on create or update response status is OK then passing a function to call updated userlist
                            else this.setState({ errMsg: data.message })
                        }).catch(error => {
                        })
                }
            })

        }
    }

    onClickCheckValidation = (addOrEdit, allFormData, generalData) => {
        let requiredValidateField = generalData.filter(item => item.isMandatory === true && item.type !== 'select').map(items => items.value);
        let filterRequiredField = addOrEdit === 'edit' ? requiredValidateField.filter(item => item !== 'userName') : requiredValidateField;
        let newdata = generalData.map(item => {
            if (item.isErr === undefined) {
                if (filterRequiredField.includes(item.value)) {
                    return { ...item, isErr: addOrEdit === 'edit' ? false : true }
                } else {
                    return { ...item, isErr: false }
                }
            } else {
                const blankField = Object.keys(Object.fromEntries(Object.entries(allFormData).filter(([key, value]) => (key === "email" && value === '') || (key === "newPassword" && value === '') || (key === "confirmPassword" && value === ''))))
                if (blankField.includes(item.value)) {
                    return { ...item, isErr: true }
                } else {
                    return { ...item, isErr: item.isErr }
                }
            }
        })
        return newdata
    }

    updateErrorMessage = (formData, errFields) => {
        let emailErrMsg = formData.email === "" ? <span><FormattedMessage id='db.common.email' /> <FormattedMessage id='db.common.isRequired' /> </span> : <FormattedMessage id='db.common.invalidEmailId' />;
        let passwordErrMsg = formData.newPassword === "" ? <span> <FormattedMessage id='db.common.password' /> <FormattedMessage id='db.common.isRequired' /> </span> : <FormattedMessage id='db.usrMngmnt.passwordValidation' />;
        let confirmPasswordErrMsg = formData.confirmPassword === "" ? <span><FormattedMessage id='db.common.confirmPassword' /> {<FormattedMessage id='db.common.isRequired' />} </span> : <FormattedMessage id='db.usrMngmnt.confirmPasswordValidation' />;

        let errMsg = "";
        if (errFields.length > 1) errMsg = <FormattedMessage id='db.common.pleaseCorrectTheErrors' />;
        else {
            if (errFields[0].value === 'email') errMsg = emailErrMsg;
            else if (errFields[0].value === 'newPassword') errMsg = passwordErrMsg;
            else if (errFields[0].value === 'confirmPassword') errMsg = confirmPasswordErrMsg;
        }
        return errMsg
    }

    handleDeleteusers = () => {
        this.setState({ isLoading: true })
        deleteUsers(this.context.state.allChecked)
            .then(data => {
                this.setState({ isLoading: false })
                if (data.message === "Success" && data.status === "OK") this.removeSession();
            }).catch(error => {
            })
    }

    removeSession = () => {
        this.setState({ isLoading: true })
        let userName = this.getUserIdToName(this.context.state.usersList, this.context.state.allChecked);
        removeEMSessionOfDelUsers(userName)
            .then(data => {
                this.setState({ isLoading: false })
                if (data === "OK") this.context.getAllUsersList();
            }).catch(error => {
            })
    }

    getUserIdToName = (allUsersList, selectedId) => {
        let tmpArr = [];
        allUsersList.forEach(item => {
            if (selectedId.includes(item.principalId)) {
                tmpArr.push(item.userName)
            }
        })
        return tmpArr
    }

    handleDisableorResetPassword = (addOrEdit) => {     //both userstate and reset password functionality api calling
        let params = { "principalId": this.context.state.rowData.principalId, 'apiType': 'data' };
        if (addOrEdit === 'userState') params = { ...params, ...{ "action": this.context.state.rowData.enabled === 'True' ? 0 : 1, } }
        this.setState({ isLoading: true })
        userStateOrresetPassword(params, addOrEdit)
            .then(data => {
                this.setState({ isLoading: false })
                if (data.message === "Success" && data.status === "OK") this.context.getAllUsersList();
                else this.setState({ errMsg: data.message })
            }).catch(error => {
            })
    }

    getModalText = (titleorFooter, addOrEdit, enabled) => {
        let title = ""
        if (addOrEdit === "add") title = titleorFooter === "title" ? <FormattedMessage id='db.common.createANewUser' /> : <FormattedMessage id='db.common.create' />;
        else if (addOrEdit === "edit") title = titleorFooter === "title" ? <FormattedMessage id='db.common.editUserDetails' /> : <FormattedMessage id='db.common.update' />;
        else if (addOrEdit === "userState" && enabled === 'False') title = <FormattedMessage id='db.common.enableUser' />;
        else if (addOrEdit === "userState" && enabled === 'True') title = <FormattedMessage id='db.common.disableUser' />;
        else if (addOrEdit === "resetpassword") title = titleorFooter === "title" ? <FormattedMessage id='db.common.resetPassword' /> : <FormattedMessage id='db.common.ok' />;
        else title = titleorFooter === "title" ? <FormattedMessage id='db.common.deleteConfirmation' /> : <FormattedMessage id='db.common.ok' />;
        return title;
    }

    getModalBodyText(addOrEdit, rowData) {
        let text = ""
        if (addOrEdit === "delete") text = <FormattedMessage id='db.common.doYouWantToDelete' />;
        else if (addOrEdit === "userState") text = <span><FormattedMessage id='db.usrMngmnt.doYouWantTo' /> {rowData.enabled === 'True' ? <FormattedMessage id='db.usrMngmnt.disable' /> : <FormattedMessage id='db.usrMngmnt.enable' />}  <FormattedMessage id='db.usrMngmnt.thisUser' /> </span>
        else text = <FormattedMessage id='db.common.forceToResetPassword' />;
        return text
    }

    onBtnClickHandlera=(value)=>{
        if (value === 'cancel') this.context.onCloseModal();
        else if(value === 'save' ) this.handleAddOrUpdateUser(this.context.state.addOrEdit)
    }

    render() {
        return (
            <Row>
                <ModalWindow
                    show={this.context.state.isOpenModal}
                    size="lg"
                    dialogClassName={"userModal modal-dialog-centered"}
                    title={this.getModalText('title', this.context.state.addOrEdit, this.context.state.rowData.enabled)}
                    onBtnClickHandler={this.onBtnClickHandlera}
                    onHide={() => this.context.onCloseModal()}
                    modeldata={{
                        content: <React.Fragment>
                            {this.state.isLoading ? <Loader elementClass="tree-ajax-loader center-element" /> : ""}
                            {this.state.errMsg !== "" ? <div className='alert-gap'><AlertMessage type={'danger'} message={this.state.errMsg} /></div>: ""}
                            {this.context.state.addOrEdit === 'delete' || this.context.state.addOrEdit === 'userState' || this.context.state.addOrEdit === 'resetpassword' ?
                                <div> {this.state.errMsg === "" ? this.getModalBodyText(this.context.state.addOrEdit, this.context.state.rowData) : ""}</div>
                                : <React.Fragment>
                                    <Tabs
                                        currentTab={this.state.currentTab}
                                        handleTabSelect={this.handleTabSelect}
                                        allTabs={userMngmntData.allTabs}
                                        tabBody={
                                            <ModalForm
                                                currentTab={this.state.currentTab}
                                                generalData={this.context.state.addOrEdit === 'edit' ? this.state.generalData.filter(item => item.type !== 'password') : this.state.generalData}
                                                preferencesData={this.state.preferencesData.filter(item => item.value !== "treeOrder")}
                                                formData={this.context.state.formData}
                                                handleOnchangeForm={this.handleOnchangeForm}
                                                addOrEdit={this.context.state.addOrEdit}
                                            />
                                        }
                                    />
                                </React.Fragment>}
                        </React.Fragment>
                    }}
                    footer={
                        (this.context.state.addOrEdit === 'delete' || this.context.state.addOrEdit === 'userState' || this.context.state.addOrEdit === 'resetpassword') && this.state.errMsg !== "" ?  
                        [
                            { className: "modalCancelBtn", name: <FormattedMessage id='dm.column.cancel' />, value: "cancel" }, 
                        ]
                        :
                        [
                            { className: "modalCancelBtn", name: <FormattedMessage id="dm.column.cancel" />, value: "cancel" }, 
                            { className: "modalSaveBtn", name: this.getModalText('footer', this.context.state.addOrEdit), value: "save" }
                        ]
                    }
                />
                
            </Row>
        );
    }
}

export default UserModal;