
class RestApi {
  constructor(){
    this.host = "";
    this.protocol = "";
    this.contextPath = "";
  }

  setRestApi(callback){
    var self = this;
    fetch('/amplemanage/apiconfig.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        return response.json();
      })
      .then(function(data) {
        self.host = data.host;
        self.contextPath= data.contextPath;
        self.protocol = data.protocol;
        callback()
      });
  }

  getRestApi(){
    return {
      host:this.host,
      protocol:this.protocol,
      contextPath:this.contextPath
    }
  }
}

export default new RestApi();
