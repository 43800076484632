import React, { Fragment } from 'react';
import Loader from '../../../../hoc/loader';
import { Row, Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl';
import NoDataAvailable from '../../../../hoc/noDataAvailableComponent';

const ClassAssignmentTabData = (props) => {
    let LoaderData = (props.isLoading) ? <div className="loader-wrap"><Loader elementClass="tree-ajax-loader center-element" /></div> : null
    return (
        <Fragment>
            {LoaderData}
            {!props.noDataAvailable ? <div className="allDeviceTabData">
                <Row className="section col-xs-12 col-12 p-0 m-0">
                    {Object.entries(props.tabClassAssignmentData).map(([field, val], i) => {
                        return (
                            <div key={i} className={"section-body col-xs-6 col-6 " + (i % 2 === 0 ? "pr-1 " : "pl-3 ") + (props.currentTab === "ZM1" && i > 1 ? "mt-3" : "")}>
                                <Row className="m-0 p-0"><FormattedMessage id={`manage.classAssignment.title.${field}`} /></Row>
                                <Row className="m-0 p-0 mt-2"><textarea rows={props.currentTab !== "ZM1" ? "15" : "6"} readOnly={field.toLowerCase().includes("ample") ? true : false} disabled={field.toLowerCase().includes("ample") ? true : false} className="form-control txtarea-wid" value={val} onChange={(e) => props.inputChangeHandler(e, field)} /></Row>
                            </div>)
                    })}
                </Row>
                {Object.keys(props.tabClassAssignmentData).length > 0 ?
                    <Row className="float-right p-0 m-0 mt-3 pr-3">
                        <Button className={"modalCancelBtn px-3 py-2" + (props.isBtnDisabled ? "disabled" : "")} disabled={props.isBtnDisabled} onClick={() => props.onSaveBtnClickHandler()}><FormattedMessage id='manage.classAssignment.btn.save' /> </Button>
                        <Button className="modalCancelBtn px-3 py-2 ml-1" onClick={() => props.onCancelBtnClickHandler()}><FormattedMessage id='manage.classAssignment.btn.cancel' /> </Button>
                    </Row> : ""}
            </div> : <NoDataAvailable />}
        </Fragment>
    )
}

export default ClassAssignmentTabData;