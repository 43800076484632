// highlight search match data
export const makeSearchHighlight = (str, query) => {
    const n = str.toString().toUpperCase();
    const q = query.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
        return str;
    }
    const l = q.length;
    return str.substr(0, x) + '<span class="makeHighlightColor">' + str.substr(x, l) + '</span>' + str.substr(x + l);
}

export const alphaNumericRegex = /^[a-zA-Z0-9\s]+$/i;  // Regex to accept alpha-numeric data

export const alphaNumericwithOptDeciRegex = /^[a-zA-Z0-9\s]+(\.\d+)*$/i;  // Regex to accept alpha-numeric data

export const numberOnlyRegex = /^[0-9]*$/; // Accept only Number

export const negativeNumberRegex = /^-?[0-9]\d*(\d+)?$/;    // Accept both positive and negative numbers

export const numWithOptDeciRgx = /^(0|[1-9]\d*)(\.\d+)?$/; // Accept only Number and decimal is optional

export const negativeNumOptRgx = /^[-]?[0-9]*$/;   // Accept only number and minus is optional

export const stringOnlyRegex = /^[A-Za-z ]+$/;   // Accept only strings with space

export const stringWithSplCharactersRegex = /^[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]*$/; // Accepts only string, space and special characters

export const ipAddressRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/    // max 255.255.255.255

export const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,})+$/    // Domain extension (.corp, .travel, .org etc) should be minimum 2

export const getCookie = (name) => {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
        let [k, v] = el.split('=');
        cookie[k.trim()] = v;
    })
    return cookie[name];
}
