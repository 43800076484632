import React, { Component } from 'react';
import { getNodeData, getFilterList, exportData, getDeviceData } from '../services/deviceService';
import * as deviceManagementUtility from '../../../../utility/deviceManagementUtility';
import { getBrowserTimeZone, sortFirmwareversions, getNodePath } from '../../../../services/utilityService';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";


export const DeviceContext = React.createContext();

class DeviceProvider extends Component {

  state = {
    treeNode: this.props.treeNode,
    devices: [],
    loaded: false,
    dataObject: null,
    filters: null,
    column: null,
    totalDevicesCount: null,
    loading: false,
    enableFilterBtn: false,
    selectAllCb: false,
    options: {
      ...deviceManagementUtility.tableOptions,
      /* tableColumns: [
        {
          Header: (<input type="checkbox" onChange={(e) => this.handleSelectAll(e)} />),
          accessor: "checkbox",
          Cell: row => (<input type="checkbox" onChange={(e) => this.onChangeHandler(e, row)} checked={deviceManagementUtility.tableOptions.selectedRows.includes(row.original) ? true : false} />),
          maxWidth: 45,
          sortable: false,
          filterable: false
        },
        ...deviceManagementUtility.tableOptions.tableColumns,
      ] */
    },
    summaryData: {},
    selectedPage: this.props.selectedPage,
    roleAndPermission: this.props.roleAndPermission,
    deviceDetailsData: {},
    displayTabByRes: [],
    rowDetails: {},
    isLodingDeviceDetailsData: false,
    displayColumns: deviceManagementUtility.displayColumns.data
  }

  componentWillUnmount() {
    deviceManagementUtility.tableOptions.selectedRows = [];
    deviceManagementUtility.tableOptions.pageNo = 1;
    deviceManagementUtility.tableOptions.pageSize = 100;
    deviceManagementUtility.tableOptions.customFilterColumns.forEach(column => column.items = []);
    this.props.dashboardFlag && this.props.setDashboardFlag()
  }

  handleSelectAll = (e) => {
    deviceManagementUtility.tableOptions.selectedRows = [];
    if (e.target.checked) {
      this.state.devices.filter(device => deviceManagementUtility.tableOptions.selectedRows.push(device))
    }
    this.setState({
      options: this.state.options,
      selectAllCb: (this.state.devices.length === deviceManagementUtility.tableOptions.selectedRows.length) ? true : false
    });
  }

  onChangeHandler = (e, row) => {
    if (e.target.checked) {
      deviceManagementUtility.tableOptions.selectedRows.push(row.original);
    }
    else {
      deviceManagementUtility.tableOptions.selectedRows.splice(deviceManagementUtility.tableOptions.selectedRows.indexOf(row.original), 1)
    }
    this.setState({
      options: this.state.options,
      selectAllCb: (this.state.devices.length === deviceManagementUtility.tableOptions.selectedRows.length) ? true : false
    });
  }

  fetchDeviceList = async (treeNode, bool) => {
    if (!treeNode) {
      treeNode = this.props.treeNode
    }
      deviceManagementUtility.tableOptions.selectedRows = [];
      this.setState({ loading: true, selectAllCb: false });
      if (!bool) {
        this.getFilterDataList();
      }

      const nodeId =  treeNode.id;
      const nodeType = treeNode.type;
      let pageNo = deviceManagementUtility.tableOptions.pageNo;
      let pageSize = deviceManagementUtility.tableOptions.pageSize;
      deviceManagementUtility.tableOptions.orgId = treeNode.routeParams ? treeNode.routeParams[1].id : treeNode.id;

      const rootParams = nodeType === "ROOTNODE" ? ["ROOTNODE", nodeId] : treeNode.routeParams;
      const params = { 'NODEPATH': getNodePath(rootParams, false), 'PAGENO': pageNo, 'PAGESIZE': pageSize, 'apiType': 'DEVICE' };//groupRouteParams(requestParams, rootParams);
      const reqBody = this.getSelectedFilters();

    getNodeData(params, reqBody)
      .then(data => {
        if (data) {
          if (data.message === 'data canceled') {
            this.setState({ loading: true })
          } else {
            this.updatedata(data, nodeType)
          }
        } else {
          this.setState({
            devices: [],
            totalDevicesCount: 0,
            loaded: false,
            loading: false,
            summaryData: []
          })
        }
      }).catch(err => {
      });
  }
  updatePrevParam = (params) => {
    let obj = {}
    for (var key in params) {
      if (params.hasOwnProperty('REGION')) {
        obj = { ...obj, [key]: params[key].name ? params[key].name : params[key] }
      } else {
        obj = { ...obj, [key]: params[key] }
      }
    }
    return obj
  }

  updateConnectedPercentageColumnVal = (device) => {  //updating the percentage column here for table column sorting issue.
    let updatedDevice = device;
    updatedDevice.map((item)=>{
      item.connected = (["ZM1","UM1","VC10"].includes(item.deviceType) || (item.deviceType === "UM3+" && item.mcpDevice)) ? "N/A" : item.connected ? item.connected : 0
    })
    return updatedDevice
}

  updatedata = (response, nodeType) => {
    const deviceList = (nodeType !== "SITE" && nodeType !== "LATERAL_SITE") ? response.devices ? this.updateConnectedPercentageColumnVal(response.devices) : [] : response.status === "FAIL" ? [] : this.updateConnectedPercentageColumnVal(response);
    this.setState({
      devices: deviceList,
      totalDevicesCount: response.totalDevicesCount,
      loaded: false,
      loading: false,
      summaryData: response.summary
    },()=>{ this.setColumnActionWidthOnScroll(); });
  }

  getFilterDataList = async () => {
    const filterList = await getFilterList('managedevices');
    if (filterList) {
      Object.keys(filterList).forEach(key => {
        deviceManagementUtility.tableOptions.customFilterColumns.forEach(filter => {
          if (filter.type === "dropdown" && filter.dataField === key) {
            if (filter.dataField === "softwareVersions") {
              filter.data = filterList[key].sort(sortFirmwareversions);
            } else {
              filter.data = filterList[key].sort((a, b) => {
                let x = a.toUpperCase(),
                  y = b.toUpperCase();
                return x === y ? 0 : x > y ? 1 : -1;
              });
            }
          }
        })
      })
    }
  }

  getSelectedFilters = () => {
    let selectedFilters = {
      'networkGroupNames': [],
      'softwareVersions': [],
      'statuses': [],
      'states': [],
      'profileStatus': [],
      'profileNames': [],
      'fwUpgradeStatus': [],
      'deviceTypes': [],
      'searchPattern': ''
    };
    Object.keys(selectedFilters).forEach(key => {
      deviceManagementUtility.tableOptions.customFilterColumns.forEach(filter => {
        filter.items = filter.selectedItems;
        if (filter.type === "dropdown" && (filter.dataField === key || (filter.dataField === "state" && key === "states") || (filter.dataField === "deviceType" && key === "deviceTypes")) && (filter.selectedItems.length > 0 || filter.summarySelectedItems.length > 0)) {
          if (this.state.selectedPage === "details") {
            filter.selectedItems.forEach(item => {
              selectedFilters[key].push(item.value);
            })
          }
          else {
            filter.summarySelectedItems.forEach(item => {
              selectedFilters[key].push(item.value);
            })
          }
        }
        else if (filter.type === "textBox" && filter.dataField === "serialNumber" && key === "searchPattern" && (filter.selectedItems.length > 0 || filter.summarySelectedItems.length > 0)) {
          if (this.state.selectedPage === "details") {
            selectedFilters[key] = filter.selectedItems[0];
          }
          else {
            selectedFilters[key] = filter.summarySelectedItems[0];
          }

        }
      })
    });
    selectedFilters.profileStatuses = selectedFilters.profileStatus;
    selectedFilters.fwUpgradeStatuses = selectedFilters.fwUpgradeStatus;
    delete selectedFilters.profileStatus;
    delete selectedFilters.fwUpgradeStatus;
    return selectedFilters;
  }

  getFilteredList = () => {
    let filteredData = [];
    let selectedFilter = [];
    let selectedFilterName;
    let filterBtn = false;
    deviceManagementUtility.tableOptions.tableColumns.forEach(tableColumn => {
      if (tableColumn.selectedItems && tableColumn.selectedItems.length > 0) {
        selectedFilter = [];
        tableColumn.selectedItems.forEach(selected => {
          selectedFilter.push(selected.value);
          selectedFilterName = selected.name;
        });
        tableColumn.items = tableColumn.selectedItems;
        filteredData.push({ "category": selectedFilterName, "values": selectedFilter });
      }
      if ((tableColumn.selectedItems && tableColumn.selectedItems.length > 0) ||
        (tableColumn.enteredText && tableColumn.enteredText !== "")) {
        filterBtn = true;
      }
    });
    this.setState({ enableFilterBtn: filterBtn });
    return filteredData;
  }

  getSerialNumTxt = () => {
    let data = "";
    deviceManagementUtility.tableOptions.tableColumns.forEach(column => {
      if (column.dataField === "serialNumber" && column.enteredText !== "") {
        data = column.enteredText;
      }
    });
    return data;
  }

  fetchColumnFilterData = async (selectedColumnDetails) => {
    const treeNode = this.state.treeNode;
    const nodeId =  treeNode.id;
    const nodeType = treeNode.type;
    const columnName = selectedColumnDetails.dataField;
    const pageNo = deviceManagementUtility.tableOptions.pageNo;
    const pageSize = deviceManagementUtility.tableOptions.pageSize;
    let response;
    if (selectedColumnDetails.dataField !== "serialNumber") {
      response = await getNodeData(nodeId, nodeType, pageNo, pageSize, null, "", true, columnName);
    }
    deviceManagementUtility.tableOptions.pageNo = deviceManagementUtility.tableOptions.pageNo > 1 ? 1 : deviceManagementUtility.tableOptions.pageNo;
    deviceManagementUtility.tableOptions.tableColumns.forEach(x => {
      if (x.dataField === selectedColumnDetails.dataField) {
        if (selectedColumnDetails.dataField !== "serialNumber") {
          x.data = response.sitePhaseFilters ? response.sitePhaseFilters.values : [];
          x.onFilterClicked = false;
        }
        if (selectedColumnDetails.dataField === "serialNumber") {
          x.enteredText = selectedColumnDetails.enteredText ? selectedColumnDetails.enteredText : "";
          x.onFilterClicked = false;
        }
      }
    })
    return deviceManagementUtility.tableOptions.tableColumns;
  }

  setColumnActionWidthOnScroll = () => {
    if ((document.getElementsByClassName("rt-table").length && document.getElementsByClassName("rt-table")[0].scrollWidth > document.getElementsByClassName("rt-table")[0].clientWidth) && (!(document.getElementsByClassName("rt-table")[0].scrollWidth === Number(document.getElementsByClassName("rt-thead")[0].style.minWidth.split("px")[0])))) {
      document.getElementsByClassName("rt-thead")[0].style.minWidth = String(Number(document.getElementsByClassName("rt-thead")[0].style.minWidth.split("px")[0]) + 86) + "px";
      document.getElementsByClassName("rt-tbody")[0].style.minWidth = String(Number(document.getElementsByClassName("rt-tbody")[0].style.minWidth.split("px")[0]) + 86) + "px";
    }
  }

  setColumnStatus = (value) => {
    let prevData = this.state.displayColumns;
    let filterData = [];
    if (prevData.includes(value)) filterData = prevData.filter(item => item !== value)
    else filterData = [...prevData, value]
    deviceManagementUtility.displayColumns.data = filterData;
    sessionStorage.setItem("deviceExpandColumn",  JSON.stringify(filterData));
    this.setState({ displayColumns: filterData }, () => { this.setColumnActionWidthOnScroll(); })
  }

  updateDeviceManagementUtilityValues = (pageNo, sizePerPage) => {
    if (pageNo !== undefined) {
      deviceManagementUtility.tableOptions.pageNo = pageNo + 1;
    }
    if (sizePerPage !== undefined) {
      deviceManagementUtility.tableOptions.pageSize = sizePerPage;
      deviceManagementUtility.tableOptions.pageNo = 1;
    }
    deviceManagementUtility.tableOptions.selectedRows = [];
    this.setState({ options: { ...this.state.options, pageNo: deviceManagementUtility.tableOptions.pageNo, pageSize: deviceManagementUtility.tableOptions.pageSize } }, () => {
      this.fetchDeviceList()
    });
  }

  componentDidMount = async () => {
    if (this.state.treeNode && Object.keys(this.state.treeNode).length > 0 && (!this.props.treeLoader)) {
      !this.props.dashboardFlag &&this.fetchDeviceList(this.state.treeNode);
      !sessionStorage.getItem("deviceExpandColumn") ? sessionStorage.setItem("deviceExpandColumn", JSON.stringify(this.state.displayColumns)) : this.setState({displayColumns:[...JSON.parse(sessionStorage.getItem("deviceExpandColumn"))]});
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.treeNode && this.props.treeNode) || (prevProps.treeNode === undefined && this.props.treeNode)) {
      if (((prevProps.treeNode === undefined && this.props.treeNode) || (prevProps.treeNode['name'] !== this.props.treeNode['name']) || (prevProps.treeNode['type'] !== this.props.treeNode['type']) || (prevProps.treeNode["id"] !== this.props.treeNode["id"])||this.props.loadDevicesList)) {
        this.props.setLoadDevicesList(false);
        !sessionStorage.getItem("deviceExpandColumn") && sessionStorage.setItem("deviceExpandColumn", JSON.stringify(this.state.displayColumns));
        this.setState({
          treeNode: this.props.treeNode,
          loading: false,
          dashboardFlag: this.props.dashboardFlag,  //display loader on right side table area if 'dashboardFlag' is true
          displayColumns: sessionStorage.getItem("deviceExpandColumn") ? [...JSON.parse(sessionStorage.getItem("deviceExpandColumn"))] : this.state.displayColumns
        }, () =>{
          deviceManagementUtility.tableOptions.pageNo = 1;
          !this.props.dashboardFlag && this.fetchDeviceList(this.props.treeNode);
        });
      }
    }
  }

  onExportBtnClick = (type) => {
    this.setState({ loading: true }, () => {
      const routeParams = this.state.treeNode.routeParams ? this.state.treeNode.routeParams : ["ROOTNODE", this.state.treeNode.id];
      const requestParams = {
        'FORMAT': type,
        'TIMEZONE': getBrowserTimeZone(),
        'NODEPATH': getNodePath(routeParams, false),
        'apiType': 'DEVICE'
      }
      exportData(requestParams)
        .then((data) => {
          navigator.onLine ? window.location.href = data : '';
          this.setState({ loading: false })
        }).catch((err) => this.setState({ loading: false }))
    })
  }

  getDeviceDetailsTabData=(info)=>{
    this.setState({ deviceDetailsData: {}, displayTabByRes: [], rowDetails : {}, isLodingDeviceDetailsData: true }, async ()=>{
      const deviceData = await getDeviceData(info.id, info.position);
      let tmpArr = [];
      deviceData && Object.entries(deviceData).forEach(([key, value]) => value === null || JSON.stringify(value) === '{}' || value.length === 0 || ((this.props.roleAndPermission?.roleId === 3 || this.props.roleAndPermission?.roleId === 4) && (key === "systemlogs" || key === "loggerFiles")) ? "" : tmpArr.push(key)); // Diagnostics tab should visible only for Admin and Global or super admin
      let sortDeviceData =deviceData? this.sortDeviceData(deviceData):{}
      this.setState({ deviceDetailsData: sortDeviceData, displayTabByRes: tmpArr, rowDetails : info, isLodingDeviceDetailsData: false });
    })
  }

  sortDeviceData = (obj) => {
    const { isArray } = Array
    const { keys } = Object
    if (isArray(obj)) {
        const newArray = []
        for (let i = 0, l = obj.length; i < l; i++)
            newArray[i] = this.sortDeviceData(obj[i])
        return newArray
    }
    if (typeof obj !== 'object' || obj === null)
        return obj
    const sortedKeys = keys(obj).sort()
    const newObject = {}
    for (let i = 0, l = sortedKeys.length; i < l; i++)
        newObject[sortedKeys[i]] = this.sortDeviceData(obj[sortedKeys[i]])
    return newObject
  }

  updateSystemLogData=(resData)=>{
    this.setState({ deviceDetailsData : {...this.state.deviceDetailsData, systemlogs : resData }})
  } 

  rebootButtonDisable=()=>{
    this.setState({ deviceDetailsData : {...this.state.deviceDetailsData, systemlogs : {...this.state.deviceDetailsData.systemlogs, canDeviceReboot : false }}})
  }

  render() {
    return (
      <DeviceContext.Provider value={{
        state: this.state,
        getDeviceList: this.fetchDeviceList,
        setColumnStatus: this.setColumnStatus,
        updateDeviceMangementUtility: this.updateDeviceManagementUtilityValues,
        fetchColumnFilterData: this.fetchColumnFilterData,
        loadNextlevel: this.props.loadNextLevel,
        selectSearchedNode: this.props.selectSearchedNode,
        skipRightSideApiCall: this.props.skipRightSideApiCall,
        onExportBtnClick: this.onExportBtnClick,
        getDeviceDetailsTabData: this.getDeviceDetailsTabData,
        updateSystemLogData: this.updateSystemLogData,
        rebootButtonDisable: this.rebootButtonDisable,
        handleSelectAll: this.handleSelectAll,
        onChangeHandler: this.onChangeHandler
      }}>
        {this.props.children}
      </DeviceContext.Provider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    roleAndPermission: state.loginData.roleAndPermission
  }
}

export default withRouter(connect(mapStateToProps, {})(DeviceProvider));

export const DeviceConsumer = DeviceContext.Consumer;