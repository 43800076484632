import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
 import { Form, FormControl } from 'react-bootstrap';
 import { getURL } from './providers/configProvider';
import restApi from "./restapi";


import Home from './home';
import ampleStore from './store';

class AmpleApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiSet: false,
        }
    }

    componentDidMount() {
        restApi.setRestApi(() => {
            this.setState({
                apiSet: true
            })
        })
    }

    render() {
        return this.state.apiSet ? <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Provider store={ampleStore}>
                <React.Fragment>
                    <Form action={getURL('login', 'autoFormURL', [], true)} method="POST" hidden id="autoLoginForm">
                        <FormControl
                            type="hidden"
                            value=""
                            id="autoLoginInput" />
                    </Form>
                    <Home />
                </React.Fragment>
            </Provider>
        </BrowserRouter> : null
    }
}
export default AmpleApp;

