import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as treeMethods from '../containers/treeMethods';
import * as treeBuilderActions from '../actions/index';
import { updateSearchQuery } from "../../login/actions/loginActionsBuilder";
import { updateTreeRefArr, updateTreeDataKeyArr } from '../../treeview/actions/treeViewActionBuilder';
import { compareValues } from '../../services/utilityService';
import { nodeSearch, updateNode } from '../services/treeService';
import { getClassName } from '../../utility/treeViewUtility'
import { tableOptions } from '../../utility/deviceManagementUtility'
import { withRouter } from 'react-router-dom';
import {userPreferences} from '../../services/utilityService';

// Creating the new context to maintain the tree view context
export const TreeContext = React.createContext();
let searchIntervalId = 0
let loadFeederDashboardValue=''
// creating the provider to maintain the local state  
class TreeProvider extends Component {
  // Initialze and assign the local state variable 
  state = {
    globalData: this.props.globalData,
    tree: this.props.tree,
    loading: false,
    searchQuery: this.props.searchQuery,
    searchResult: [],
    searchedString: "",
    showDropdown: false,
    selectedItem:"",
    breadCrumb: "",
    previouslySelectedNode: "",
    highlightedNode: "",
    dragState: false,
    selectedRoute: "",
    nodeOrderedList: [],
    dashboardFlag:false,
    isClickedTick: false,
    loadDevicesList:false
    //searchedNode: {},
    //allowToFetchList: true
  }

  // Updating the state based on the new props value
  componentWillReceiveProps(nextProps) {
    if (tableOptions.pageComingFrom === "systemAdmin") { //if redirected from system-admin and deviceManagementUtility.pageComingFrom === 'systemAdmin then call loadNextLevel function
      this.setState({ previouslySelectedNode: 'nodeName' }, () => { this.loadNextLevel({ "node": this.state.tree[0] }, null, null, 'nodeName'); })
      tableOptions.pageComingFrom = ""; // once calling loadNextLevel function removing deviceManagementUtility.pageComingFrom = "", so that next time Won't call
    }
    else if(nextProps.location.search && tableOptions.pageComingFrom === "systemAdminFileUpload"){
      this.refreshTree(nextProps.location.search);
      tableOptions.pageComingFrom = "";
    }
     if (nextProps.location.pathname !== this.props.location.pathname) {  //when switching from one page to an another page (prv path and new path are not same)
       this.setState({ searchedString: '' })
         if( ((this.props.location.search && this.state.dragState ) || this.state.isClickedTick)) { // if dragState is true or user clicked update (tick mark), then calling urlUpdate()
          this.urlUpdate()
        }
      }
      if (nextProps.tree !== this.props.tree) {
      this.setState({
        globalData: nextProps.globalData,
        tree: nextProps.tree,
        nextProps: nextProps
      })
      this.props.updateSelectedTree(nextProps.tree);
    }
    if (nextProps.dashboardRedirectData !== this.props.dashboardRedirectData) { // checking if redirected from dashboard and updated dashboard reducer then calling selectSearchedNode for auto select feeder
      this.autoSelectFromDashboard(nextProps.dashboardRedirectData)
    }
    // URL needs to be updated when navigating occurs (eg., manage to mapview / manage profiles to manage site) starts
    if ((this.state.tree.type || this.state.tree[0].children) && (this.state.selectedRoute !== nextProps.currentRoute)) {  
      this.setState({
        selectedRoute: nextProps.currentRoute,
      },()=>{(nextProps.currentRoute.includes("/events/register") || nextProps.currentRoute.includes("/events/summary") || nextProps.currentRoute.includes("/events/details") || nextProps.currentRoute.includes("/manage/summary") || nextProps.currentRoute.includes("/manage/details") || nextProps.currentRoute.includes("/manage/site") || nextProps.currentRoute.includes("/mapView") || nextProps.currentRoute.includes("/monitor")  || nextProps.currentRoute.includes("/disturbances")) && this.appendParamsToUrl();})
    }
    // URL needs to be updated when navigating occurs ends
  }
  autoSelectFromDashboard = (data, bool) => {
    this.selectSearchedNode(data,true);
    if(!bool){
    this.setDashboardFlagState(true);
    loadFeederDashboardValue = data.type === "SUBSTATION" ?  data.substationName : data.feederName;
    }
  }

  componentDidUpdate(){
    let nextProps = this.state.nextProps;
    if (nextProps?.location?.pathname !== this.props.location.pathname) { //wnen current path and prv path both are not same or when switching one page to an another page, 
      if (this.state.dragState || this.state.isClickedTick ) {  // !immediatly state value updating to false to prevent the unnecessary urlUpdate() call.
          this.setState({ dragState : false, isClickedTick: false})
       }
      }
  }

  componentDidMount(){  //URL needs to be updated on page refresh or reload.
    this.props.location.search.includes('?node=ROOTNODE') && this.urlUpdate();
    window.addEventListener('popstate', this.onClickBrowserBack);
  }

  componentWillUnmount() {
    document.removeEventListener('popstate', this.onClickBrowserBack);
  }

  onClickBrowserBack = () => {
    if (this.props.location.search.includes('?node=ROOTNODE')) this.urlUpdate();
  }

  setDashboardFlagState=(params)=>{
    this.setState({dashboardFlag: params});
  }

  skipRightSideApiCall=(value, selectedNode)=>{ // device moving time
    this.setState({ dashboardFlag : value })
    loadFeederDashboardValue = selectedNode;
  }

  cancelApiCallFromDashboard=()=>{ //cancelRightRender
   this.setDashboardFlagState(false);
  }
  refreshTree = (pData) => {
    this.reloadTreeNode(pData);
  }
  reloadTreeNode = async(pData)=>{
    const node = pData && new URLSearchParams(pData).get('node').split('/');
    const searchedKey = node && node[node.length - 1];
    const data = node.length === 2 ?  this.state.tree : await nodeSearch(this.state.tree[0].id, searchedKey);
    let arr = data.length === 1 ? data : data.filter(item => (node.length > 8 && item.lateralName) ? item.regionName === node[3] && item.substationName === node[5] && item.feederName === node[7] && item.lateralName === node[9] && item.type === node[node.length - 2] :
      node.length > 6 ? item.regionName === node[3] && item.substationName === node[5] && item.feederName === node[7] && item.type === node[node.length - 2] :
        node.length > 4 ? item.regionName === node[3] && item.substationName === node[5] && item.type === node[node.length - 2] :
          item.regionName === node[3] && item.type === node[node.length - 2]);
    if (arr.length > 0) {
      arr[0].isSearchValue = false;
      this.selectSearchedNode(arr[0]);
    }
  }
  urlUpdate = () => {
    this.reloadTreeNode(this.props.location.search);
  }

  updateTempId = (payloadData, item, key) => {
    let uniqKey = '';
    switch (item.type) {
        case 'REGION': 
            uniqKey = payloadData[1].name + item.name +item.id + key
        break;
        case 'SUBSTATION': 
            uniqKey = payloadData[1].name + payloadData[3].name + item.name + item.id + key
        break;
        case 'FEEDER': 
            uniqKey = payloadData[1].name + payloadData[3].name + payloadData[5].name + item.name + item.id + key
        break; 
        case 'LATERAL':
        case 'SITE':
            uniqKey = payloadData[1].name + payloadData[3].name + payloadData[5].name + payloadData[7].name + item.name + item.id + key
        break; 

        default:
            break;
    }

    return uniqKey
}

  // Handling the on tree node click
  loadNextLevel = async (clickedNode, routeParams, callback, nodeNameVal,updateSite) => {
    this.props.updateSearchQuery(null);
    let updatedNode = {};
    const nodeRouteParams = routeParams ? routeParams : treeMethods.getNodeRouteParams(clickedNode);
    const breadCrumb = getClassName(clickedNode);
    this.setState({
      loading: this.state.previouslySelectedNode === nodeNameVal ? false : true,
      previouslySelectedNode: nodeNameVal,
      breadCrumb: nodeNameVal ? breadCrumb.replace(/-/g, "-") : this.state.breadCrumb,
      nodeNameVal : nodeNameVal,
      searchedString: nodeNameVal === "nodeName" || nodeNameVal === undefined ? this.state.searchedString : "",
      //allowToFetchList: (nodeNameVal === "nodeName" && this.state.searchedString && this.state.searchResult.length > 0) ? !(nodeRouteParams.includes(this.state.searchedNode.type)) ? false : true : true
    });
    //Toggle the node Based on the node type 
    if (((clickedNode.node.type.indexOf('SITE') === -1) && (clickedNode.node.type.indexOf('LATERAL_SITE') === -1))) {
      // Getting the childs 
      let children = await treeMethods.getNodes(nodeRouteParams);
      children = children && children.length ? children : [];
      let arr = ["FEEDER","LATERAL","SITE","LATERAL_SITE"];
      if (children.length > 0 && ((children[0].type === 'REGION' || children[0].type === 'SUBSTATION') || (userPreferences.treeOrder === 'Alphabetical' && (arr.includes(children[0].type))))) {
        children = children.sort(compareValues("name")); //((children[0].type === 'REGION') || (children[0].type === 'SUBSTATION')) ? children.sort(compareValues("name")) : children;
      }
      clickedNode.node.children = [];
      clickedNode.node.children = children;

      for (let i = 0; i < children.length; i++) {
        let item = children[i];
        item.routeParams = nodeRouteParams.slice(0, nodeRouteParams.length);
        item.routeParams.push(item.type);
        item.routeParams.push(item);
        item.expanded = item.hasOwnProperty("expanded") ? item.expanded : false;
        item.tempId = nodeNameVal !== 'editNode' && this.updateTempId(nodeRouteParams, item, i);
        children[i] = treeMethods.addNodeData(clickedNode, item);
      }

      setTimeout(() => { if (callback) callback(children) }, 0);

      let selectedNode = treeMethods.updateSelectedNode(clickedNode);
      updatedNode = treeMethods.updateTree(this.state.tree, selectedNode.updatedNode, selectedNode.clickedNode); 
    }
    if(clickedNode.node.expanded && nodeNameVal === undefined) {
      updatedNode = treeMethods.closeNode(this.state.tree, clickedNode);
      if(clickedNode.node.type === "ROOTNODE") {
        updatedNode = [updatedNode];
      }
    }
    if(clickedNode.node.type === "SITE" || clickedNode.node.type === "LATERAL_SITE") {
      if (updateSite) {
        let children = await treeMethods.getNodes(nodeRouteParams.slice(0, nodeRouteParams.length - 2));
        let data = children.find(e => e.id === clickedNode.node.id);
        clickedNode.node = { ...clickedNode.node, ...data };
        clickedNode.node.routeParams[clickedNode.node.routeParams.length - 1].siteDeviceType = data.siteDeviceType;
        let selectedNode = treeMethods.updateSelectedNode(clickedNode);
        updatedNode = treeMethods.updateTree(this.state.tree, selectedNode.updatedNode, selectedNode.clickedNode);
      } else {
        updatedNode = clickedNode.node;
      }
    }
    //updating the tree nodes based on the new state
    this.setState({
      tree: clickedNode.node.type === "ROOTNODE" ? updatedNode : Object.assign([], this.state.tree, updatedNode),
      loading: false,
      highlightedNode: nodeNameVal ? clickedNode : this.state.highlightedNode,
      selectedItem: nodeNameVal? `shadow${getClassName(clickedNode)}` : this.state.selectedItem
    },()=>{nodeNameVal && this.appendParamsToUrl();})
    if(this.state.searchedString !== "" && nodeNameVal !== undefined && routeParams && routeParams[routeParams.length-2] === this.state.searchedNodeType){
        this.scrolltoSearchedNode();
    }
  }
  appendParamsToUrl = () => {
    let url = this.props.currentRoute;
    let node = this.props.location.search && new URLSearchParams(this.props.location.search).get('node').split('/');
    let formatUrl = (this.state.tree.type && this.state.tree.type !== 'ROOTNODE') ? this.getUrl() : (this.state.nodeNameVal === this.state.tree[0].name || this.state.nodeNameVal === "nodeName" || this.state.nodeNameVal === "deleteNode") ? encodeURIComponent(this.state.tree[0].type + '/' + this.state.tree[0].id) : node ? encodeURIComponent(node.join('/')) : '';
    url = url + '?node=' + formatUrl;
    return this.props.history.push(url);
  }
  getUrl = () => {
    let formattedUrl = this.state.highlightedNode && this.state.highlightedNode.node.routeParams && this.state.highlightedNode.node.routeParams.map(val => val.name ? val.type === 'ROOTNODE' ? val.id : val.name : val).join('/');
    return encodeURIComponent(formattedUrl);
  }
  scrolltoSearchedNode = () => {
    var x = 0;
    let treeElement = document.getElementsByClassName("ReactVirtualized__List")[0];
    let selectedNode = document.getElementsByClassName("selected-feature-node");
    treeElement.scroll(0, 0);
    var t = setInterval(() => {
      x += 100;
      if (selectedNode.length > 0) {
        selectedNode[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        clearInterval(t);
      } else {
        treeElement.scroll(0, x)
      }
    }, 1)
  }

  setTreeRef = (ref, name) => {
    let refenc = { [name]: ref }
    this.props.treeRefArr(refenc, name);
  }

  inputChangedHandler = async (evt) => {
    if (searchIntervalId) {
      clearTimeout(searchIntervalId);
      searchIntervalId = null;
    }
    const searchedKey = evt.target.value;
    let result = this.state.searchResult;
    let callAPI = true;

    if (searchedKey === "" || this.state.searchedString !== searchedKey) {
      result = [];
    }

    // Pattern for alphanumeric and white spaces
    const re = /^(?!.*[\\\\/;$(#?%).].*)/i;
    if (!searchedKey || (!re.test(searchedKey))) {
      result = [];
      callAPI = false;
    }

    this.setState({ searchedString: searchedKey });

    searchIntervalId = setTimeout(
      async () => {
        if (callAPI) {
          const orgId = this.state.tree ? this.state.tree[0].id : 1;

          const data = await nodeSearch(orgId, searchedKey);
          result = data;
        }
        this.setState({ searchResult: result, showDropdown: true })
      },
      200);
  }

  updateDragStateChange = (data) => {
    let parent = [];
    let childs = [];
    data && data.nextParentNode && data.nextParentNode.routeParams && data.nextParentNode.routeParams.map(item => item.name && parent.push(item.name));
    parent.shift();
    data && data.nextParentNode && data.nextParentNode.children && data.nextParentNode.children.length > 0 && data.nextParentNode.children.map(item => childs.push(item.name));
    this.prepareNodeOrderingList(parent, childs);
    this.setState({ dragState: Object.keys(data).length > 0 })
  }
  closeDragState = (value) => {
    this.setState({
      dragState: false,
      isClickedTick : value
    })
  }

  prepareNodeOrderingList = (prepareParentLevel, childs) => {
    let nodeOrderingList = this.state.nodeOrderedList;
    var matched = false;
    if (nodeOrderingList && (nodeOrderingList.length > 0)) {
      nodeOrderingList.forEach(obj => {
        if (JSON.stringify(obj.parentLevel) === JSON.stringify(prepareParentLevel)) {
          obj.childOrder = childs;
          matched = true;
        }
      });
    }
    if (!matched && (prepareParentLevel.length > 0)) {
      nodeOrderingList.push({ 'parentLevel': prepareParentLevel, 'childOrder': childs });
    }
    this.setState({ nodeOrderedList: nodeOrderingList });
  };

  updateNodePositions = async () => {
    let changeNodeStr = {
      "regions": [],
      "substations": {},
      "feeders": {},
      "sitesAndLaterals": {},
      "lateralSites": {}
    };
    let nodeStr = ["regions", "substations", "feeders", "sitesAndLaterals", "lateralSites"];

    this.state.nodeOrderedList.forEach(nodeObj => {
      let currentNodeEntry = '';
      let NextNodeEntry = '';
      let lastParentName = '';
      nodeObj.parentLevel.forEach((val, key) => {
        let prevNodeName = (key === 0) ? '' : nodeObj.parentLevel[key - 1];
        lastParentName = val;
        currentNodeEntry = nodeStr[key];
        NextNodeEntry = nodeStr[key + 1];
        if (key === 0) {
          if (changeNodeStr[currentNodeEntry].indexOf(val) == -1) {
            changeNodeStr[currentNodeEntry].push(val);
            changeNodeStr[NextNodeEntry][val] = [];
          }
        } else {
          if (changeNodeStr[currentNodeEntry][prevNodeName].indexOf(val) == -1) {
            changeNodeStr[currentNodeEntry][prevNodeName].push(val);
            changeNodeStr[NextNodeEntry][val] = [];
          }
        }
      });
      changeNodeStr[NextNodeEntry][lastParentName] = nodeObj.childOrder;
    });
    let data = await updateNode(changeNodeStr);
    if (data.status == 'OK') {
      this.setState({ nodeOrderedList: [] });
    }
  }

  selectSearchedNode = (selectedNode, addDevice) => {
    const searchKeyword = selectedNode.hasOwnProperty('isSearchValue') || addDevice ? '' : selectedNode.name;
    //this.setState({ showDropdown: false, searchedString: searchKeyword ,searchedNodeType : selectedNode.type, searchedNode: selectedNode},()=>{
    this.setState({ showDropdown: false, searchedString: searchKeyword ,searchedNodeType : selectedNode.type },()=>{
    const types = ['REGION', 'SUBSTATION', 'FEEDER', 'SITE', 'LATERAL', 'LATERAL_SITE'];
    const typeName = ['regionName', 'substationName', 'feederName', 'siteName', 'lateralName', 'lateralSiteName'];
    let path = ["ROOTNODE", this.state.tree[0]];

    types.forEach((type, i) => {
      let nodeType = selectedNode.hasOwnProperty(types[i]) ? types[i] : typeName[i];
      if (selectedNode.hasOwnProperty(nodeType) && selectedNode[nodeType] !== null) {
        if (!((selectedNode['lateralName'] || selectedNode['lateralSiteName']) && type === 'SITE')) {
          path.push(type, selectedNode[nodeType]);
        }
      }
    });

      this.autoLoadTree(path, (data) => {
        let nodeId = this.state.tree[0].id;
        types.forEach((type, i) => {
          let nodeType = selectedNode.hasOwnProperty(types[i]) ? types[i] : typeName[i];
          if (selectedNode.hasOwnProperty(nodeType) && selectedNode[nodeType] !== null) {
            if (!((selectedNode['lateralName'] || selectedNode['lateralSiteName']) && type === 'SITE')) {
              nodeId = nodeId + "-" + selectedNode[nodeType];
            }
            nodeId = nodeId.replace(/\s+/g, '_-_-_');
          }
        })
      })
    });

    
  }

  autoLoadTree = (path, callback) => {
    let updateRootNode = this.state.tree[0];
    updateRootNode.expanded = false;
    updateRootNode.arrow = "right";
    this.setState({ tree: Object.assign([], this.state.tree, updateRootNode) },()=>{
      let recursiveFetch = (nodeInfo, path, index) => {
        let newPath = (index + 2 <= path.length) ? path.slice(0, index + 2) : [];
  
        const childPath = index + 4 <= path.length ? path.slice(index + 2, index + 4) : [null, -1];
        const childType = childPath[0];
        const childId = childPath[1];
  
        if (nodeInfo != null && nodeInfo.node && nodeInfo.node.type !== 'ROOTNODE') {
          const route = nodeInfo.node.routeParams;
          newPath = route;
        }

        if (loadFeederDashboardValue) {
          if ((nodeInfo != null && nodeInfo.node.name === loadFeederDashboardValue) || (nodeInfo.node.type === 'SITE' || nodeInfo.node.type === 'LATERAL_SITE')) this.setState({ dashboardFlag: false });
          else this.setDashboardFlagState(true);
        }

        this.loadNextLevel(nodeInfo, newPath, (data) => {
          if (data) {
            data.forEach(dt => {
              const item = dt;
              if (item.hasOwnProperty('children')) {
                item.routeParams[item.routeParams.length - 1]['children'] = item['children'];
              }
              if ((item.name === childId) && (item.type === childType)) {
                item.collapsed = false;
                recursiveFetch({ node: item }, path, index + 2);
              }
            })
          };
          if (callback && index + 2 >= path.length) {
            callback(data);
          }
        }, "nodeName");
      }
      recursiveFetch({ "node": this.state.tree[0] }, path, 0);
    });
    
  };   

  onBlur = () => {
    this.setState({ showDropdown: false });
  }

  setLoadDevicesList = (param)=>{
    this.setState({loadDevicesList:param})
  }

  updateDataOnDeleteOfTreeNode=(name)=>{
    // updating actual highlighted node name here, according to this value we are updating 'dashboardFlag' true/false. (follow this function=> autoLoadTree(),  if (loadFeederDashboardValue) condition )
    loadFeederDashboardValue = name
  }

  render() {
    // Initialzing and assigning the provider values on top of the component
    return (
      <TreeContext.Provider value={{
        state: this.state,
        loadNextLevel: this.loadNextLevel,
        searchQuery: this.props.searchQuery,
        setTreeRef: this.setTreeRef,
        inputChangedHandler: this.inputChangedHandler,
        selectSearchedNode: this.selectSearchedNode,
        onBlur: this.onBlur,
        updateDragStateChange: this.updateDragStateChange,
        closeDragState: this.closeDragState,
        updateNodePositions: this.updateNodePositions,
        urlUpdate: this.urlUpdate,
        cancelApiCallFromDashboard:this.cancelApiCallFromDashboard,
        autoSelectFromDashboard: this.autoSelectFromDashboard,
        setDashboardFlagState:this.setDashboardFlagState,
        skipRightSideApiCall: this.skipRightSideApiCall,
        setLoadDevicesList:this.setLoadDevicesList,
        updateDashboardFlagAndHighlNode: this.updateDataOnDeleteOfTreeNode
      }}>
        {this.props.children}
      </TreeContext.Provider>
    )
  }
}

// Getting the state from the store
const mapStateToProps = state => {
  return {
    globalData: state.loginData.treeData,
    tree: state.treeviewData.tree,
    searchQuery: state.loginData.searchQuery,
    treeRefArrVal: state.treeviewData.treeRefArr,
    treeDataKeyVal: state.treeviewData.TreeDataKeyValuePair,
    dashboardRedirectData: state.dashboardData.redirectData,
    currentRoute: state.headerData.currentRoute,
  };
}

// Dispatching the event
const mapDispatchToProps = dispatch => {
  return {
    updateSelectedTree: (tree) => dispatch(treeBuilderActions.updateSelectedTree(tree)),
    updateSearchQuery: (updatedSearchQuery) => dispatch(updateSearchQuery(updatedSearchQuery)),
    treeRefArr: (updatetreeRefArr, key) => dispatch(updateTreeRefArr(updatetreeRefArr, key)),
    treeDataKeyValArr: (updatetreeDataKeyValArr) => dispatch(updateTreeDataKeyArr(updatetreeDataKeyValArr)),
  }
}

// connecting the store to get the state and dispatching the event
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TreeProvider));

// Creating and exporting the Consumer to access the local state
export const TreeConsumer = TreeContext.Consumer;