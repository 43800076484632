import axios from 'axios';
import { getURL, getRestAPIURL } from '../../providers/configProvider';
import { requestQAll } from '../../services/extendedService';

export const checkSession = () => {
    const url = getURL('login', 'customerInfo', {});
    return axios.get(url);
}
//code for subsequent security check for other modules
export const getSecurityCheck = (clientName, path) => {
    var conPath = getRestAPIURL(true) + path;
    const url = getURL('login', 'securityCheckSession', { 'CLIENTNAME': clientName, 'PATH': conPath }, true);
    return axios.get(url);
}
export const checkAutoLogin = () => {
    const url = getURL('login', 'auto', {}, true);
    return axios.get(url);
}
export const checkSSOLogin = () => {
    const url = getURL('login', 'checkSSO', {}, true);
    return axios.get(url);
}

export const checkSSOCheckLogin = (accountid, email) => {
    const url = getURL('login', 'checkSSOCheck', {'EMAIL': email, 'ACCOUNTID': accountid}, true);
    return axios.get(url);
}

export const getOrganizations = () => {
    const url = getURL('login', 'organizations', {}, true);
    return axios.get(url);
}

export const selectOrganization = (orgId) => {
    const url = getURL('login', 'selectOrganization', { 'ORGID': orgId }, true);
    return axios.get(url);
}

export const getIP = () => {
    const url = getURL('login', 'ipInfo', {}, true);
    return axios.get(url);
}

export const audirTrailPostLogin = (dataParams, reqBody) => {
    const url = getURL('audittrail', 'postlogin', dataParams);
    return axios.post(url, reqBody);
};

export const roleAndPermission = () => {
    const url = getURL('dashboard', 'rolesandpermissions', {'apiType': 'data'}, true);
    return axios.get(url);
}

export const sendResetPasswordEmail = (email,accountid) => {
    const url = getURL('login', 'resetpasswordemail', { 'EMAIL': email, 'ACCOUNTID':accountid, 'apiType': 'data' }, true);
    return axios.get(url);
}

export const validateResetPasswordToken = (id, token) => {
    const url = getURL('login', 'resetpasswordtoken', { 'PRINCIPALID': id, 'TOKEN': token, 'apiType': 'data' }, true);
    return axios.get(url);
}

export const resetPassword = (id, params) => {
    const url = getURL('login', 'resetpasswordinfo', { 'PRINCIPALID': id, 'apiType': 'data' }, true);
    return axios.post(url, params);
}

export const forceResetPassword = (params) => {
    const url = getURL('login', 'resetpassword', { 'apiType': 'data' }, true);
    return axios.post(url, params);
}

export const samlLoginUrl = () => {
    return getURL('login', 'samlLogin', {}, true);
}

export const samlLogout = () => {
    const url = getURL('login', 'samlLogout', {}, true);
    return requestQAll([url]);
}