import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import SubNavigations from '../../header/navigation/subNavigations';
import Menus from '../../config/menus.json';
import EventsRoutes from '../../routes/eventsRoutes';
import './index.css';

class FaultAnalysis extends Component {
    render() {
        return (
            <div className="secondary-navbar col-md-12 col-12 p-0 fault-analysis">
                <SubNavigations menu={Menus['eventsSubMenu']} />
                <EventsRoutes />
            </div>
        );
    }
}

export default withRouter(FaultAnalysis);