import React, { createContext } from 'react';
import { getAllLicenseData, uploadLicenseURL, resetLicenseData } from '../services/licenseService';
import { FormattedMessage } from 'react-intl';

export const LicenceContext = createContext();

class LicenceProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            file: null,
            fileUploadRes: { type: "", msg: "" },
            isLoading: false,
            licenceData : [],
        }
    }

    componentDidMount() {
        this.getLicenseData();
    }

    getLicenseData = () => {
        this.setState({ isLoading : true },()=>{
            getAllLicenseData()
            .then((data) => {
                if(data) this.processAllLicenseData(data)
                else this.setState({ isLoading : false })
            }).catch((err) => this.setState({ isLoading : false }))
        })
    }

    processAllLicenseData = (data) => {
        let newData = [
            { license: <FormattedMessage id='db.licMngmnt.manage' />, expiresOn: data.manage },
            { license: <FormattedMessage id='db.licMngmnt.monitor' />, expiresOn: data.monitor },
            { license: <FormattedMessage id='db.licMngmnt.SAIDI' />, expiresOn: data.faultAnalysis },
            { license: <FormattedMessage id='db.licMngmnt.SAIFI' />, expiresOn: data.disturbanceAnalytics }
        ];
        this.setState({ tableData: newData, isLoading: false, licenceData : data })
    }

    handleFileUpload = (e) => {
        if (e.target.files.length !== 0) {
            this.setState({ file: e.target.files[0], isLoading: true }, () => {
                let formData = new FormData();
                formData.append('fileData', this.state.file);
                uploadLicenseURL(formData)
                    .then(res => {
                        let licenceFileUploadResMsg = (res.indexOf('uploaded successfully') !== -1) ? { type: 'success', msg: <FormattedMessage id='db.licMngmnt.successfullyUploaded' /> } : { type: 'danger', msg: res };
                        this.setState({ fileUploadRes: licenceFileUploadResMsg, file : null }, () => {
                            if (res.indexOf('uploaded successfully') !== -1) {
                                this.resetLicense();
                                this.getLicenseData();
                            } else this.setState({ isLoading: false, file: null, })
                        })
                    }).catch(err => {
                        this.setState({ fileUploadRes: { type: 'danger', msg: err.data, isLoading: false, file: null, } })
                    })
            })
        }
    }

    resetLicense = () => {
        resetLicenseData()
            .then((data) => { })
    }

    handleReoveAlert = () => this.setState({ fileUploadRes: { type: "", msg: "" } })

    render() {
        return (
            <LicenceContext.Provider value={{
                state: this.state,
                handleFileUpload: this.handleFileUpload,
                handleReoveAlert: this.handleReoveAlert
            }}>
                {this.props.children}
            </LicenceContext.Provider>
        )
    }
}
export default LicenceProvider;