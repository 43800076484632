import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { DetailsContext } from '../provider/detailsProvider';
import HighchartsReact from 'highcharts-react-official';
import HighCharts from "highcharts/highstock";
import HighchartsData from "highcharts/highcharts";
import HC_more from 'highcharts/highcharts-more';
import { FormattedMessage } from 'react-intl';
import { dateConvertion } from '../../../../services/utilityService';
import BlankPage from '../../../../hoc/blankPage';
import exporting from "highcharts/modules/exporting";
import { exportData } from '../services/faultDetailsService';
import {chartData, updateLegend} from '../../../../utility/faultDetailsUtility';
import jsPDF from 'jspdf';
import { toBlob, toCanvas } from 'html-to-image';
import { saveAs } from 'file-saver';
import TableSection from './tableSection'

exporting(HighCharts);
HC_more(HighCharts)


const style = { fontFamily: "'univers', Arial, sans-serif", fontSize: "1.3vh", color: "#333333" };
let activeSeries = [];

class FaultChart extends Component {
    static contextType = DetailsContext;
    constructor(props) {
        super(props);
        HighCharts.setOptions({
            colors: ['#FF7474', '#5CD65C', '#7495BE'], // controls which colors are used.
            global: {
                useUTC: true,
                timezone: props.timezone
            }
        })
        this.state = {
            finalData: {},
            isDisplyCollapsIcon: true
        }

        this.siteMinMaxTime = [];
        // this.sampleRate = 130;
        this.pointInterval = 128;
        this.pointStart = new Date().getTime();
        this.pointEnd = new Date().getTime();
        // this.tickInterval = this.sampleRate * this.pointInterval;

        this.series = [];
        this.chartOptions = JSON.parse(JSON.stringify(this.props.options))
        // this.activeSeries = [];
    }

    afterChartCreated = (chart) => {
        chartData.internalChart = chart;
        this.internalChart = chart;
        this.context.chartList.push(chart);
        Object.keys(chart).length > 0 && chartData.chartList.push(chart);
        this.forceUpdate();
    }

    shouldComponentUpdate(nextProps, nextState){
        return JSON.stringify(this.props.waveformList) === JSON.stringify(nextProps.waveformList)
     }

    componentDidMount() {
        this.buildSeries(this.props.waveformList);
        this.drawChart('it');
    }

    componentDidUpdate(prvProps) {
        if (JSON.stringify(this.props.waveformList) !== JSON.stringify(prvProps.waveformList)) {
            this.buildSeries(this.props.waveformList);
            this.drawChart('it');
            this.context.chartList.forEach((chart, key) => {
            chart.resetZoomButton && chart.resetZoomButton.hide();
            chart.xAxis && chart.xAxis[0].setExtremes(null,null);
            chart.yAxis && chart.yAxis[0].setExtremes(null, null);
            chart.yAxis && chart.yAxis[1].setExtremes(null, null);
            });
        }
    }

    drawChart = () => {
        let view = '';
        if (this.context.state.typeVal.length === 2 || this.context.state.typeVal.length === 0) {
            view = 'a'
        } else view = this.context.state.typeVal.toString()
        var data = this.props.waveformList;
        this.siteMinMaxTime = data.siteMinMaxTime;
        this.chartOptions.view = (view === 'e') ? 'a' : view;
        this.chartOptions.phases = this.props.phases;
        this.chartOptions.highcharts.exporting['filename'] = 'feeder-' + data.feeder + '-site-' + data.siteName;
        this.chartOptions.highcharts.title['text'] = 'feeder-' + data.feeder + '-site-' + data.siteName;
        this.renderChart(data, data.siteMinMaxTime);
    }

    renderChart = (data, siteMinMaxTime) => {
        this.pointStart = (siteMinMaxTime && siteMinMaxTime.length > 0) ? siteMinMaxTime[0] : new Date().getTime();
        this.pointEnd = (siteMinMaxTime && siteMinMaxTime.length > 0) ? siteMinMaxTime[1] : new Date().getTime();

        //update the axis extents
        this.chartOptions.highcharts.navigator.xAxis.max = this.pointEnd;
        this.chartOptions.highcharts.xAxis.max = this.pointEnd;
        this.chartOptions.highcharts.navigator.xAxis.min = this.pointStart;
        this.chartOptions.highcharts.xAxis.min = this.pointStart;
        var view = this.chartOptions.view;
        var series = this.buildActiveSeries(view);
        var hasData = (series && series.length > 0) ? this.seriesArrayHasData(series) : false;
        activeSeries = series;
        this.chartOptions.highcharts.navigator.series = [];
        series && series.forEach((seriesdata,seriesind)=>{
            let tempArr = [];
            seriesdata.data.forEach((data,ind)=>{
                let time = seriesdata.pointStart + (ind * seriesdata.pointInterval);
                tempArr.push([time,data])
            })
            this.chartOptions.highcharts.navigator.series[seriesind] = {
                data: tempArr
            }
        })
            this.chartOptions.highcharts.series = series;
            this.chartOptions.highcharts.plotOptions= {
                series: {
                    pointStart: this.pointStart,
                    events:{
                        legendItemClick: (event) =>{
                            setTimeout(()=> {
                                updateLegend("legendItemClick", event);
                            }, 0);
                        }
                    }
                }
            };
            this.chartOptions.highcharts.yAxis = this.buildAxes(view);
            this.setState({ finalData: {...this.chartOptions} })
    }

    buildAxes = (view) => {
        var yAxes = [];
        yAxes.push(this.buildAxis(0, 'it', 0));
        yAxes.push(this.buildAxis(1, 'et'));
        return yAxes;
    }

    buildAxis = (i, unit, min) => {
        var enabled = (this.chartOptions.view === 'a') ? true : (this.chartOptions.view === unit) ? true : false;
        return { // create and add the axis
            allowDecimals: true,
            labels: {
                style: style,
                x: (i === 0) ? -10 : 10,
                align: (i === 0) ? 'right' : 'left',
                enabled: true
            },
            title: {
                text: (enabled) ? this.chartOptions.highcharts?.series[0]?.summary?.siteDeviceType === "UM1" && unit === "et" ?  "Voltage (V)" : this.chartOptions.titles[unit] : null,
                style: style
            },
            lineWidth: 1,
            opposite: (i === 0) ? false : true,
            gridLineWidth: (i === 0) ? 1 : 0,
            gridLineDashStyle: 'dot'
        };
    }

    seriesArrayHasData = (seriesArray) => {
        var dataSum = seriesArray.length !== 0 && seriesArray.map((series, name) => { // for each  series
            return (series.data || []).length; // get  the  series  length
        }).reduce(function (sum, x) {
            return sum + x;
        });
        return dataSum > 0;
    };

    isETs = (seriesId) => seriesId && seriesId.indexOf('et') !== -1;
  
    buildActiveSeries = (view) => {
        var result = Object.keys(this.series).length !== 0 ? Object.entries(this.series).map(([id, s]) => {
            var axisIndex = this.isETs(id) ? 1 : 0;
            var dashStyleIndex = this.isETs(id) ? 1 : 0;
            // s.name = this.getSeriesName(view, id);
            s.name = this.getSeriesName(view, id, s);
            s.phaseName = this.getPhaseInfo(view, id);
            s.id = id;
            s.showInNavigator =  false;
            s.yAxis = axisIndex;
            s.turboThreshold = 0;
            s.dashStyle = this.chartOptions.dashStyles[dashStyleIndex];
            return s;

        }) : []
        return result;
    }

    getSeriesName = (view, id, data) => {
        id = id.split("t");
        let tempPhase = id[0][0].toUpperCase();
        let finalName = this.chartOptions.seriesNames[id[0] + 't'].name;
        if (data.summary.siteDeviceType === "UM1") {
            let temp = this.chartOptions.seriesNames[id[0] + 't'].name.split("Phase "+tempPhase);
            temp[1] = temp[1].includes("E-field") ? " Voltage (V)" : temp[1]
            switch ("Phase "+tempPhase) {
                case "Phase A": temp[0] = "Primary"
                    break;
                case "Phase B": temp[0] = "Secondary X1";
                    break;
                case "Phase C": temp[0] = "Secondary X3";
                    break;
                default: // do nothing;
                    break
            }
            finalName = temp.join("")
        }
        return finalName;
    }

    getPhaseInfo = (view, id) => {
        id = id.split("t");
        return this.chartOptions.seriesNames[id[0] + 't'].name;
    }

    buildSeries = (siteData) => {
        var cols = this.chartOptions.dataColumns;
        var seriess = {};
        for (var i = 0; i < cols.length; i++) {
            var columnName = cols[i];
            var phase = columnName.slice(0, 1);
            var unit = columnName.slice(1);
            var data = (siteData.hasOwnProperty(phase.toUpperCase())) ? siteData[phase.toUpperCase()] : {};
            if (Object.keys(data).length > 0) {
                for (var k = 0; k < data[unit + 'Series'].length; k++) {
                    var ss = data[unit + 'Series'];
                    var col = columnName + k;
                    seriess[col] = {};
                    seriess[col].pointStart = ss[k][unit + 'StartTime'];
                    seriess[col].summary = data["summaries"][k]
                    seriess[col].pointInterval = (ss[k]['pointInterval']) ? ss[k]['pointInterval'] : this.pointInterval;
                    seriess[col].data = ss[k][unit + 'Values'];
                    seriess[col].tooltip = [];
                    seriess[col].visible = true;
                    seriess[col].id = col;
                    seriess[col].color = this.chartOptions.colors[i];
                    if (k !== 0) {
                        seriess[col].linkedTo = 'previous';
                    }
                }
            }
        }
        this.series = seriess
    }

    handleBtnClick = (val) => this.context.updateChartData(val)

    waveformdownload = (type, data) => {
        let params = { 'FORMAT': type, "SITE": data.siteName, "FAULTID": this.context.state.feederFaultId, "apiType": "COMMON" };
        exportData(params)
        .then(data => {
            window.location.href = data;
        }).catch(err => { 
            console.log("download failed");
        })
    }

    resizeChartSection=()=>{
        this.state.finalData.highcharts && Object.keys(this.state.finalData?.highcharts?.series).length !== 0 && this.internalChart.reflow()
        setTimeout( () => {
            window.dispatchEvent(new Event('resize'));
        }, 100);
    }

    handleExpandProperty = (val) => this.setState({ isDisplyCollapsIcon: val })

    updateXScale = (value) => {
        this.context.chartList.forEach((chart, key) => {
            if (chart.xAxis) {
                let ex = chart.xAxis[0].getExtremes();
                let chartMin = chart.xAxis[0].options.min;
                let chartMax = chart.xAxis[0].options.max;
                if (ex.min != value.min || ex.max != value.max) {
                    chart.resetZoomButton && chart.resetZoomButton.hide();
                    chart.xAxis[0].setExtremes(value.min, value.max, true, false);
                    chart.yAxis[0].setExtremes(null, null);
                    chart.yAxis[1].setExtremes(null, null);
                }
                if ((chartMin == value.min) && (chartMax == value.max)) {
                    chart.resetZoomButton && chart.resetZoomButton.hide();
                    delete chart.resetZoomButton;
                    chart.xAxis[0].setExtremes(null, null);
                    chart.yAxis[0].setExtremes(null, null);
                    chart.yAxis[1].setExtremes(null, null);
                } else {
                    if (chart) {
                        chart.resetZoomButton && chart.resetZoomButton.hide();
                        chart.showResetZoom();
                    }
                }
            }
        })

    }
    zoomOut = (chart, event) => {
        if (event.trigger === "zoom") {
            if (chart.hasOwnProperty('resetZoomButton') && Object.keys(chart.resetZoomButton).length !== 0) {
                chart.resetZoomButton.hide();
                delete chart.resetZoomButton;
            }
        }
    }

    updateXAxisZoom = (value)=>{
        this.context.chartList.forEach((chart, key) => {
            if (chart.xAxis) {
                let ex = chart.xAxis[0].getExtremes();
                if (ex.min != value.min || ex.max != value.max) {
                    chart.resetZoomButton && chart.resetZoomButton.hide();
                    chart.xAxis[0].setExtremes(value.min, value.max, true, false);
                    chart.yAxis[0].setExtremes(null, null);
                    chart.yAxis[1].setExtremes(null, null);
                }
            }
        })
    }
    downloadChart = (value) => {
        /**moved download highchart download to the method to handle png,jpeg.pdf,svg download */
        let fileNameAndFormat = this.chartOptions.highcharts.exporting['filename'] + "." + value;
        if (value === "png" || value === "jpeg") {
            toBlob(event.target.parentElement.parentElement.parentElement)
                .then(function (blob) {
                    saveAs(blob, fileNameAndFormat)
                });
        }
        else if (value === "svg") {
            saveAs(new Blob([event.target.parentElement.parentElement.parentElement.children[0].outerHTML], { type: "image/svg+xml" }), fileNameAndFormat)
        }
        else if (value === "pdf") {
            toCanvas(event.target.parentElement.parentElement.parentElement)
                .then(function (canvas) {
                    let pdf = new jsPDF("l", "mm", "a4");
                    let imgData = canvas.toDataURL('image/jpeg', 1.0);
                    pdf.addImage(imgData, 'JPEG', 10, 10, 280, 200);  // 180x150 mm @ (10,10)mm      
                    pdf.save(fileNameAndFormat);
                });
        }
    }
    render() {
        const tabValues = this.props.waveformList.details.filter(e => e.siteDeviceType === "UM1").length > 0 ?
                    (this.props.waveformList.phasesList && this.props.waveformList.phasesList.map(e => {
                    let obj = e;
                    obj.label = e.id === "a" ? "Primary" : e.id === "b" ? "Secondary X1" : "Secondary X3";
                    return obj;
                })) : (this.props.waveformList.phasesList && this.props.waveformList.phasesList.map(e => {
                    let obj = e;
                    obj.label = e.id.toUpperCase();
                    return obj;
                }))
        return (
            <Row className="border-1px">
                <Col>
                    <Row className='mt-2'>
                        <Col>{this.props.waveformList.breadcrumb}</Col>
                    </Row>
                    <Row>
                        <Col className='fault-chart'>
                            {this.state.finalData?.highcharts?.series && Object.keys(this.state.finalData?.highcharts?.series).length !== 0 ?
                                <HighchartsReact
                                    highcharts={HighCharts}
                                    options={{
                                        ...this.state.finalData.highcharts,
                                        xAxis:{
                                            ...this.state.finalData.highcharts.xAxis,
                                            labels:{
                                                ...this.state.finalData.highcharts.xAxis.labels,
                                                formatter: function () {
                                                    var nanoStr = this.value.toString();
                                                    var d = new Date(parseInt(nanoStr.substr(0, 13), 10));
                                                    var dStr = d.getSeconds() + "." + HighchartsData.dateFormat('%L', d) + nanoStr.substr(13, 3);
                                                    return dStr + ' s';
                                                }
                                            }
                                        },
                                        tooltip: {
                                            ...this.state.finalData.highcharts.tooltip,
                                            formatter: function () {
                                                var dateStr = this.x.toString();
                                                var stp = parseInt(dateStr.substr(0, 13), 10);
                                                var micro = parseInt(dateStr.substr(13, 3), 10);
                                                var s = '<b>' + dateConvertion(stp, 'maxLongDateWithoutAP') + ' ' + micro + ' ' + dateConvertion(stp, 'AMPM') + '</b>';
                                                var current = [];
                                                var eField = [];

                                                this.points.forEach((e, k) => {
                                                    var name = e.series.name;
                                                    var splitName = (name.indexOf("Current") !== -1) ? ' Current' : (name.indexOf("Voltage") !== -1) ? ' Voltage':' E-field';
                                                    if (splitName === ' Current') {
                                                        current[name.split(splitName)[0]] = e.y + '(A)';
                                                    } 
                                                    else {
                                                        eField[name.split(splitName)[0]] = `${e.y} ${splitName === ' Voltage' ? '(V)' : '(V/m)'}`;
                                                    }
                                                });
                                                let arr = [...new Set(Object.keys(current).concat(Object.keys(eField)))].sort();
                                                arr.forEach(e => {
                                                    s += `<br/> ${e} : ${current.hasOwnProperty(e) ? current[e] : ""} ${eField.hasOwnProperty(e) && current.hasOwnProperty(e) ? ', ' : ""} ${eField.hasOwnProperty(e) ? eField[e] : ""}`
                                                })
                                                return s;
                                            }
                                        },
                                        exporting: {
                                            ...this.chartOptions.highcharts.exporting,
                                            menuItemDefinitions: {
                                                downloadCSV: {
                                                    onclick: () => this.waveformdownload('csv', this.props.waveformList),
                                                    text: 'Download CSV'
                                                },
                                                downloadXLS: {
                                                    onclick: () => this.waveformdownload('excel', this.props.waveformList),
                                                    text: 'Download XLS'
                                                },
                                                downloadPNG:{
                                                    onclick:()=> this.downloadChart("png"),
                                                    text:"Download PNG image"
                                                },
                                                downloadJPEG:{
                                                    onclick:()=> this.downloadChart("jpeg"),
                                                    text:"Download JPEG image"
                                                },
                                                downloadPDF:{
                                                    onclick:()=> this.downloadChart("pdf"),
                                                    text:"Download PDF document"
                                                },
                                                downloadSVG:{
                                                    onclick:()=> this.downloadChart("svg"),
                                                    text:"Download SVG vector image"
                                                }
                                            },
                                        },
                                        ...this.chartOptions.highcharts.xAxis.events.setExtremes = (e) => {
                                            if (e.trigger === 'navigator' && e.DOMEvent && (e.DOMEvent.type === 'mouseup' || e.DOMEvent.type === 'touchend') && this.props) {
                                            this.zoomOut(this.internalChart, e)
                                            }
                                            if(e.trigger && e.trigger === "zoom"){
                                                    this.updateXScale({max:e.max || e.target.options.max , min:e.min || e.target.options.min, e: e});
                                            }
                                        },
                                        // ...this.state.finalData.highcharts.xAxis.events.afterSetExtremes = (e) => {
                                        ...this.chartOptions.highcharts.xAxis.events.afterSetExtremes = (e) => {
                                            if (e.hasOwnProperty('DOMEvent')) {
                                            this.updateXScale({max:e.max, min:e.min, e: e});
                                            }else if(e.type !== "afterSetExtremes"){
                                               this.updateXAxisZoom({max:e.max, min:e.min, e: e});
                                            }
                                        },
                                    }}
                                    callback={this.afterChartCreated}
                                />
                                : <BlankPage blankText={<FormattedMessage id='db.common.noDataAvailable' />} /> }           
                        </Col>
                    </Row>
                </Col>

                <TableSection data={this.props} tabValues={tabValues} resizeChartSection={this.resizeChartSection}/>

            </Row>
        )
    }
}

export default FaultChart