import React, { Component, Fragment } from 'react';
import ConnectedPercentageModal from './connectedPercentageModal';
import { DeviceContext } from '../../provider/deviceProvider';
import { connect } from 'react-redux';
import { getconnectionStatus, getLoadData } from '../../services/deviceService';
import { optionsData } from '../../../../../utility/deviceManagementUtility';

class ConnectedPercentage extends Component {
    static contextType = DeviceContext;
    state = {
        monitorEnabled: this.props.roleAndPermission.licenseData.monitorEnabled,
        timezone: this.props.roleAndPermission.timezone,
        isOpenConnectedPercentageModal: false,
        optionsData: optionsData,
        isLodingData: false,
        columnstartPoint: 0,
        series: [],
        activeSeries: []
    }

    getConnectedVal = (item) => {
        if((item.value && !isNaN(item.value) && !(item.row.deviceType === "ZM1" || item.row.deviceType === "UM1"))){
            return <span style={{ color: '#BF7400' }} className='cursor-pointer active-color' onClick={(e) => this.getDeviceDetailsData(e, item)}>{item.value}</span>
        } else {
           return item.value
        }
    }

    onCloseModal = () => {
        optionsData.series = [];
        optionsData.dataColumns = ['disturbance'];
        optionsData.xAxis.max = undefined;
        optionsData.xAxis.min = undefined;
        this.setState({ isOpenConnectedPercentageModal: false, columnstartPoint: 0, series: [], activeSeries : [] })
    }

    getDeviceDetailsData = async (e, info) => {
        e.preventDefault();
        this.setState({ isOpenConnectedPercentageModal: true, isLodingData: true }, () => {
            getconnectionStatus(info.row._original.id)
                .then((connectStatus) => {
                    if (connectStatus.status !== 'FAIL') {
                        if (!this.state.monitorEnabled) {
                            this.connectedData(
                                {
                                    'startDate': connectStatus.startDate,
                                    'endDate': connectStatus.endDate,
                                    'connectionSatus': connectStatus.data
                                }
                            )
                        } else {
                            getLoadData(info.row._original.id)
                                .then((logiData) => {
                                    if (logiData.status !== 'FAIL') {
                                        this.connectedData(
                                            {
                                                'startDate': logiData.startDate,
                                                'endDate': logiData.endDate,
                                                'connectionSatus': connectStatus.data,
                                                'phases': (logiData.hasOwnProperty('phases')) ? logiData.phases : ['A'],
                                                'logiStatus': logiData.load
                                            }
                                        )
                                    }
                                })
                        }
                    }
                }).catch((err) => { })
        })
    }

    connectedData = (data) => {
        this.processData(data);
       // series = this.buildSeries(data, optionsData.dataColumns);
        this.setState({ series: this.buildSeries(data, optionsData.dataColumns) }, () => this.renderChart(data))
    }

    processData = (data) => {
        if (data.hasOwnProperty('logiStatus') && data['logiStatus'] && Object.keys(data['logiStatus']).length > 0) {
            let detailObj = data['logiStatus'];
            Object.keys(detailObj).forEach(key => {
                //removing inx property from data
                var filterData = detailObj[key] !== undefined && detailObj[key] !== null ? detailObj[key].filter(item => !item.inx) : detailObj[key]

                var existingobj = {};
                var addOnArr = [];
                if (filterData && filterData.length) {
                    for (var i = 0; i < filterData.length; i++) {
                        var pointDataObj = filterData[i];
                        var newTimestamp = 0, addonObj = {};
                        var diff = (i !== 0) ? (pointDataObj.t - existingobj.t) / (60 * 1000) : 0;
                        var diffValue = 60;
                        if ((diff > diffValue) && (i !== 0)) {
                            // Add the null valueCalculate the time for null value
                            newTimestamp = parseInt((existingobj.t + ((pointDataObj.t - existingobj.t) / 2)), 10);
                            addonObj['inx'] = i;
                            addonObj['id'] = parseInt(pointDataObj.id, 10) + 1000;
                        }
                        if (newTimestamp > 0) {
                            addonObj['t'] = newTimestamp;
                            addonObj['load'] = null;
                            addonObj['temperature'] = null;
                        }
                        if (newTimestamp > 0) {
                            addOnArr.push(addonObj);
                        }
                        filterData[i] = pointDataObj;
                        existingobj = detailObj[key][i];
                    }
                }
                if (addOnArr.length > 0) {
                    var preparedPointDate = filterData.concat(addOnArr);
                    var detailObjData = preparedPointDate.sort((a, b) => a.t - b.t)
                    data['logiStatus'][key] = detailObjData;
                }
            });
        }

        if (data.hasOwnProperty('logiStatus') && data['logiStatus']) {
            if (data.hasOwnProperty('phases') && data.phases.length > 1) {
                for (var k = 0; k < data.phases.length; k++) {
                    optionsData.dataColumns.push('current_' + data.phases[k]);
                }
            } else {
                optionsData.dataColumns = ['disturbance', 'current'];
            }
        } else {
            optionsData.dataColumns = ['disturbance'];
        }
    }

    buildSeries = (connectedData, cols) => {
        var series = {};
        var columnstartPoint = this.state.columnstartPoint;
        // var value = 0;
        var areaData = [];
        var lastConnectedPointObj = null;
        var connectionSatus = (connectedData.hasOwnProperty('connectionSatus')) ? connectedData['connectionSatus'] : {};
        var logiStatus = (connectedData.hasOwnProperty('logiStatus')) ? connectedData['logiStatus'] : {};
        cols.forEach(function (columnName, key) {
            var data = (key === 0) ? connectionSatus : logiStatus;
            series[columnName] = {};
            series[columnName].tooltip = [];
            series[columnName].visible = true;
            series[columnName].id = columnName;
            series[columnName].color = optionsData.colors[key];
            series[columnName].data = [];
            if (key === 0) {
                Object.keys(data).forEach(date => {
                    columnstartPoint = (columnstartPoint === 0) ? date : (columnstartPoint < date) ? columnstartPoint : date;
                    // this.setState({ columnstartPoint : (this.state.columnstartPoint === 0) ? date : (this.state.columnstartPoint < date) ? this.state.columnstartPoint : date })
                    // this.state.columnstartPoint = (this.state.columnstartPoint === 0) ? date : (this.state.columnstartPoint < date) ? this.state.columnstartPoint : date;

                    value = data[date] ? 1 : 0;
                    areaData.push([parseInt(date, 10), value]);
                })

                lastConnectedPointObj = (areaData && areaData.length > 0) ? areaData[areaData.length - 1] : null;
                series[columnName].data = areaData;
                series[columnName].pointStart = parseInt(columnstartPoint, 10);
                series[columnName].step = 'left';
            } else {
                var phase = 'A';
                if (columnName.indexOf('_') !== -1) {
                    phase = columnName.split('_')[1];
                } else {
                    phase = connectedData.phases[0];
                }
                if (data[phase] && data[phase].length) {
                    for (var i = 0; i < data[phase].length; i++) {
                        var points = data[phase];
                        var point = points[i];
                        var t = point['t'];
                        var colNameUnit = 'load';
                        if (point.hasOwnProperty(colNameUnit) && lastConnectedPointObj && (lastConnectedPointObj.length > 0) && (t <= lastConnectedPointObj[0])) {
                            var value = point[colNameUnit];
                            // Set marker radius as 2 for point which has previous/next point has null values
                            if ((points.length > 1) && (((i !== 0 && i !== (points.length - 1)) && (points[i - 1][colNameUnit] === null && points[i + 1][colNameUnit] === null)) || (i === (points.length - 1) && points[i - 1][colNameUnit] === null) || ((i === 0 && i !== (points.length - 1)) && (points[i + 1][colNameUnit] === null)))) {
                                series[columnName].data.push({ x: t, y: value, marker: { radius: 2 } });
                            } else {
                                series[columnName].data.push([t, value]);
                            }
                        }
                    }
                }

            }
        });

        return series;
    }

    renderChart = (data) => {
        var series = this.buildActiveSeries();
        var hasData = (series && series.length > 0) ? this.seriesArrayHasData(series) : false;
        optionsData.xAxis.max = data.startDate;
        optionsData.xAxis.min = data.endDate;
        // activeSeries = series;
        this.setState({ activeSeries : series })
        if (hasData) {
            optionsData.series = series;
            this.setState({ optionsData: optionsData, isLodingData: false })
        }
    }
    buildActiveSeries = () => {
        var series = this.state.series;
        var result = Object.keys(series).map((s, key) => {
            var axisIndex = (s === 'disturbance') ? 0 : 1;
            series[s].id = s;
            series[s].type = (s === 'disturbance') ? 'area' : 'spline';
            series[s].yAxis = axisIndex;
            series[s].dashStyle = optionsData.dashStyles[0];
            series[s]._symbolIndex = key
            series[s].compare = undefined
            series[s].useHtml = true
            return series[s];
        });
        return result;
    }

    seriesArrayHasData = (seriesArray) => {
        var dataSum = seriesArray.map(s => { // for each  series
            return (s.data || []).length; // get  the  series  length
        }).reduce((sum, x) => sum + x);
        return dataSum > 0;
    };

    render() {
        return (
            <Fragment>
                <span>{this.getConnectedVal(this.props.data)}</span>
                {this.state.isOpenConnectedPercentageModal ?
                    <ConnectedPercentageModal
                        onCloseModal={this.onCloseModal}
                        chartData={this.state.optionsData}
                        timezone={this.state.timezone}
                        rowData={this.props.data.row}
                        isLodingData={this.state.isLodingData}
                    /> : ""}
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        roleAndPermission: state.loginData.roleAndPermission
    }
}
export default connect(mapStateToProps, {})(ConnectedPercentage);