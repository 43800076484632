import * as loginDispatch from './loginActionDispatch';

export const logIn = (postData) => {
    return dispatch => {
        dispatch(loginDispatch.onLogIn(postData));
    };
};

export const logOut = () => {
    return dispatch => {
        dispatch(loginDispatch.onLogOut());
    }

}

export const updateGlobalTree = (newData) => {
    return dispatch => {
        dispatch(loginDispatch.updateGlobalTree(newData));
    };
};
export const updateSearchQuery = (newData) => {
    return dispatch => {
        dispatch(loginDispatch.updateSearchQuery(newData));
    };
};
export const notify = (newData) => {
    return dispatch => {
        dispatch(loginDispatch.notify(newData));
    };
};
export const showAlertToaster = (errorMessage) => {
    return dispatch => {
        dispatch(loginDispatch.showAlertToaster(errorMessage));
    };
}
export const closeAlertToaster = () => {
    return dispatch => {
        dispatch(loginDispatch.closeAlertToaster());
    };
}
export const openErrorWindow = (errorMessage, errorTile) => {
    return dispatch => {
        dispatch(loginDispatch.openErrorWindow(errorMessage, errorTile));
    };
}
export const closeErrorWindow = (errorMessage) => {
    return dispatch => {
        dispatch(loginDispatch.closeErrorWindow(errorMessage));
    };
}
export const setLoader = (loaderData) => {
    return dispatch => {
        dispatch(loginDispatch.setLoader(loaderData));
    };
}