import React from 'react';
import { FormattedMessage } from 'react-intl';
import TableMultiSortNumber from '../hoc/tableMultiSortNumber';
import { formatDateAndTime } from '../filters/dateHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export const DGWData = {
    pageSize: 15,
    pageSizeOptions: [15, 25, 50, 75],
    previousText: <FormattedMessage id='db.common.pgntn.previous' />,
    nextText: <FormattedMessage id='db.common.pgntn.next' />,
    pageText: <FormattedMessage id='db.common.pgntn.rowsPerPage' />,
    ofText: <FormattedMessage id='db.common.pgntn.of' />,
    rowsText: <FormattedMessage id='db.common.pgntn.rows' />,
    firstText: <FormattedMessage id='db.common.pgntn.first' />,
    lastText: <FormattedMessage id='db.common.pgntn.last' />,

    columnsData: [
        { accessor: 'name', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dgw.column.name' />} />, sortedOrder: 0, },
        { accessor: 'type', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dgw.column.type' />} />, sortedOrder: 0, },
        { accessor: 'created', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dgw.column.created' />} />, sortedOrder: 0, Cell: (original) => formatDateAndTime(original.row.created) },
        { accessor: 'status', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='dgw.column.status' />} />, sortedOrder: 0, }
    ],
   
    currentTab : "Ample Certificate",
    allTabsList: [
        { label: <FormattedMessage id='db.sg.ample_certificate' />, value: 'Ample Certificate' }, 
        { label: <FormattedMessage id='db.sg.certificate_revocation_list' />, value: 'Certificate Revocation List' }
    ],
    ampleCertTable: [
        { accessor: 'fileName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sysAdmin.fileName'/>} /> },
        { accessor: 'importedOn', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sg.upload_date'/>} />, Cell: (original) => formatDateAndTime(original.row.importedOn) },
        { accessor: 'status', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sysAdmin.status'/>} /> }
    ],
    certRevocationListTable: [
        { accessor: 'fileName', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sysAdmin.fileName'/>} /> },
        { accessor: 'crlMD5', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sg.md5_checksum'/>} /> },
        { accessor: 'importedOn', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sg.upload_date'/> } />, Cell: (original) => formatDateAndTime(original.row.importedOn) },
        { accessor: 'status', Header: (original) => <TableMultiSortNumber column={original} label={<FormattedMessage id='db.sysAdmin.status'/>} /> }
    ],
}

function updateData(column) {
    return (
        <span><FontAwesomeIcon icon={updateDataIcon(column.status)} size="sm" /> { formatUptime(column.uptime)}</span>
    )
}
function updateDnpAddress(column) {
    return column.commServerVersion.indexOf('SNAPSHOT') > -1 ? column.masterDnpAddress : 'NA'
}
function updateDataIcon(value) {
    var iconName = '';
    if (value === "RUNNING") iconName = faCheckCircle;
    else if (value === 'UNKNOWN') iconName = faTimesCircle;
    else iconName = faExclamationTriangle;
    return iconName
}

function formatUptime(input) {
    var formatedValue = '';
    var oneHour = 60 * 60 * 1000;
    var d = Math.floor(input / (oneHour * 24));
    var h = Math.floor(input / oneHour) % 24;
    var m = Math.floor((input % oneHour) / (60 * 1000));
    if (d) {
        formatedValue += d + 'd ';
    }
    if (d > 0 || h) {
        formatedValue += h + 'h ';
    }
    if (m) {
        formatedValue += m + 'm ';
    }
    return formatedValue;
}